import Swal from "sweetalert2";
import { authStore } from "./authStore";
import "../assets/css/errorHandler.css";

export function popupError(navigateToLogin = false, errorMessage) {
  let AlertErrorMsg = "Something Went Wrong";
  let errtext = "";
  let confirmBtnText = "OK";
  let fileUploadIssue = false;

  // Update AlertErrorMsg and errtext based on errorCode
  if (errorMessage === "Invalid File Format") {
    fileUploadIssue = true;
    AlertErrorMsg = "Invalid File Format";
    errtext = `<div><ul style="font-size:10px;padding-top:0px;padding-left:60px;"><li style="text-align: left;">Only .pdf, .docx and .doc files are accepted.</li></ul>`;
  } else if (errorMessage === "Invalid File Name ") {
    fileUploadIssue = true;
    AlertErrorMsg = "Invalid File Name";
    errtext = `<ul style="font-size:10px;"><li style="text-align: left;">Special characters '_' and '-' only are allowed in file name.</li><li style="text-align: left;">Special characters like '.','$','!','?','@' etc are strictly prohibited.</li><li style="text-align: left;">All alphanumeric characters accepted.</li></ul>`;
  }

  // Update AlertErrorMsg and confirmBtnText if user needs to navigate to login
  if (navigateToLogin) {
    AlertErrorMsg = "Session expired";
    confirmBtnText = "Go back to login";
  }

  // Shows a popup modal
  Swal.fire({
    icon: fileUploadIssue ? "warning" : "error",
    position: "center",
    // icon: "error",
    title: AlertErrorMsg,
    html: errtext,
    showConfirmButton: true,
    confirmButtonText: confirmBtnText,
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonColor: "#337fc9",
    customClass: fileUploadIssue && {
      popup: "swalPopup",
      htmlContainer: "swalHtmlContainer",
      title: "title",
      icon: "swalWarningIcon",
      iconContent: "swalWarningContent",
    },
  }).then((result) => {
    // If user needs to navigate to login, redirect and clear auth store
    if (navigateToLogin) {
      window.location.href = "/";
      console.log("Clearing the auth store ... ");
      authStore.clear();
    }
    // navigate("/");
  });
}
