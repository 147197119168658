import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ReactComponent as ErrorComponent } from "../assets/svg/NewError.svg";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          // paddingRight: "7%",
          flexDirection: "column",
          // height: "90vh",
          border: "1px solid #CCCCCC",
          width: "30%",
          height: "50%",
          borderRadius: "1rem",
        }}
      >
        <ErrorComponent />
        <Grid style={{ paddingTop: "3rem" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              background: "linear-gradient(to right, #000084, #337FC9)",
              color: "#ffffff",
              padding: "8px 27px",
              borderRadius: "10px",
              font: "Lato !important",
              // position: "absolute",
            }}
          >
            {" "}
            Get back to Home
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default Error;
