import * as React from "react";
import Grid from "@mui/material/Grid";
import LineGraph from "./LineGraph";
import PieChart from "./PieChart";
import styles from "../../assets/css/Graphs.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as UpArrowSVG } from "../../assets/svg/UpArrow.svg";
import PieTable from "./PieTable";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InfoIcon from "@mui/icons-material/Info";

import { Button, Tooltip } from "@mui/material";
import DashboardTable from "../DashboardTable";
import { connect } from "react-redux";
import { AMC_DETAILS } from "../../variable";

function Graphs(props) {
  // const [graphsFundType, setGraphsFundType] = React.useState("");
  const [performanceDataFundWise, setPerformanceDataFundWise] = React.useState({
    "Absolute Return": "",
    "Cost Value": "",
    "Current Value": "",
    "Fund Type": "",
    "Overall Return": "",
  });
  const [backButton, setBackButton] = React.useState();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  // 👇️ 24/10/2021 (dd/mm/yyyy)
  const todayDate = formatDate(new Date());
  const CommitmentHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const ContributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
  ];
  const PerformanceHeadCells = [
    {
      field: "fundType",
      numeric: true,
      disablePadding: false,
      headerName: "Fund Type",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 186,
      align: "center",
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "costValue",
      numeric: true,
      disablePadding: false,
      headerName: "Cost Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "absoluteReturn",
      numeric: true,
      disablePadding: false,
      headerName: "Absolute Returns",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    // {
    //   field: "annualisedReturn",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "Annualised Returns",
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 178,
    //   align: "center",
    // },
    {
      field: "overallReturn",
      numeric: true,
      disablePadding: false,
      headerName: "Overall Return",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];

  // const [performanceGraphX, setPerformanceGraphX] = React.useState([]);
  // React.useEffect(() => {
  //   setPerformanceGraphX(props.performanceData.performanceData);
  // }, []);

  // React.useEffect(() => {
  //   // performanceGraphX.shift();
  // }, [performanceGraphX]);

  // //console.log("performanceData=", performanceGraphX);

  console.log("Graphs portfolio box----", props.portfolioBox);
  // //console.log("performanceData=456", props);
  let rows = [];
  props?.portfolioBox?.portfolioView &&
    Object.keys(props.portfolioBox.portfolioView).map((key) => {
      rows.push({
        fundType: key,
        totalCommitment: props.portfolioBox.portfolioView[key],
      });
    });
  //console.log("ROWS--", rows);

  function changeBackButton() {
    props.setFundType({ name: "Total", color: "#5d63b4" });
  }

  React.useEffect(() => {
    //console.log("inside use effect of props.fundType", props.fundType);
    // setGraphsFundType(props.fundType);
    props?.performanceData?.performanceData &&
      Object.values(props?.performanceData?.performanceData).map((key) => {
        if (key["Fund Type"] === props.fundType.name) {
          setPerformanceDataFundWise(key);
        }
      });
    setBackButton(
      props.fundType.name !== "Total" ? (
        <Button onClick={changeBackButton} className={styles.backButton}>
          <KeyboardBackspaceIcon fontSize="small" />
        </Button>
      ) : (
        <></>
      )
    );
  }, [props.fundType]);

  const performanceString = `Performance`;
  const performanceFundTypeString = `Performance - ${props.fundType.name}`;

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Grid container className={styles.outerContainer}>
        <Grid item xs={6.5}>
          <Card
            variant="outlined"
            style={{ height: "380px", width: "550px" }}
            className={styles.card}
          >
            <CardContent>
              <Grid item xs={12} className={styles.lineGrid__row1}>
                <Grid item className={styles.bold}>
                  {props.fundType.name === "Total"
                    ? performanceString
                    : performanceFundTypeString}
                </Grid>
                {/* <Grid item className={styles.lineGrid__grey}>
                  As on {todayDate}
                </Grid> */}
              </Grid>

              <Grid item xs={12} className={styles.lineGrid__row2}>
                <Grid item xs={4}>
                  <Grid
                    item
                    xs={12}
                    className={styles.lineGrid__grey}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      paddingInline: "10px",
                    }}
                  >
                    Current Value
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      fontWeight: "bold",
                      textAlign: "justify",
                      display: "flex",
                      justifyContent: "start",
                      paddingInline: "10px",
                    }}
                  >
                    {performanceDataFundWise?.["Current Value"]}
                    {/* <UpArrowSVG
                      style={{
                        marginTop: "5px",
                        marginLeft: "8px",
                        fontWeight: "bold",
                      }}
                    /> */}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {/* <Grid
                    item
                    xs={12}
                    className={styles.lineGrid__grey}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Annualised Returns
                    <Tooltip title="Info" placement="top" arrow>
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      textAlign: "justify",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {performanceDataFundWise["Annualised Return"]}
                  </Grid> */}
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>

              <Grid
                item
                style={{
                  marginTop: "1rem",
                  height: "12rem",
                  paddingInline: "0.5rem",
                }}
              >
                <LineGraph performanceGraphData={props.performanceGraphData} />
              </Grid>

              <Grid item xs={12} className={styles.lineGrid__row3}>
                <Grid item xs={4}>
                  <Grid
                    item
                    xs={12}
                    className={styles.lineGrid__grey}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      paddingInline: "10px",
                    }}
                  >
                    Cost Value
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      fontWeight: "bold",
                      textAlign: "justify",
                      display: "flex",
                      justifyContent: "start",
                      paddingInline: "10px",
                    }}
                  >
                    {performanceDataFundWise["Cost Value"]}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    item
                    xs={12}
                    className={styles.lineGrid__grey}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Absolute Return
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={
                      performanceDataFundWise["Absolute Return"].substring(
                        0,
                        performanceDataFundWise["Absolute Return"].length - 1
                      ) < 0
                        ? styles.absoluteReturnRedCss
                        : performanceDataFundWise["Absolute Return"].substring(
                            0,
                            performanceDataFundWise["Absolute Return"].length -
                              1
                          ) > 0
                        ? styles.absoluteReturnGreenCss
                        : styles.absoluteReturnBlackCss
                    }
                  >
                    {performanceDataFundWise["Absolute Return"]}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    item
                    xs={12}
                    className={styles.lineGrid__grey}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingInline: "10px",
                    }}
                  >
                    Overall Return
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      fontWeight: "bold",
                      textAlign: "justify",
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingInline: "10px",
                    }}
                  >
                    {performanceDataFundWise["Overall Return"]}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "50px",
                  justifyContent: "right",
                  paddingTop: "1.3rem",
                }}
              >
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "50px",
                    justifyContent: "right",
                  }}
                >
                  <Button>
                    <DashboardTable
                      name="PerformanceTable"
                      headCells={PerformanceHeadCells}
                      detailHeader="Performance Details"
                      performanceData={props.performanceData}
                    />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={5.5}
          style={{
            // justifyContent: "center",
            height: "100%",
          }}
        >
          {/* <Card
            variant="outlined"
            className={styles.card}
            style={{ height: "380px", width: "400px" }} // style={{ border: "solid 2px slateblue", height: "20rem" }}
          >
            <CardContent>
              <Grid item xs={12} className={styles.lineGrid__row1}>
                <Grid item className={styles.bold}>
                  Portfolio View
                  {backButton}
                </Grid>
              </Grid>
              <Grid container style={{ display: "flex", flexDirection: "row" }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    width: "auto",
                    display: "flex",
                    justifyContent: "center",
                    height: "10rem",
                  }}
                  className={styles.pieGrid__col1}
                >
                  <PieChart portfolioBox={props.portfolioBox} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: "0px",
                    marginLeft: "0.5rem",
                    height: "132px",
                  }}
                >
                  <PieTable pieData={props.portfolioBox} />
                </Grid>
              </Grid>
            </CardContent>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "3.4rem",
                justifyContent: "right",
              }}
            >
              <Button>
                <DashboardTable
                  name={
                    AMC_DETAILS.INVESTMENT_TYPE == "openEnded"
                      ? "TotalContribution"
                      : "TotalCommitment"
                  }
                  headCells={
                    AMC_DETAILS.INVESTMENT_TYPE == "openEnded"
                      ? ContributionHeadCells
                      : CommitmentHeadCells
                  }
                  detailHeader={
                    AMC_DETAILS.INVESTMENT_TYPE == "openEnded"
                      ? "Contribution Details"
                      : "Commitment Details"
                  }
                />
              </Button>
            </Grid>
          </Card> */}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    fundType: state.mainReducer.fundType,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setFundType: (val) => {
      dispatch({ type: "CHANGE-FUND-TYPE", fundType: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Graphs);
