import { Card, CardContent, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styles from "../../assets/css/Brokerage.module.css";
import BrokerageModal from "./BrokerageModal";

function BrokerageCard(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // let loaderDownload = false;

  // useEffect(() => {
  //   //console.log("card ka unseen number ->", props.uaData[props.nameForApi]);
  // }, [props.uaData]);
  const monthObject = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const currentMonth = new Date().getMonth() + 1;
  let monthArray = [];
  for (let i = currentMonth - 3; i < currentMonth; i++) {
    let val = i;
    if (i <= 0) {
      val = 12 + i;
    }
    monthArray.unshift(monthObject[val]);
  }
  console.log(monthArray);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={`${styles.bubbleBox} ${styles.card}`}>
          <Grid item onClick={handleOpen}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                backgroundColor: "#ffffff",
              }}
            >
              <Grid
                style={{
                  height: "30px",
                  width: "30px",
                }}
              ></Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={styles.cardTitleCss}
              style={props.cardStyle}
            >
              {props.cardTitle}
            </Grid>
          </Grid>
          <Modal
            open={open}
            // onClose={loaderDownload ? handleClose : ""}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ backdropFilter: "blur(10px)" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "700px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 3,
              }}
            >
              <BrokerageModal
                dropdownTitle={
                  props.cardTitle === "Rate Structure"
                    ? "Select Scheme"
                    : "Select Month"
                }
                dropdownArray={
                  props.cardTitle === "Rate Structure"
                    ? props.cardContent
                    : monthArray
                }
                detailHeader={props.cardTitle}
                handleClose={handleClose}
                nameForApi={props.nameForApi}
              />
            </Grid>
          </Modal>
        </Card>
      </Grid>
    </Grid>
  );
}

const mapStatetoProps = (state) => {
  return {
    BrokerageData: state.mainReducer.BrokerageData,
  };
};

export default connect(mapStatetoProps)(BrokerageCard);
