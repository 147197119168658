const initialState = {
  userDetails: {},
  fundType: {
    name: "Total",
    color: "#5d63b4",
  },
  page: "",
  authorization: false,
  token: "",
  navDate: "",
  query: null,
  unread: 0,
  uaData: {},
  admUser: "",
  admClientName: {},
  signupEmail: "",
  showTLNav: true,
  userPermission: "all",
  resendOtp: false,
  showLoader: false,
};

const mainReducer = (state = initialState, action) => {
  if (action.type === "CHANGE-USER") {
    return { ...state, userDetails: action.userDetails };
  } else if (action.type === "CHANGE-FUND-TYPE") {
    return { ...state, fundType: action.fundType };
  } else if (action.type === "CHANGE-PAGE") {
    return { ...state, page: action.page };
  } else if (action.type === "CHANGE-AUTHORIZATION") {
    return { ...state, authorization: action.authorization };
  } else if (action.type === "CHANGE-TOKEN") {
    return { ...state, token: action.token };
  } else if (action.type === "CHANGE-NAV-DATE") {
    return { ...state, navDate: action.navDate };
  } else if (action.type === "CHANGE-QUERY") {
    return { ...state, query: action.query };
  } else if (action.type === "CHANGE-UNREAD") {
    return { ...state, unread: action.unread };
  } else if (action.type === "CHANGE-UADATA") {
    return { ...state, uaData: action.uaData };
  } else if (action.type === "CHANGE-ADM-USER") {
    return { ...state, admUser: action.admUser };
  } else if (action.type === "CHANGE-CLIENT-NAME") {
    return { ...state, admClientName: action.admClientName };
  } else if (action.type === "CHANGE-SIGNUP-EMAIL") {
    return { ...state, signupEmail: action.signupEmail };
  } else if (action.type === "CHANGE-SHOW-TLNAV") {
    return { ...state, showTLNav: action.showTLNav };
  } else if (action.type === "CHANGE-USER-PERMISSION") {
    return { ...state, userPermission: action.userPermission };
  } else if (action.type === "CHANGE-RESEND-OTP") {
    return { ...state, resendOtp: action.resendOtp };
  } else if (action.type === "CHANGE-SHOW-LOADER") {
    return { ...state, showLoader: action.showLoader };
  } else if (action.type === "RESET") {
    return initialState;
  } else {
    return state;
  }
};

export default mainReducer;
