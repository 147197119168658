import * as React from "react";
import LeftNavInvestor from "./LeftNavInvestor";
import LeftNavDistributor from "./LeftNavDistributor";
import TopNav from "./TopNav";
import { connect } from "react-redux";
import LeftNavAdmin from "./LeftNavAdmin";
import LeftNavAdminKFintechUser from "./LeftNavAdminKFintechUser";
import LeftNavAdminAMCUser from "./LeftNavAdminAMCUser";
import { Outlet, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import LeftNavUnAuthAdmin from "./LeftNavUnAuthAdmin";
import Error from "../Error";
import { authStore } from "../../utils/authStore";

function TLNav(props) {
  let navigate = useNavigate();

  let sessionAccountType = authStore.getItem("accountType");
  React.useEffect(() => {
    if (!sessionAccountType) {
      navigate("/");
    }
  }, [authStore.getItem("accountType")]);

  //console.log("props show tl nav ", props.showTLNav)
  return props.showTLNav ? (
    <div>
      <TopNav />

      {sessionAccountType && sessionAccountType?.includes("INV") ? (
        <LeftNavInvestor />
      ) : sessionAccountType && sessionAccountType?.includes("DIS") ? (
        <LeftNavDistributor />
      ) : sessionAccountType && sessionAccountType?.includes("ADM") ? (
        props.userDetails?.permissions?.length <= 0 ? (
          <LeftNavUnAuthAdmin />
        ) : props.userDetails?.userType === "Admin" ||
          props.userDetails?.userType === "SuperAdmin" ? (
          <LeftNavAdmin />
        ) : props.userDetails?.userType === "KFintechUser" ? (
          <LeftNavAdminKFintechUser />
        ) : props.userDetails?.userType === "AMCUser" ? (
          <LeftNavAdminAMCUser />
        ) : (
          <></>
        )
      ) : (
        // navigate("/")
        <></>
      )}

      <Grid container style={{ marginTop: "65px" }}>
        <Grid item xs={2.5}></Grid>
        <Grid item xs={9.5}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  ) : (
    <></>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    showTLNav: state.mainReducer.showTLNav,
  };
};
export default connect(mapStatetoProps)(TLNav);
