import * as React from "react";

import styles from "../../assets/css/Graphs.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AMC_DETAILS } from "../../variable";

function PieTable(props) {
  const convertToCurrency = function standardData(initialData) {
    try {
      let finalData = parseFloat(initialData);
      finalData = finalData.toString();
      finalData = finalData.split(".")[0];
      finalData = parseFloat(finalData).toLocaleString("en-IN");

      return finalData;
    } catch (err) {
      console.log("Error in convert to currency:", err);

      throw err;
    }
  };

  let colors = [
    "#89C4F4",
    "#BBBBBB",
    "#337FC9",
    "#9AECF3",
    "#717171",
    "#36DAE9",
    "#333333",
    "#4492A8",
    "#66BCFF",
    "#7094C6",
    "#8ED8B7",
  ];

  function createData(fundType, totalCommitment) {
    return { fundType, totalCommitment };
  }

  // let data = Object.entries(props.pieData);
  //console.log("Pie Table data------------", props.pieData);

  return (
    <div>
      <TableContainer style={{}}>
        <Table size="small" aria-label="a dense table" width="450px">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}></TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Strategy Type
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                {AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                  ? "Total Contribution"
                  : "Total Commitment"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.pieData.portfolioView.map((row, index) =>
              index <= props.pieData.portfolioView.length - 1 ? (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    ".MuiTableCell-root": {
                      borderBottomColor: "white",
                      fontSize: "12px !important",
                    },
                  }}
                >
                  <TableCell
                    style={{
                      backgroundColor: `${colors[index]}`,
                      width: "10px",
                    }}
                    className={styles.tableCell}
                  ></TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.fundType}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {convertToCurrency(
                      row[
                        AMC_DETAILS.INVESTMENT_TYPE == "openEnded"
                          ? "totalContribution"
                          : "totalCommitment"
                      ]
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <div></div>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PieTable;
