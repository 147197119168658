import {
  Card,
  CardContent,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Modal,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { TextField } from "@mui/material";

import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { handleFormDataAPI, handleAPI } from "../../API/handleAPI";
import { connect } from "react-redux";
import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";
import { ReactComponent as Editicon } from "../../assets/svg/Editicon.svg";
import { ReactComponent as EditiconDisabled } from "../../assets/svg/EditiconDisabled.svg";
import { ReactComponent as DeleteReport } from "../../assets/svg/DeleteReport.svg";
import { ReactComponent as DeleteReportDisabled } from "../../assets/svg/DeleteReportDisabled.svg";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import RecentTransactionTable from "../RecentTransactionTable";
import styles from "../../assets/css/RecentTransaction.module.css";
import Loader from "../../views/Loader";
import ModalLoader from "../../views/modalLoader";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";

function AdminUA(props) {
  console.log("Inside AdminUA");

  const [searchString, setSearchString] = useState("");
  const [dataAll, setDataAll] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [coaName, setCoaName] = useState("");
  const [listOfCoa, setListOfCoa] = useState([]);
  const [open, setOpen] = useState(false);
  const [picture, setPicture] = useState(null);
  const [fileName, setFileName] = useState("");
  const [preSignedURL, setPreSignedURL] = useState();
  const [loaderUpload, setLoaderUpload] = useState(true);
  const [uniqueId, setUniqueId] = useState("");
  const [uploadStatus, setUploadStatus] = useState();
  const [open2, setOpen2] = useState(false);
  const [loaderEdit, setLoaderEdit] = useState(false);
  const [editUniqueId, setEditUniqueId] = useState("");
  const [editDropdownData, setEditDropdownData] = useState("");
  const [editTableData, setEditTableData] = useState();
  const [editSchemeDropdownValue, setEditSchemeDropdownValue] = useState([]);
  const [scheme, setScheme] = useState("");
  const [listOfScheme, setListOfScheme] = useState([]);
  const [deleteUniqueId, setDeleteUniqueId] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [dataAll2, setDataAll2] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [loaderHistory, setLoaderHistory] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [editDeleteScheme, setEditDeleteScheme] = useState();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  async function handleOpen() {
    console.log("category :>> ", coaName);
    await handleAPI(
      "/admin/getAnnouncmentAvailableSchemeForCategory",
      { category: coaName },
      props.token
    )
      .then((response) => {
        console.log("Response in Uploads dropdown :>> ", response.data);
        setScheme(response.data[0]);
        setListOfScheme(response.data);
      })
      .catch((error) => {
        console.log(`Error Message:  ${error.errorMessage}`);
      });
    setOpen(true);
  }
  const handleClose = () => {
    setPicture(null);
    setFileName("");
    setOpen(false);
  };

  const handleOpen2 = () => {
    console.log("entered handleOpen2 -> Edit Modal");
    callEditDropdownAPI();
  };
  const handleClose2 = () => {
    setEditUniqueId("");
    setEditSchemeDropdownValue([]);
    setOpen2(false);
  };

  // when the edit button on the table is clicked
  useEffect(() => {
    if (editUniqueId) {
      handleOpen2();
    }
  }, [editUniqueId]);
  // useEffect(() => {
  //   props.setPage("AdminUA");
  // }, []);

  // call API when edit modal opens to get dropdown and table data
  async function callEditDropdownAPI() {
    await handleAPI(
      "/admin/getAnnouncementEditDetails",
      {
        uniqueId: editUniqueId,
        category: coaName,
      },
      props.token
    )
      .then((response) => {
        console.log("callEditDropdownAPI response ->", response);
        setEditDropdownData(response.data.otherAvailableSchemes);

        let tempArray = [];
        response.data.schemeAttachedToFile.forEach((key) => {
          let tempObj = {
            scheme: key,
          };
          tempArray.push(tempObj);
        });

        setEditTableData(tempArray);
        setLoaderEdit(true);
      })
      .catch((error) => {
        console.log("Error");

        setLoaderEdit(true);
      });

    setOpen2(true);
  }

  // // edit dropdown options
  // function showEditDropdown() {
  //   console.log("entered showEditDropdown", editDropdownData);
  //   return editDropdownData.map((key) => {
  //     return <MenuItem value={key}>{key}</MenuItem>;
  //   });
  // }

  // when edit dropdown changes
  const handleEditSchemeDropdownChange = (event) => {
    // setEditSchemeDropdownValue(event.target.value);
    const {
      target: { value },
    } = event;
    setEditSchemeDropdownValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    console.log(
      "editSchemeDropdownValue now changed to -> ",
      editSchemeDropdownValue
    );
  }, [editSchemeDropdownValue]);

  console.log("props.userDetails.abc :", props.userDetails.fullName);
  async function handleEditConfirm() {
    await handleAPI(
      "/admin/addSchemeToAnnouncmentFiles",
      {
        category: coaName,
        uniqueId: editUniqueId,
        schemes: editSchemeDropdownValue,
        userName: props.userDetails.fullName,
      },
      props.token
    )
      .then((response) => {
        console.log("Edit Scheme added ,", response);
        handleClose2();
        callAPIOnUpdate();
      })
      .catch((error) => {
        console.log("Error");
      });
  }

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const onChangePicture = (event) => {
    console.log("Inside onchange picture", event.target.files[0]);
    setPicture(event.target.files[0]);
    setFileName(event.target.files[0].name);
    event.target.value = null;
  };

  async function FileUpload(event) {
    setDisable(true);
    await handleFormDataAPI("/admin/uploadUpdatesAndAnnouncements", picture, {
      uploadedBy: props.userDetails.fullName,
      category: coaName,
      schemeNames: [scheme],
    })
      .then((response) => {
        setUniqueId(response.data.uniqueId);
        callAPIOnUpdate();
        // setUploadStatus(true);
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Uploaded Successfully",
          confirmButtonColor: "#1976d2",
          customClass: {
            popup: "swalPopup",
            title: "title1",
          },
        });
      })
      .catch((error) => {
        console.log("Error");
        setLoaderUpload(true);
        setUploadStatus(false);
        handleClose();
      });

    setDisable(false);
  }

  function callAPIOnUpdate() {
    historyApi();
    getUpdatesAnnouncement();
  }

  // search button
  const handleChange = (event) => {
    setSearchString(event.target.value);
    console.log("searching for event", event.target.value);
    console.log("dataAll2", dataAll2);
    let filteredRows = dataAll2.filter((row) => {
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      console.log("temp", temp);
      console.log("searching for event to", event.target.value.toLowerCase());

      return temp.includes(event.target.value.toLowerCase());
    });
    console.log("filteredRows", filteredRows);
    setTableData2(filteredRows);
  };

  // font styling for COA dropdown
  const MenuProps = {
    PaperProps: {
      sx: {
        "& .MuiMenuItem-root": {
          fontSize: 12,
          fontFamily: "Lato",
        },
      },
      style: {
        fontSize: 12,
        fontFamily: "Lato",
      },
    },
  };

  // font styling for edit dropdown
  const MenuProps2 = {
    PaperProps: {
      sx: {
        "& .MuiMenuItem-root": {
          fontSize: 12,
          fontFamily: "Lato",
        },
      },
      style: {
        fontSize: 12,
        fontFamily: "Lato",
        maxHeight: 250,
        width: 250,
      },
    },
  };

  // API to get dropdown data of Category of announcement
  async function getUpdatesAnnouncement() {
    if (props.token) {
      handleAPI("/admin/getUpdatesAndAnnouncementFiles", {}, props.token)
        .then((response) => {
          console.log("response.data -->>", response.data);
          setDataAll(response.data);
          setCoaName(
            Object.keys(response.data)[0] ? Object.keys(response.data)[0] : ""
          );
          setTableData(response.data[Object.keys(response.data)[0]]);
          setListOfCoa(Object.keys(response.data));
          setLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          if (error.errorNavigate) {
            console.log("Redirecting to login");
            props.RESET();
            authStore.clear();
            navigate("/");
          } else {
            console.log("Redirecting to error");

            setLoader(true);
          }
        });
    }
  }
  useEffect(() => {
    props.setPage("AdminUA");
    if (props.userDetails.permissions == "") {
      navigate("/admin/profile");
    } else {
      callAPIOnUpdate();
    }
  }, [props.token]);

  // function to download in category of announcemnt
  async function downloadCoa(uniqueId) {
    console.log("uniqueId of row:>> ", uniqueId);
    await handleAPI(
      "/admin/downloadUpdateAnnouncementFiles",
      { uniqueId: uniqueId, category: coaName },
      props.token
    )
      .then((response) => {
        console.log("Response in download:", response.data);
        window.open(response.data, "_self");
      })
      .catch((error) => {
        console.log(`Error Message:  ${error.errorMessage}`);
      });
  }

  // Dropdown options for Category of Announcement
  function showCoa() {
    return listOfCoa.map((key, index) => {
      return (
        <MenuItem key={index} value={key}>
          {key}
        </MenuItem>
      );
    });
  }

  // when dropdown changes of category of announcement
  const handleCOAChange = (event) => {
    // console.log("event.target.value", event.target.value);
    setCoaName(event.target.value);
    console.log("dataAll(event.target.value)", dataAll[event.target.value]);
    setTableData(dataAll[event.target.value]);
  };

  // Dropdown option of Add scheme inside upload
  function showScheme() {
    return listOfScheme.map((key) => {
      return <MenuItem value={key}>{key}</MenuItem>;
    });
  }

  // When dropdown changes of Add scheme in upload
  const handleSchemeChange = (event) => {
    setScheme(event.target.value);
    console.log("Scheme Value", scheme);
  };

  // Deleting api call
  async function deleteApiCall(calledFrom) {
    setDisable(true);
    console.log("User delete inside ", props.userDetails);
    console.log("COA Name ", coaName);
    console.log(" deleteUniqueId", deleteUniqueId);
    console.log("editUniqueId -->", editUniqueId);

    console.log("Inside delete api call");

    let apiScheme = undefined;
    let apiUniqueid = undefined;

    if (calledFrom === "coa") {
      console.log("setting values for coa");
      apiScheme = [];
      apiUniqueid = deleteUniqueId;
    } else if (calledFrom === "edit") {
      console.log("setting values for edit");
      apiScheme = [editDeleteScheme];
      apiUniqueid = editUniqueId;
    }

    console.log(
      `apiSchemes--> ${apiScheme} \n and \napiUniqueid--> ${apiUniqueid}`
    );

    await handleAPI(
      "/admin/deleteAnnouncementFiles",
      {
        category: coaName,
        schemes: apiScheme,
        uniqueId: apiUniqueid,
        userName: props.userDetails.fullName,
      },
      props.token
    )
      .then((response) => {
        console.log("response of delete Api", response);
        if (response.status) {
          callAPIOnUpdate();

          setOpen3(false);
          handleClose4();
          handleClose2();
        } else if (!response.status) {
          alert("Error! Unable to Revert");
        }
      })
      .catch((error) => {
        console.log("Error");
      });
      setDisable(false);
  }

  // History api
  async function historyApi() {
    if (props.token) {
      handleAPI(
        "/admin/getAnnouncementAndFormsHistory",
        { tag: "announcements" },
        props.token
      )
        .then((response) => {
          console.log("admin forms download history", response.data);
          setTableData2(response.data);
          setDataAll2(response.data);
          setLoaderHistory(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);

          setLoaderHistory(true);
        });
    }
  }

  // upload modal styling
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: 500,
    p: 4,
  };

  // Delete confirmation modal styling
  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: 200,
    p: 4,
  };

  // edit modal styling
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: 500,
    padding: " 10px 32px 10px 32px",
  };

  // category of Announcement headcells
  let FormsCols = [
    {
      field: "fileName",
      numeric: true,
      disablePadding: false,
      headerName: "File Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 470,
      // description: "Name of the Particular File",
    },
    {
      field: "uploadedOn",
      numeric: true,
      disablePadding: false,
      headerName: "Date of Upload",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 140,
    },
    {
      field: "download",
      numeric: true,
      disablePadding: false,
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          console.log("row params", params);
          downloadCoa(params.row.uniqueId);
        };

        return (
          <Button onClick={onClick}>
            <DownloadReport />
          </Button>
        );
      },
    },
    {
      field: "edit",
      numeric: true,
      disablePadding: false,
      headerName: "Edit",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          console.log("Edit icon params.row", params.row);
          setEditUniqueId(params.row.uniqueId);
        };

        return (
          <Button
            onClick={onClick}
            disabled={
              ["all", "write"].includes(props.userPermission) ? false : true
            }
          >
            {["all", "write"].includes(props.userPermission) ? (
              <Editicon />
            ) : (
              <EditiconDisabled />
            )}
          </Button>
        );
      },
    },
    {
      field: "delete",
      numeric: true,
      disablePadding: false,
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClickFn = (e) => {
          e.stopPropagation();
          console.log("first params", params);
          setDeleteUniqueId(params.row.uniqueId);
          setOpen3(true);
          // window.open(params.formattedValue, "_self");
        };

        return (
          <Button
            onClick={onClickFn}
            disabled={
              ["all", "write"].includes(props.userPermission) ? false : true
            }
          >
            {["all", "write"].includes(props.userPermission) ? (
              <DeleteReport />
            ) : (
              <DeleteReportDisabled />
            )}
          </Button>
        );
      },
    },
  ];

  // history table headcells
  let historyHeadCell = [
    {
      field: "fileName",
      numeric: true,
      disablePadding: false,
      headerName: "File Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 180,
    },
    {
      field: "activity",
      numeric: true,
      disablePadding: false,
      headerName: "Activity",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 172,
    },
    {
      field: "user",
      numeric: true,
      disablePadding: false,
      headerName: "User",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 180,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 180,
    },
  ];

  // edit table headcells
  let editHeadCell = [
    {
      field: "scheme",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 630,
    },
    {
      field: "delete",
      numeric: true,
      disablePadding: false,
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClickFn = (e) => {
          e.stopPropagation();
          console.log("first params", params);
          setEditDeleteScheme(params.row.scheme);
          handleOpen4();
        };

        return (
          <Button onClick={onClickFn}>
            <DeleteReport />
          </Button>
        );
      },
    },
  ];

  async function downloadAnnouncements() {
    await handleAPI(
      "/admin/downloadAnnouncementAndFormHistory",
      {
        tag: "announcements",
      },
      props.token
    )
      .then((response) => {
        console.log("Download Status", response);
        if (response.data.historyAvailable) {
          // setDownloadLink(response.data.s3XlsxSignedUrl);
          window.open(response.data.s3XlsxSignedUrl, "_self");
        }
      })
      .catch((error) => {
        console.log("Error");
      });
  }

  return (
    <div style={{ padding: "10px 10px 0px 20px" }}>
      {loader && loaderHistory ? (
        <Grid container style={{ marginTop: "10px", marginBottom: "10px" }}>
          {/* Category of Announcement */}
          <Grid item xs={12}>
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "940px",
              }}
              className={styles.card}
            >
              <CardContent style={{ width: "97%" }}>
                <Grid
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Grid
                    item
                    xs={8}
                    style={{
                      fontFamily: "Lato",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Category of Announcement
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={coaName}
                        onChange={handleCOAChange}
                        sx={{ fontFamily: "lato", fontSize: "12px" }}
                        MenuProps={MenuProps}
                      >
                        {listOfCoa.length > 0 ? (
                          listOfCoa.map((key, index) => {
                            return (
                              <MenuItem key={index} value={key}>
                                {key}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value="">
                            <em>No Schemes Avaialble</em>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Category of Announcements Table  */}
                <RecentTransactionTable
                  data={tableData}
                  headCells={FormsCols}
                  name={`${coaName} Announcements`}
                />
                {/* Edit modal */}
                <Modal
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{ backdropFilter: "blur(10px)" }}
                >
                  <Grid item sx={style2}>
                    {loaderEdit ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{ fontWeight: "bolder", fontSize: "16px" }}
                          >
                            {coaName}
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              variant="standard"
                              sx={{
                                margin: "15px 0px 0px 0px",
                                width: "100%",
                              }}
                            >
                              <InputLabel id="demo-multiple-checkbox-label">
                                Add Scheme
                              </InputLabel>
                              <Select
                                id="demo-multiple-checkbox"
                                multiple
                                value={editSchemeDropdownValue}
                                onChange={handleEditSchemeDropdownChange}
                                renderValue={(selected) => selected.join(", ")}
                                sx={{ fontFamily: "Lato", fontSize: "12px" }}
                                MenuProps={MenuProps2}
                              >
                                {/* {showEditDropdown()} */}
                                {editDropdownData.length > 0 ? (
                                  editDropdownData.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          editSchemeDropdownValue.indexOf(
                                            name
                                          ) > -1
                                        }
                                      />
                                      <ListItemText
                                        sx={{
                                          fontFamily: "Lato",
                                          fontSize: "12px",
                                        }}
                                        primary={name}
                                      />
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="">
                                    <em>No Schemes Avaialble</em>
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            <button
                              onClick={handleEditConfirm}
                              className={
                                editDropdownData.length > 0
                                  ? styles.htmlButton
                                  : styles.htmlButtonDisabled
                              }
                              disabled={false}
                            >
                              <span style={{ color: "#ffffff" }}>Confirm</span>
                            </button>
                          </Grid>
                          {/* Edit Modal Table */}
                          <RecentTransactionTable
                            data={editTableData}
                            headCells={editHeadCell}
                            name="Edit Schemes"
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <LoadingBlack /> */}
                        <ModalLoader />
                      </Grid>
                    )}
                  </Grid>
                </Modal>
                {/* Upload button */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderRadius: "2px solid green",
                  }}
                >
                  <button
                    onClick={handleOpen}
                    className={
                      ["all", "write"].includes(props.userPermission) &&
                      coaName?.length > 0
                        ? styles.htmlButton
                        : styles.htmlButtonDisabled
                    }
                    disabled={
                      ["all", "write"].includes(props.userPermission)
                        ? false
                        : true
                    }
                  >
                    Upload
                  </button>
                  {/* Upload Modal */}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ backdropFilter: "blur(10px)" }}
                  >
                    <Grid item sx={style}>
                      {loaderUpload ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              fontWeight: "600",
                              fontFamily: "lato",
                              marginBottom: "15px",
                            }}
                          >
                            {coaName}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Add scheme
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={scheme}
                                onChange={handleSchemeChange}
                                sx={{ fontFamily: "lato", fontSize: "12px" }}
                                MenuProps={MenuProps}
                              >
                                {listOfScheme.length > 0 ? (
                                  listOfScheme.map((key, index) => {
                                    return (
                                      <MenuItem value={key} key={index}>
                                        {key}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem value="">
                                    <em>No Schemes Avaialble</em>
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={styles.radioButtonCss}
                            sx={{ fontFamily: "Lato" }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FormControl sx={{ fontFamily: "Lato" }}>
                              {/* <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={valueSelected}
                                onChange={handleChange3}
                                sx={{ fontFamily: "Lato" }}
                                row
                              >
                                <FormControlLabel
                                  value="investor"
                                  control={<Radio />}
                                  sx={{ fontFamily: "Lato" }}
                                  label="Investor"
                                />
                                <FormControlLabel
                                  value="distributor"
                                  control={<Radio />}
                                  label="Distributor"
                                />
                                <FormControlLabel
                                  value="both"
                                  control={<Radio />}
                                  label="Both"
                                />
                              </RadioGroup> */}
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            {/* File Upload */}
                            <Grid
                              item
                              xs={12}
                              style={{
                                padding: "10px",
                                width: "450px",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <label htmlFor="upload-data">
                                  <input
                                    style={{ display: "none" }}
                                    id="upload-data"
                                    name="upload-data"
                                    type={
                                      listOfScheme.length <= 0
                                        ? "button"
                                        : "file"
                                    }
                                    onChange={onChangePicture}
                                  />
                                  {/* <button */}
                                  <Button
                                    component="span"
                                    style={{
                                      backgroundColor: "#333333",
                                      borderRadius: "20px",
                                      textTransform: "none",
                                      fontWeight: "800",
                                      fontFamily: "lato",
                                    }}
                                    className={
                                      listOfScheme.length <= 0
                                        ? "btncss-disable"
                                        : "btncss"
                                    }
                                  >
                                    <span style={{ color: "white" }}>
                                      {" "}
                                      Browse
                                    </span>
                                  </Button>
                                  {/* </button> */}
                                </label>
                              </Grid>

                              <Grid item xs={12} style={{ paddingTop: "15px" }}>
                                {fileName ? (
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      fontFamily: "Lato",
                                      paddingTop: "50px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {/* {console.log("picture", picture)} */}
                                    Are you sure you want to upload&nbsp;
                                    <Box
                                      sx={{
                                        fontWeight: 600,
                                        mt: 2,
                                      }}
                                    >
                                      {fileName?.replace(
                                        /^(.{35}).*?(.{5}\.[^.]+)$/,
                                        "$1....$2"
                                      )}{" "}
                                      ?
                                    </Box>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {fileName ? (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                <Button
                                  onClick={FileUpload}
                                  disabled={picture ? false : true}
                                  component="span"
                                  style={{
                                    backgroundColor: "#333333",
                                    borderRadius: "20px",
                                    textTransform: "none",
                                    fontWeight: "800",
                                    fontFamily: "lato",
                                  }}
                                  className ={ disable ? "btncss-disable" : "btncss"}
                                >
                                  <span style={{ color: "#ffffff" }}>
                                    Confirm
                                  </span>
                                </Button>
                              </Typography>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <Grid
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <LoadingBlack /> */}
                          <ModalLoader />
                        </Grid>
                      )}
                    </Grid>
                  </Modal>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* History */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Card
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
                flexDirection: "column",
                width: "940px",
              }}
              className={styles.card}
            >
              <CardContent style={{ width: "97%" }}>
                <Grid
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{
                      // padding: " 1rem 0 0.25rem 0.5rem",
                      fontSize: "18px",
                      fontWeight: "bolder",
                      // marginBottom: "15px",
                      // border: "solid 2px red",
                    }}
                  >
                    History
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      margin: "10px",
                      height: "35px",
                    }}
                  >
                    <div
                      style={{
                        border: "solid 1px black",
                        borderRadius: "30px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Search"
                        type="search"
                        onChange={handleChange}
                        value={searchString}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          ".MuiInputBase-input": {
                            borderBottom: "none !important",
                            padding: "5px 0 0 10px !important",
                            /*margin: -12px ;  */
                          },
                          paddingBottom: "5rem",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <RecentTransactionTable
                  data={tableData2}
                  headCells={historyHeadCell}
                  name="History data"
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={downloadAnnouncements}
                    className={
                      dataAll2?.length > 0
                        ? styles.htmlButton
                        : styles.htmlButtonDisabled
                    }
                  >
                    Download as Excel
                  </button>
                </Grid>
              </CardContent>
            </Card>
            {/* Confirm Delete in COA */}
            <Modal
              open={open3}
              onClose={handleClose3}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ backdropFilter: "blur(10px)" }}
            >
              <Grid container sx={styleDelete}>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontFamily: "Lato",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Are you sure you want to delete this?
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    component="span"
                    onClick={() => deleteApiCall("coa")}
                    style={{
                      backgroundColor: "#333333",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontWeight: "800",
                      fontFamily: "lato",
                    }}
                    className ={ disable ? "btncss-disable" : "btncss"}
                  >
                    <span style={{ color: "#ffffff" }}>Confirm</span>
                  </Button>
                </Grid>
              </Grid>
            </Modal>
            {/* Confirm Delete in Edit modal */}
            <Modal
              open={open4}
              onClose={handleClose4}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ backdropFilter: "blur(10px)" }}
            >
              <Grid container sx={styleDelete}>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontFamily: "Lato",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Are you sure you want to delete this?
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    component="span"
                    onClick={() => deleteApiCall("edit")}
                    style={{
                      backgroundColor: "#333333",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontWeight: "800",
                      fontFamily: "lato",
                    }}
                    className ={ disable ? "btncss-disable" : "btncss"}
                  >
                    <span style={{ color: "#ffffff" }}>Confirm</span>
                  </Button>
                </Grid>
              </Grid>
            </Modal>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    userPermission: state.mainReducer.userPermission,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(AdminUA);
