import * as React from "react";
import Grid from "@mui/material/Grid";
import Investments from "./Investments";
import TopClients from "./TopClients";

function GraphsDistributor(props) {
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const todayDate = formatDate(new Date());

  return (
    <Grid
      container
      style={{ fontFamily: "Lato", padding: "1rem 1rem 1rem 1rem" }}
    >
      <Grid item xs={6}>
        <Investments
          todayDate={todayDate}
          doughnutData={props.investmentsData}
        />
      </Grid>
      <Grid item xs={6}>
        <TopClients todayDate={todayDate} pieData={props.topClientsData} />
      </Grid>
    </Grid>
  );
}

export default GraphsDistributor;
