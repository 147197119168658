// STEP 1 - Include Dependencies
// Include react
import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";

// STEP 2 - Chart Data
function PieChartDistributor(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  let colors = ["#BBBBBB", "#3081B4", "#717171", "#333333", "#89C4F4"];

  let data = {
    labels: Object.keys(props.aumWiseData).map(
      (key) => props.aumWiseData[key].investorName
    ),
    datasets: [
      {
        data: Object.keys(props.aumWiseData).map(
          (key) => props.aumWiseData[key].aum
        ),
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
    responsive: true, // Make the chart responsive
    maintainAspectRatio: false, // Disable aspect ratio to allow resizing
    width: 550, // Set the desired width
    height: 550, // Set the desired height
  };

  return <Pie data={data} options={options} />;
}

export default PieChartDistributor;
