export var URL = {
  AIF_BACKEND_URL: process.env.REACT_APP_AIF_BACKEND_URL,
  SAAS_URL: process.env.REACT_APP_SAAS_URL,
  SAAS_URL_ONBOARD: process.env.REACT_APP_SAAS_URL_ONBOARD,
  SAAS_IDM: process.env.REACT_APP_SAAS_IDM,
  SAAS_CODE_URL: process.env.REACT_APP_SAAS_CODE_URL,
  FUND: process.env.REACT_APP_FUND ? process.env.REACT_APP_FUND : "161",
};
export var SAAS_AUTH = {
  COGNITO_APPNAME: process.env.REACT_APP_COGNITO_APPNAME
    ? process.env.REACT_APP_COGNITO_APPNAME
    : "kfinAlternates",
  TENET_CODE: process.env.REACT_APP_TENET_CODE
    ? process.env.REACT_APP_TENET_CODE
    : "2633709306",
  COGNITO_APPLICATION_ID: process.env.REACT_APP_COGNITO_APPLICATION_ID
    ? process.env.REACT_APP_COGNITO_APPLICATION_ID
    : "a8627e8a-95ba-44f4-852a-4496629efe2b",
  REGION: process.env.REACT_APP_REGION
    ? process.env.REACT_APP_REGION
    : "ap-south-1",
  CONGNITO_AUTHENTICATION_FLOW_TYPE: process.env
    .REACT_APP_CONGNITO_AUTHENTICATION_FLOW_TYPE
    ? process.env.REACT_APP_CONGNITO_AUTHENTICATION_FLOW_TYPE
    : "CUSTOM_AUTH",
  SAAS_ENCRYPTION: process.env.REACT_APP_SAAS_ENCRYPTION
    ? process.env.REACT_APP_SAAS_ENCRYPTION.replace(/\s/g, "") === "true"
    : true,
  API_KEY: process.env.REACT_APP_API_KEY,
  SERVER_ENCRYPTION_KEY: process.env.REACT_APP_SERVER_ENCRYPTION_KEY,
  SAAS_ENCRYPTION_KEY: process.env.REACT_APP_SAAS_ENCRYPTION_KEY,
  SECURELS_ENCRYPTION_KEY: process.env.REACT_APP_SECURELS_ENCRYPTION_KEY,
};

// These details should be maintain in ENV , to disable end-user accessibility
export var AMC_DETAILS = {
  INVESTMENT_TYPE: process.env.REACT_APP_INVESTMENT_TYPE || "openEnded", // Available options ["closeEnded","openEnded"]
  FUND_CATEGORY: (process.env.REACT_APP_FUND_CATEGORY || "CAT-III").split(","), // Available options "CAT-II","CAT-III","CAT-II,CAT-III"
  USER_PORTAL: process.env.REACT_APP_USER_PORTAL || "INV", // Available options ["INV","DIS","BOTH"]
  ENABLE_BROKERAGE: true, // Available Option true or false
  MODE: "light",
  PAYOUT_DISTRUBUTION: false,
  SCHEME_STARTING_MONTH: "2024-06-01", // This is difference between current month and scheme starting month(put 0 if the difference is greater then 0)
};

export const COLOR_CODE = {
  LEFT_NAV: AMC_DETAILS.MODE === "dark" ? "#323332" : "#f2f2f2",
  LEFT_NAV_BUTTON_BG: AMC_DETAILS.MODE === "dark" ? "#445268" : "#e6e6e6",
  LEFT_NAV_BUTTON: AMC_DETAILS.MODE === "dark" ? "#FEFEFF" : "#000000",
  THEME_ELEMENT_COLOR: "#2057A6",
  BG_COLOR: "#000084",
  TABLE_HEADER: "#000084",
};

export var USE_DEFAULT = process.env.REACT_APP_USE_DEFAULT
  ? process.env.REACT_APP_USE_DEFAULT.replace(/\s/g, "") === "true"
  : false; // IF true then use default value as mentioned above or else use api to fetch

export var SECURE_LS_ENCRYPT = process.env.REACT_APP_SECURE_LS_ENCRYPT
  ? process.env.REACT_APP_SECURE_LS_ENCRYPT.replace(/\s/g, "") === "true"
  : true; // IF true encrypt local storage
