import { useRef, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import "../../assets/css/passwordToOtp.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { URL, SAAS_AUTH } from "../../variable";
import { handleSaasAPI } from "../../API/handleAPI";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import capitalizeCase from "../../utils/capitalizeCase";
import CryptoJS from "crypto-js";
import { ReactComponent as LoginImage } from "../../assets/svg/Kfintech Login 1.svg";
import styles from "../../assets/css/Login.module.css";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";

function Otp(props) {
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]); // from store all 6 digit otp
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Invalid OTP");
  const [userEmail, setUserEmail] = useState("demo@kfintech.com");
  const navigate = useNavigate();
  const { state } = useLocation();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1700,
    timerProgressBar: true,
  });

  useEffect(() => {
    document.getElementById(parseInt(0)).focus();
    if (state?.email) {
      setUserEmail(state.email);
    } else {
      navigate("/");
    }
    //console.log("user email where otp is sent ", userEmail);
  }, []);
  // handle the focus of input field on backspace
  const keyDownHandler = (e) => {
    if (e.key == "Backspace" && e.target.id > -1) {
      let newOtp = [...otpValue];
      e.preventDefault();
      newOtp[e.target.id] = "";
      setOtpValue(newOtp);

      e.target.id > 0 &&
        document.getElementById((parseInt(e.target.id) - 1).toString()).focus();
    }
  };

  const resendOtp = () => {
    const payload = {
      userName: userEmail,
      code: authStore.getItem("tenantCode"),
      appName: SAAS_AUTH.COGNITO_APPNAME,
    };
    handleSaasAPI("/auth/resendCode", payload)
      .then((res) => {
        //console.log(res);
        if (res.status == "true") {
          Toast.fire({
            icon: "success",
            title: "OTP send successfully",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle the focus of input field on value change
  const inputHandler = (e) => {
    // //console.log(e)
    // //console.log(e.target.value)
    if (!isNaN(e.target.value)) {
      let newOtp = [...otpValue];
      newOtp[e.target.id] = e.target.value;
      setOtpValue(newOtp);
      // //console.log(newOtp)
      if (e.target.id < 5) {
        // //console.log(e.target.id)
        document.getElementById((parseInt(e.target.id) + 1).toString()).focus();
      }
    }
  };

  // verify the otp
  async function verifyOtp(event) {
    event.preventDefault();
    let otp = "";
    if (otpValue) {
      otpValue.map((data) => {
        otp = otp + data;
      });
    }
    //console.log(otp);
    setDisabled(true);
    const payload = {
      userName: userEmail,
      otp: otp,
      code: authStore.getItem("tenantCode"),
      appName: SAAS_AUTH.COGNITO_APPNAME,
    };
    await handleSaasAPI("/auth/confirmAccount", payload)
      .then((res) => {
        //console.log(res);
        if (res.status && res.message == "userConfirmed") {
          // navigate("/");
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.errorData?.message == "CodeMismatchException") {
          setIsOtpValid(false);
          setErrorMessage("Invalid Otp , Try again");
        } else {
          alert("respoce changes");
        }
      });
    setDisabled(false);
  }

  return (
    <Grid item container direction="row" className={styles.background}>
      <Grid item container xs direction="column">
        <Grid item className={styles.container}>
          <div style={{ paddingBottom: "80px" }}>
            {" "}
            <LoginImage />
          </div>
        </Grid>
      </Grid>

      <Grid item xs className={styles.container}>
        <div
          style={{
            height: "60vh",
            width: "40vw",
            backgroundColor: "rgb(255 255 255 / 59%)",
            borderRadius: "40px",
            border: "1px solid black",
            display: "flex",
            //   alignItems: "center",
            minWidth: "400px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bolder",
              fontSize: "1.7rem",
              fontFamily: "Lato",
              paddingTop: "2rem",
            }}
          >
            Confirm Account
          </div>

          <div>
            <form onSubmit={verifyOtp}>
              <Grid
                style={{
                  marginTop: "3rem",
                  maxHeight: "30vh",
                  minHeight: "30vh",
                  scale: "110%",
                }}
              >
                {/* <TextField
                          variant="standard"
                          label="Enter OTP"
                          style={{ border: "none", borderRadius: "0px" }}
                          className={styles.inputFormat}
                          type="password"
                          name="OTP"
                          // value={pwd}
                          onChange={(e) => {
                            // setPassword(e.target.value);
                          }}
                          // helperText={otpMsg}
                          FormHelperTextProps={{ style: { color: "gray" } }}
                          // error={otpMsg === "Incorrect OTP."}
                        /> */}
                <Stack spacing={1}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      fontFamily: "Lato",
                    }}
                  >
                    An OTP has been sent to
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.8rem",
                      color: "black",
                      fontFamily: "Lato",
                      fontWeight: "bolder",
                      marginBottom: "1rem",
                    }}
                  >
                    {userEmail}
                  </div>
                  <Stack className="otp-stack" direction="row" spacing={0.1}>
                    <input
                      type="text"
                      inputMode="numeric"
                      id="0"
                      className="otp-input"
                      maxLength="1"
                      autoComplete="off"
                      onChange={inputHandler}
                      onKeyDown={keyDownHandler}
                      value={otpValue[0]}
                    />
                    <input
                      type="text"
                      inputMode="numeric"
                      id="1"
                      className="otp-input"
                      maxLength="1"
                      autoComplete="off"
                      onChange={inputHandler}
                      onKeyDown={keyDownHandler}
                      value={otpValue[1]}
                    />
                    <input
                      type="text"
                      inputMode="numeric"
                      id="2"
                      className="otp-input"
                      maxLength="1"
                      autoComplete="off"
                      onChange={inputHandler}
                      onKeyDown={keyDownHandler}
                      value={otpValue[2]}
                    />
                    <input
                      type="text"
                      inputMode="numeric"
                      id="3"
                      className="otp-input"
                      maxLength="1"
                      autoComplete="off"
                      onChange={inputHandler}
                      onKeyDown={keyDownHandler}
                      value={otpValue[3]}
                    />
                    <input
                      type="text"
                      inputMode="numeric"
                      id="4"
                      className="otp-input"
                      maxLength="1"
                      autoComplete="off"
                      onChange={inputHandler}
                      onKeyDown={keyDownHandler}
                      value={otpValue[4]}
                    />
                    <input
                      type="text"
                      inputMode="numeric"
                      id="5"
                      className="otp-input"
                      maxLength="1"
                      autoComplete="off"
                      onChange={inputHandler}
                      onKeyDown={keyDownHandler}
                      value={otpValue[5]}
                    />
                  </Stack>
                  {!isOtpValid ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: "red",
                        fontFamily: "Lato",
                      }}
                    >
                      {errorMessage}
                    </span>
                  ) : null}
                </Stack>

                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    style={{
                      fontSize: "10px",
                      fontFamily: "Lato",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      // setHeaderName("Update");
                      resendOtp();
                    }}
                  >
                    Resend Otp
                  </Button>
                </div>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "5px",
                }}
              >
                <button
                  component="span"
                  className={disabled ? "login-disabled" : "loginButton"}
                  onClick={verifyOtp}
                  // disabled={pan.length < 10 || pan.length > 10}
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
          {/* <div style={{ marginBottom: "10vh" }}></div> */}
        </div>
      </Grid>
    </Grid>
  );
}

const mapStatetoProps = (state) => {
  return {
    token: state.mainReducer.token,
    authorization: state.mainReducer.authorization,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setToken: (val) => {
      dispatch({ type: "CHANGE-TOKEN", token: val });
    },
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setAuthorization: (val) => {
      dispatch({ type: "CHANGE-AUTHORIZATION", authorization: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Otp);
