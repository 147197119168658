import CryptoJS from "crypto-js";
import { SAAS_AUTH } from "../variable";

// Encryption key is hard coded need to be removed
const encrpytionKey = SAAS_AUTH.SERVER_ENCRYPTION_KEY;
const saasEncryptionKey = SAAS_AUTH.SAAS_ENCRYPTION_KEY;
export const encrypt = (myDataObj, isSaas) => {
  const myData = JSON.stringify(myDataObj);
  try {
    // console.log(
    //   "is saas ",
    //   isSaas,
    //   "encyption key ",
    //   isSaas ? saasEncryptionKey : encrpytionKey
    // );
    const enData = CryptoJS.AES.encrypt(
      myData,
      isSaas ? saasEncryptionKey : encrpytionKey
    ).toString();
    //console.log("Data Encrypted");
    return enData;
  } catch (err) {
    //console.log(err);
    console.log("error in encryption", err);
  }
};

export const decrypt = (myData, isSaas) => {
  // //console.log("data to decrypt",myData)
  try {
    var decryptedData = CryptoJS.AES.decrypt(
      myData,
      isSaas ? saasEncryptionKey : encrpytionKey
    );
    //console.log("Decrypted Data Before parse :", decryptedData);
    var decryptedDataParsed = JSON.parse(
      decryptedData.toString(CryptoJS.enc.Utf8)
    );
    //console.log("Decrypted Data after parse", decryptedDataParsed);
    //console.log("Data Decrypted");
    return decryptedDataParsed;
  } catch (err) {
    //console.log(err);
  }

  return decryptedDataParsed;
};
