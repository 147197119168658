import * as React from "react";

// import styles from "../../../assets/css/PieTable.module.css";
import styles from "../../../assets/css/GraphsDistributor.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function createData(fundType, totalCommitment) {
  return { fundType, totalCommitment };
}
function InvestmentsTable(props) {
  const convertToCurrency = function standardData(initialData) {
    try {
      let finalData = parseFloat(initialData);
      finalData = finalData.toString();
      finalData = finalData.split(".")[0];
      finalData = parseFloat(finalData).toLocaleString("en-IN");

      return finalData;
    } catch (err) {
      console.log("Error in convert to currency:", err);

      throw err;
    }
  };

  // console.log("inv props", props);
  // let data = Object.entries(props.data);

  // const rows = props.data.map((v) => createData(v.fundType, v.totalCommitment));
  let colors = [
    "#89C4F4",
    "#BBBBBB",
    "#337FC9",
    "#9AECF3",
    "#717171",
    "#36DAE9",
    "#333333",
    "#4492A8",
    "#66BCFF",
    "#7094C6",
    "#8ED8B7",
  ];

  return (
    <div>
      <TableContainer>
        <Table size="small" aria-label="a dense table" width="450px">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}></TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Scheme Name</TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;AUM &nbsp;&nbsp;&nbsp;&nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  ".MuiTableCell-root": {
                    borderBottomColor: "white",
                    fontSize: "12px !important",
                    lineHeight: "1.5",
                  },
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: `${colors[index]}`,
                    width: "10px",
                    // padding: "5px",
                    // border: "solid 2px red",
                    // borderRadius: "100%",
                  }}
                  className={styles.tableCell}
                ></TableCell>
                <TableCell className={styles.SecondColumn}>
                  {row["schemeName"]}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {convertToCurrency(row["aum"])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default InvestmentsTable;
