import * as React from "react";
import Grid from "@mui/material/Grid";
import styles from "../../assets/css/LeftNav.module.css";
// import { ReactComponent as KfinLogo } from "../../assets/svg/axisleftnav.svg";
import { ReactComponent as KfinLogo } from "../../assets/svg/KfintechNavBarLogo.svg";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { AMC_DETAILS } from "../../variable";

let ReportSVG, DashboardSVG, UpdatesSVG, FormsDownloadSVG, TickerSVG;

if (AMC_DETAILS.MODE === "dark") {
  ReportSVG = require("../../assets/svg/ClientReport.svg").ReactComponent;
  DashboardSVG = require("../../assets/svg/Dashboard.svg").ReactComponent;
  UpdatesSVG = require("../../assets/svg/Updates.svg").ReactComponent;
  FormsDownloadSVG =
    require("../../assets/svg/FormsDownload.svg").ReactComponent;
  TickerSVG = require("../../assets/svg/TickerSVG.svg").ReactComponent;
} else {
  ReportSVG = require("../../assets/svg/ClientReportLight.svg").ReactComponent;
  DashboardSVG = require("../../assets/svg/DashboardLight.svg").ReactComponent;
  UpdatesSVG = require("../../assets/svg/UpdatesLight.svg").ReactComponent;
  FormsDownloadSVG =
    require("../../assets/svg/FormsDownloadLight.svg").ReactComponent;
  TickerSVG = require("../../assets/svg/TicketSVGLight.svg").ReactComponent;
}
function LeftNavAdmin(props) {
  return (
    <div style={{ paddingTop: "65px", zIndex: "2" }}>
      <Grid
        className={styles.leftNavBar}
        container
        maxWidth={"18rem"}
        minWidth={"18rem"}
        marginTop={"-64px"}
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="fixed"
      >
        {" "}
        <Grid
          item
          className={styles.leftTile}
          style={{
            paddingTop: " 70px",
            paddingLeft: "35px",
            paddingBottom: "50px",
            zIndex: "4",
          }}
        >
          <KfinLogo />
        </Grid>
        <Grid
          item
          className={
            props.page === "ADM" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/admin/dashboard"
            style={{ textDecoration: "none" }}
            className={styles.leftTile}
          >
            <DashboardSVG className={styles.tileSvg} />
            Dashboard
          </NavLink>
        </Grid>
        <Grid
          item
          className={
            props.page === "CLIA" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/admin/clientReports"
            style={{ textDecoration: "none" }}
            className={styles.leftTile}
          >
            <ReportSVG className={styles.tileSvg} />
            Client Reports
          </NavLink>
        </Grid>
        <Grid
          item
          className={
            props.page === "AdminUA" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <Grid
            item
            xs={12}
            // style={{ display: "flex", justifyContent: "space-around" }}
          >
            <NavLink
              to="/admin/updates"
              style={{ textDecoration: "none" }}
              className={styles.leftTile}
            >
              <UpdatesSVG className={styles.tileSvg} />
              Updates &nbsp;&nbsp;&nbsp;&nbsp;
              {props.unread ? (
                <span className={styles.dot}>{props.unread}</span>
              ) : (
                <></>
              )}
            </NavLink>
          </Grid>
        </Grid>
        <Grid
          item
          className={
            props.page === "FDA" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/admin/standardForms"
            style={{ textDecoration: "none" }}
            className={styles.leftTile}
          >
            <FormsDownloadSVG className={styles.tileSvg} />
            Standard Forms
          </NavLink>
        </Grid>
        <Grid
          item
          className={
            props.page === "tickerMessage"
              ? styles.leftTileGolden
              : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/admin/tickerAdmin"
            style={{ textDecoration: "none" }}
            className={styles.leftTile}
          >
            <TickerSVG className={styles.tileSvg} />
            Ticker Message
          </NavLink>
        </Grid>
        {/* <Grid
          item
          className={
            props.page === "Approvals" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/admin/approvals"
            style={{ textDecoration: "none" }}
            className={styles.leftTile}
          >
            <ApprovalSVG className={styles.tileSvg} />
            Approvals
          </NavLink>
        </Grid> */}
        <Grid item className={styles.lastTile}></Grid>
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    unread: state.mainReducer.unread,
  };
};
export default connect(mapStatetoProps)(LeftNavAdmin);
