import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import camelCase from "../utils/camelCase";
import { useState, useEffect } from "react";
import { handleAPI } from "../API/handleAPI";
import { Button } from "@mui/material";
import styles from "../assets/css/RecentTransaction.module.css";
import RecentTransactionTable from "./RecentTransactionTable";
import { ReactComponent as LoadingBlack } from "../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../views/Loader";
import Swal from "sweetalert2";
import { popupError } from "../utils/errorHandler";
import { AMC_DETAILS } from "../variable";

function RecentTransactionDistributor(props) {
  const [dataAll, setDataAll] = useState([]);
  const [dataContribution, setDataContribution] = useState([]);
  const [dataDistribution, setDataDistribution] = useState([]);
  const [dataRedemption, setDataRedemption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState({});
  const [valueSelected, setValueSelected] = useState("All");
  const [rows, setRows] = useState([]);
  const [dataDefaulters, setDataDefaulters] = useState([]);
  const [dataInvestors, setDataInvestors] = useState([]);
  const [dataInvestorsSchemeWise, setDataInvestorsSchemeWise] = useState([]);
  const [loaderDefaulter, setLoaderDefaulter] = useState(false);
  const [loaderInvestor, setLoaderInvestor] = useState(false);
  // const [downloadLinkDefaulters, setDownloadLinkDefaulters] = useState({});
  // const [downloadLinkInvestors, setDownloadLinkInvestors] = useState({});
  const [schemeNumber, setSchemeNumber] = useState(0); // Scheme selected in the dropdown
  const [listOfSchemes, setListOfSchemes] = useState([]); // the list of schemes
  const [colsAll, setColsAll] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    props.setPage("RecentTransactionDistributor");
  }, []);

  let RecentTransactionCols = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      align: "left",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 250,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "transactionType",
      numeric: true,
      disablePadding: false,
      headerName: "Transaction Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 145,
    },
    {
      field: "nAV",
      numeric: true,
      disablePadding: false,
      headerName: "NAV",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "amount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];
  let RedemptionCols = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      align: "left",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 250,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "transactionType",
      numeric: true,
      disablePadding: false,
      headerName: "Transaction Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 145,
    },

    {
      field: "nAV",
      numeric: true,
      disablePadding: false,
      headerName: "NAV",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "amount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];
  let DistributionCols = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      align: "left",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 250,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "transactionType",
      numeric: true,
      disablePadding: false,
      headerName: "Transaction Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 145,
    },

    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "amount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];

  let InvestorCols = [
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "left",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 260,
    },
    {
      field: "pAN",
      numeric: true,
      disablePadding: false,
      headerName: "PAN",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 170,
    },
    // {
    //   field: "schemeName",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "Scheme Name",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 350,
    // },
    // {
    //   field: "numberOfInvestors",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "No. of Investors",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 145,
    // },

    {
      field: "totalCommitment",
      numeric: true,
      disablePadding: false,
      headerName: "Total Commitment",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },

    {
      field: "totalContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Total Contribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
    {
      field: "aUM",
      numeric: true,
      disablePadding: false,
      headerName: "AUM",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },

    {
      field: "uncalledContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Uncalled Contribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 195,
    },
  ];
  let InvestorColsOpenEnded = [
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "left",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 300,
    },
    {
      field: "pAN",
      numeric: true,
      disablePadding: false,
      headerName: "PAN",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    // {
    //   field: "schemeName",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "Scheme Name",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 350,
    // },
    // {
    //   field: "numberOfInvestors",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "No. of Investors",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 145,
    // },

    {
      field: "totalContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Total Contribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "aUM",
      numeric: true,
      disablePadding: false,
      headerName: "AUM",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];

  let DefaultersCols = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      align: "left",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "totalCommitment",
      numeric: true,
      disablePadding: false,
      headerName: "Total Commitment",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "capitalContributed",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Contributed",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "amountDefaulted",
      numeric: true,
      disablePadding: false,
      headerName: "Amount Defaulted",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];

  // Recent Transactions API
  useEffect(() => {
    //console.log("Entered recent transactions use effect");
    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getInvestmentRecentTransactions",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("dist recent transactions", response.data);

          setDataAll(response.data.recentTransactionData.All);
          setDataContribution(response.data.recentTransactionData.Contribution);
          setDataRedemption(response.data.recentTransactionData.Redemption);
          setDataDistribution(response.data.recentTransactionData.Distribution);
          setRows(response.data.recentTransactionData.All);
          setColsAll(RecentTransactionCols);
          setDownloadLink({
            xlsxLink: response.data.s3XlsxSignedUrl,
            pdfLink: response.data.s3PdfSignedUrl,
          });
          setLoader(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoader(true);
        });
    }
  }, [props.userDetails.pan]);

  // list of investors API
  useEffect(() => {
    //console.log("Entered list of investors use effect");

    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getInvestmentListOfInvestors",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("dist investors", response.data);

          setDataInvestors(response.data);
          setDataInvestorsSchemeWise(response.data.All);
          setSchemeNumber(0);

          setListOfSchemes(Object.keys(response.data));

          // setDownloadLinkInvestors({
          //   xlsxLink: response.data.s3XlsxSignedUrl,
          //   pdfLink: response.data.s3PdfSignedUrl,
          // });
          setLoaderInvestor(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoaderInvestor(true);
        });
    }
  }, [props.userDetails.pan]);

  // defaulters API
  useEffect(() => {
    //console.log("Entered defaulters use effect");

    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getInvestmentListOfDefaulters",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("dist defaulters", response.data);

          setDataDefaulters(response.data);

          // setDownloadLinkDefaulters({
          //   xlsxLink: response.data.s3XlsxSignedUrl,
          //   pdfLink: response.data.s3PdfSignedUrl,
          // });
          setLoaderDefaulter(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoaderDefaulter(true);
        });
    }
  }, [props.userDetails.pan]);

  // Radio Button handle change function
  const handleChange = (event) => {
    setValueSelected(event.target.value);

    if (event.target.value === "All") {
      setRows(dataAll);
      setColsAll(RecentTransactionCols);
    } else if (event.target.value === "Contribution") {
      setRows(dataContribution);
      setColsAll(RecentTransactionCols);
    } else if (event.target.value === "Distribution") {
      setRows(dataDistribution);
      setColsAll(DistributionCols);
    } else if (event.target.value === "Redemption") {
      setRows(dataRedemption);
      setColsAll(RedemptionCols);
    }
  };

  function downloadXlsxData() {
    //console.log("Inside downloadXLSX Link --->", downloadLink.xlsxLink);
    window.open(downloadLink.xlsxLink, "_self");
  }

  function downloadPdfData() {
    //console.log("Inside downloadPDF Link --->", downloadLink.pdfLink);
    window.open(downloadLink.pdfLink, "_self");
  }

  // function downloadXlsxDataDefaulters() {
  //   //console.log(
  //     "Inside downloadXLSX Link --->",
  //     downloadLinkDefaulters.xlsxLink
  //   );
  //   window.open(downloadLinkDefaulters.xlsxLink, "_self");
  // }

  // function downloadPdfDataDefaulters() {
  //   //console.log("Inside downloadPDF Link --->", downloadLinkDefaulters.pdfLink);
  //   window.open(downloadLinkDefaulters.pdfLink, "_self");
  // }

  // function downloadXlsxDataInvestors() {
  //   //console.log(
  //     "Inside downloadXLSX Link --->",
  //     downloadLinkInvestors.xlsxLink
  //   );
  //   window.open(downloadLinkInvestors.xlsxLink, "_self");
  // }

  // function downloadPdfDataInvestors() {
  //   //console.log("Inside downloadPDF Link --->", downloadLinkInvestors.pdfLink);
  //   window.open(downloadLinkInvestors.pdfLink, "_self");
  // }

  // what scheme dropdown will show
  function showSchemes() {
    return listOfSchemes.map((key, index) => {
      return (
        <MenuItem key={index} value={index}>
          {key}
        </MenuItem>
      );
    });
  }

  // when the scheme drop down changes -> scheme number changes and use effect (scheme number dependency) is called
  const handleSchemeNumberChange = (event) => {
    setSchemeNumber(event.target.value);
    //console.log("Sheme number", schemeNumber);
  };

  // when scheme number changes , tableDataSchemeWise is set as per that scheme
  useEffect(() => {
    if (schemeNumber === 0) setDataInvestorsSchemeWise(dataInvestors.All);
    else {
      let schemeNameString = listOfSchemes[schemeNumber];
      setDataInvestorsSchemeWise(dataInvestors[schemeNameString]);

      // setDataInvestorsSchemeWise(dataInvestors[listOfSchemes[schemeNumber]]);

      // let schemeNameString = currentSchemes[schemeNumber - 1]; // 0 ADP,1 ADQ ,2 ADR

      // setTableDataSchemeWise(
      //   tableDataCategoryWise.filter((row) => {
      //     let temp = JSON.stringify(row);
      //     temp = temp.toLowerCase();
      //     return temp.includes(schemeNameString.toLowerCase());
      //   })
      // );
    }
  }, [schemeNumber]);

  const MenuProps = {
    PaperProps: {
      sx: {
        "& .MuiMenuItem-root": {
          fontSize: 12,
          fontFamily: "Lato",
        },
      },
      style: {
        fontSize: 12,
        fontFamily: "Lato",
      },
    },
  };

  return (
    <div
      style={{
        padding: "10px 10px 0px 20px",
      }}
    >
      {loader && loaderDefaulter && loaderInvestor ? (
        <Grid container>
          {/* Recent Transactions */}
          <Grid item xs={12}>
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "solid 2px red",
                // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
                width: "940px",
              }}
              className={styles.card}
            >
              <CardContent
                style={{
                  // border: "solid 2px red",
                  width: "97%",
                }}
              >
                <Grid
                  item
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{
                      // padding: " 1rem 0 0.25rem 0.5rem",
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Recent Transactions
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    className={styles.radioButtonCss}
                    sx={{ fontFamily: "Lato" }}
                  >
                    <FormControl sx={{ fontFamily: "Lato" }}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={valueSelected}
                        onChange={handleChange}
                        sx={{ fontFamily: "Lato" }}
                        row
                      >
                        <FormControlLabel
                          value="All"
                          control={<Radio />}
                          sx={{ fontFamily: "Lato" }}
                          label="All"
                        />
                        <FormControlLabel
                          value="Contribution"
                          control={<Radio />}
                          label="Contribution"
                        />
                        <FormControlLabel
                          value="Redemption"
                          control={<Radio />}
                          label="Redemption"
                        />
                        <FormControlLabel
                          value="Distribution"
                          control={<Radio />}
                          label="Distribution"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Table */}
                <RecentTransactionTable
                  name="Recent Transaction"
                  data={rows}
                  headCells={colsAll}
                />

                {/* Download buttons */}
                <Grid item xs={12} className={styles.buttonGrid}>
                  <Grid item xs={6} className={styles.DownloadAsExcelButton}>
                    <button
                      onClick={downloadXlsxData}
                      className={
                        dataAll?.length > 0
                          ? styles.htmlButton
                          : styles.htmlButtonDisabled
                      }
                      disabled={false}
                    >
                      Download as Excel
                    </button>
                  </Grid>
                  <Grid item xs={6} className={styles.DownloadAsPdfButton}>
                    <button
                      onClick={downloadPdfData}
                      className={
                        dataAll?.length > 0
                          ? styles.htmlButton
                          : styles.htmlButtonDisabled
                      }
                    >
                      Download as PDF
                    </button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* List of Investors */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "solid 2px red",
                // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
                width: "940px",
              }}
              className={styles.card}
            >
              <CardContent
                style={{
                  // border: "solid 2px red",
                  width: "97%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // height: "1rem",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{
                      // padding: " 1rem 0 0.25rem 0.5rem",
                      fontSize: "18px",
                      fontWeight: "bolder",
                      // marginBottom: "15px",
                      // border: "solid 2px red",
                    }}
                  >
                    List of Investors
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    style={{
                      fontFamily: "Lato",
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                      // border: "solid 2px blue"
                    }}
                  >
                    <FormControl
                      variant="standard"
                      sx={{
                        m: 1,
                        width: "60%",
                        // marginRight: "3rem",
                        // paddingBottom: "2rem",
                      }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Scheme Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={schemeNumber}
                        onChange={handleSchemeNumberChange}
                        sx={{ fontFamily: "Lato", fontSize: "12px" }}
                        MenuProps={MenuProps}
                      >
                        {showSchemes()}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Table */}
                <RecentTransactionTable
                  name="Investors"
                  data={dataInvestorsSchemeWise}
                  headCells={
                    AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                      ? InvestorColsOpenEnded
                      : InvestorCols
                  }
                />

                {/* Download buttons */}
                {/* <Grid item xs={12} className={styles.buttonGrid}>
                  <Grid item xs={6} className={styles.DownloadAsExcelButton}>
                    <button
                      onClick={downloadXlsxDataInvestors}
                      className={styles.htmlButton}
                    >
                      Download as Excel
                    </button>
                  </Grid>
                  <Grid item xs={6} className={styles.DownloadAsPdfButton}>
                    <button
                      onClick={downloadPdfDataInvestors}
                      className={styles.htmlButton}
                    >
                      Download as PDF
                    </button>
                  </Grid>
                </Grid> */}
              </CardContent>
            </Card>
          </Grid>

          {/*  List of Defaulters */}
          {AMC_DETAILS.INVESTMENT_TYPE != "openEnded" && (
            <Grid
              item
              xs={12}
              style={{ marginTop: "20px", marginBottom: "10px" }}
            >
              <Card
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  // border: "solid 2px red",
                  // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
                  width: "940px",
                }}
                className={styles.card}
              >
                <CardContent
                  style={{
                    // border: "solid 2px red",
                    width: "97%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      // padding: " 1rem 0 0.25rem 0.5rem",
                      fontSize: "18px",
                      fontWeight: "bolder",
                      marginBottom: "15px",
                    }}
                  >
                    List of Defaulters
                  </Grid>
                  {/* Table */}
                  <RecentTransactionTable
                    name="Defaulters"
                    data={dataDefaulters}
                    headCells={DefaultersCols}
                  />

                  {/* Download buttons */}
                  {/* <Grid item xs={12} className={styles.buttonGrid}>
                  <Grid item xs={6} className={styles.DownloadAsExcelButton}>
                    <button
                      onClick={downloadXlsxDataDefaulters}
                      className={styles.htmlButton}
                    >
                      Download as Excel
                    </button>
                  </Grid>
                  <Grid item xs={6} className={styles.DownloadAsPdfButton}>
                    <button
                      onClick={downloadPdfDataDefaulters}
                      className={styles.htmlButton}
                    >
                      Download as PDF
                    </button>
                  </Grid>
                </Grid> */}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(RecentTransactionDistributor);
