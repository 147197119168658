import * as React from "react";

import { handleAPI } from "../../API/handleAPI";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import styles from "../../assets/css/Profile.module.css";
import { connect } from "react-redux";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import capitalizeCase from "../../utils/capitalizeCase";
import Swal from "sweetalert2";


function InvestorProfile(props) {
  let [loader, setLoader] = useState(false);
  let [userProfile, setUserProfile] = useState({
    "A/C Holder Name": "",
    CONTACT: "",
    EMAIL: "",
    Address: "",
    PAN_NO: "",
    NOMINEE: "",
    D_EMAIL: "",
    DISTIBUTOR_NAME: "",
    FOLIO: "",
    "KIN Number": "",
  });
  let [profileTable, setProfileTable] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    props.setPage("InvestorProfile");
  }, []);

  useEffect(() => {
    //console.log("Pan Details in investor----", props.userDetails.pan);
    if (props.userDetails.pan) {
      handleAPI(
        "/investor/getProfile",
        {
          pan: props.userDetails.pan,
          userType: "investor",
        },
        props.token
      )
        .then((response) => {
          //console.log("User Details received", response.data);
          let userDetails = {
            "A/C Holder Name": response.data.userDetails["A/C Holder Name"]
              ? response.data.userDetails["A/C Holder Name"]
              : props.userDetails.fullName,

            CONTACT: response.data.userDetails.CONTACT
              ? response.data.userDetails.CONTACT
              : props.userDetails.phNumber,

            EMAIL: response.data.userDetails.EMAIL
              ? response.data.userDetails.EMAIL
              : props.userDetails.email,

            Address: response.data.userDetails.Address,
            PAN_NO: response.data.userDetails.PAN_NO
              ? response.data.userDetails.PAN_NO
              : props.userDetails.pan,

            NOMINEE: response.data.userDetails.NOMINEE,
            D_EMAIL: response.data.userDetails.D_EMAIL,
            DISTIBUTOR_NAME: response.data.userDetails.DISTIBUTOR_NAME,
            FOLIO: response.data.userDetails.FOLIO,
            "KIN Number": response.data.userDetails["KIN Number"],
          };
          setUserProfile(userDetails);
          setProfileTable(response.data.bankDetails);
          setLoader(true);
        })
        .catch((error) => {
          setLoader(true);
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
         
          setLoader(true);
        });
    }
  }, [props.userDetails.pan]);
  let tableData = [];
  //console.log("Profile Table", profileTable);
  // Object.keys(profileTable).map((key) => {
  //   tableData.push({
  //     folioNumber: key,
  //     currentValue: props.cat2Data.middleData.category2[key].currentValue,
  //     investedValue: props.cat2Data.middleData.category2[key].investedValue,
  //   });
  // });
  var result = profileTable.map((key) => ({
    accountNumber: key["Account Number"],
    accountType: key["Account Type"],
  }));

  //console.log("resyult", result);
  return (
    <div>
      {loader ? (
        <>
          <Grid item xs={12} className={styles.profileFont}>
            Profile
          </Grid>
          {/* <Card
        style={{
          maxWidth: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      > */}
          <Grid container className={styles.profileBox}>
            {/* Ac Holder Name */}
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid item xs={12} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-multiline-static"
                  label="A/C Holder Name"
                  multiline
                  maxRows={1}
                  value={
                    userProfile["A/C Holder Name"] === null
                      ? "NA"
                      : userProfile["A/C Holder Name"]
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>

            {/* Contact , email id  */}
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  // border: "solid 2px red",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid item xs={6} className={styles.profileTextField}>
                  <TextField
                    style={{ width: "75%" }}
                    id="standard-multiline-static"
                    label="Phone Number"
                    multiline
                    maxRows={1}
                    value={
                      userProfile.CONTACT === null ? "NA" : userProfile.CONTACT
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: {
                        font: "unset",
                        fontFamily: "Lato",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      style: { fontWeight: "800", color: "#333333" },
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} className={styles.profileTextField}>
                  <TextField
                    style={{ width: "75%" }}
                    id="standard-multiline-static"
                    label="Email ID"
                    multiline
                    maxRows={1}
                    value={
                      userProfile.EMAIL === null ? "NA" : userProfile.EMAIL
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: {
                        font: "unset",
                        fontFamily: "Lato",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      style: { fontWeight: "800", color: "#333333" },
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Address */}
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
                // border: "solid 2px red",
              }}
            >
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{
                    width: "75%",
                  }}
                  id="standard-multiline-static"
                  label="Address"
                  multiline
                  maxRows={5}
                  value={
                    userProfile.Address === null ? "NA" : userProfile.Address
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>

            {/* Pan,Kin,Nominee,Dist Name, Dist Email id */}
            <Grid
              container
              style={{
                // border: "solid 2px red",
                width: "90%",
                padding: "5px 5px 5px 5px",
                // display: "flex",
                // flexDirection: "row",
              }}
            >
              <Grid
                item
                xs={6}
                // style={{ border: "solid 2px blue" }}
                className={styles.profileTextField}
              >
                <TextField
                  style={{ width: "75%" }}
                  id="standard-multiline-static"
                  label="PAN"
                  multiline
                  maxRows={1}
                  value={
                    userProfile.PAN_NO === null ? "NA" : userProfile.PAN_NO
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-multiline-static"
                  label="KIN"
                  multiline
                  maxRows={1}
                  value={
                    userProfile["KIN Number"] === null
                      ? "NA"
                      : userProfile["KIN Number"]
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
              {/* <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-multiline-static"
                  label="Nominee"
                  multiline
                  maxRows={1}
                  value={
                    userProfile.NOMINEE === null ? "NA" : userProfile.NOMINEE
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid> */}
              {/* <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-multiline-static"
                  label="Distributor Name"
                  multiline
                  maxRows={1}
                  value={
                    userProfile.DISTIBUTOR_NAME === null
                      ? "NA"
                      : userProfile.DISTIBUTOR_NAME
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid> */}
              {/* <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-multiline-static"
                  label="Distributor E-Mail ID"
                  multiline
                  maxRows={1}
                  value={
                    userProfile.D_EMAIL === null ? "NA" : userProfile.D_EMAIL
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid> */}
            </Grid>
            <Grid
              container
              style={{
                borderTop: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  fontWeight: "800",
                  fontSize: "14px",
                  color: "#333333",
                  padding: "8px 0px 8px 6px",
                }}
              >
                Bank Mandate Details
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* {//console.log("Profile table in return", profileTable)} */}
                {profileTable.length > 0 ? (
                  <Table className={styles.tableCss}>
                    {/* <Table> */}
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.tableCellCss}>
                          Folio No.
                        </TableCell>

                        <TableCell className={styles.tableCellCss}>
                          IFSC
                        </TableCell>

                        <TableCell className={styles.tableCellCss}>
                          Account Number
                        </TableCell>

                        <TableCell className={styles.tableCellCss}>
                          Account Type
                        </TableCell>

                        <TableCell className={styles.tableCellCss}>
                          Bank Name
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={styles.profileTable}>
                      {profileTable.map((row) => (
                        <TableRow
                          key={row["Folio No."]}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: "none",
                            },
                            ".MuiTableCell-root": {
                              borderBottom: "none",
                            },
                          }}
                        >
                          <TableCell className={styles.profileTable}>
                            {row["Folio No."]}{" "}
                          </TableCell>

                          <TableCell className={styles.profileTable}>
                            {row["IFSC"]}{" "}
                          </TableCell>

                          <TableCell className={styles.profileTable}>
                            {row["Account Number"]}{" "}
                          </TableCell>

                          <TableCell className={styles.profileTable}>
                            {row["Account Type"]}{" "}
                          </TableCell>

                          <TableCell className={styles.profileTable}>
                            {row["Bank Name"]}{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* </Table> */}
                  </Table>
                ) : (
                  <>
                    <div className={styles.noInvestmentSecondLineCss}>
                      No bank mandate details to show
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* </Card> */}
        </>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </div>
  );
}

const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    page: state.mainReducer.page,
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(InvestorProfile);
