import * as React from "react";
import Grid from "@mui/material/Grid";
import styles from "../../../assets/css/GraphsDistributor.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AumTopClients from "./AumTopClients";
import TopClientsExtension from "./TopClientsExtension";

function TopClients(props) {
  const InvestorHeadCells = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",

      headerClassName: "tableModal--Header",
      width: 258,
      align: "left",
    },
    {
      field: "mobileNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Mobile Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },

    {
      field: "emailID",
      numeric: true,
      disablePadding: false,
      headerName: "Email ID",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 176,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },

    {
      field: "aUM",
      numeric: true,
      disablePadding: false,
      headerName: "AUM",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  return (
    <div>
      <Grid
        container
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Card
          variant="outlined"
          style={{ height: "430px", width: "450px" }}
          className={styles.card}
        >
          <Grid item style={{ marginTop: "0.9rem" }}>
            <CardContent>
              <Grid item xs={12} className={styles.lineGrid__row1}>
                <Grid item xs={4} className={styles.cardHeader}>
                  Top Clients
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  height: "auto",
                }}
              >
                <AumTopClients aumWiseData={props.pieData} />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  height: "auto",
                  justifyContent: "right",
                  marginTop: "0.6rem",
                  paddingTop: "1rem",
                  // border: "solid 2px red",
                }}
              >
                <TopClientsExtension
                  name="InvestorData"
                  headCells={InvestorHeadCells}
                  // data={props.data}
                  detailHeader="Top 10 Clients"
                />
              </Grid>
            </CardContent>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
}

export default TopClients;
