import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import ValuePreviewTable from "./ValuePreviewTable";
import Cat2 from "../../assets/svg/cat2_white.svg";
import Cat23Table from "./Cat23Table";
import Cat3 from "../../assets/svg/cat3_white.svg";
import styles from "../../assets/css/CurrentInvestedValue.module.css";
import { AMC_DETAILS } from "../../variable";

const Android12Switch = styled(Switch)(({ theme }) => ({
  height: 45,
  width: 160,
  padding: 8,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(72px)",
      "& .MuiSwitch-thumb": {
        backgroundImage: `url(${Cat3})`,
        opacity: 1,
        border: 0,
        content: "''",
        position: "absolute",
        left: 0,
        top: 7,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
    ".MuiSwitch-thumb": {
      backgroundImage: `url(${Cat2})`,
      opacity: 1,
      border: 0,
      content: "''",
      position: "absolute",
      left: 0,
      top: 7,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    opacity: 1,
    backgroundColor: "#aab4be",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 36,
      height: 26,
    },
    "&:before": {
      backgroundImage: `url(${Cat3})`,
      opacity: 1,
      border: 0,
      content: "''",
      position: "absolute",
      top: 15,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      right: 25,
    },
    "&:after": {
      backgroundImage: `url(${Cat2})`,
      opacity: 1,
      border: 0,
      content: "''",
      position: "absolute",
      top: 15,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      left: 25,
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "var(--themecolor)",
    borderRadius: 30 / 2,
    width: 80,
    height: 30,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
}));
const Cat23HeadCells = [
  {
    id: "folioNo",
    numeric: true,
    disablePadding: false,
    label: "Folio Number",
  },
  {
    id: "clientName",
    numeric: true,
    disablePadding: false,
    label: "Scheme Name",
  },
  {
    id: "fundName",
    numeric: true,
    disablePadding: false,
    label: "Total Capital Commitment",
  },
  {
    id: "planName",
    numeric: true,
    disablePadding: false,
    label: "Current Value",
  },
  {
    id: "acommitmentAmount",
    numeric: true,
    disablePadding: false,
    label: "Share Class",
  },
  {
    id: "Contribution Till Date",
    numeric: true,
    disablePadding: false,
    label: "Contribution Till Date",
  },
  {
    id: "Contribution Due Date",
    numeric: true,
    disablePadding: false,
    label: "Contribution Due Date",
  },
  {
    id: "Entry Nav",
    numeric: true,
    disablePadding: false,
    label: "Entry Nav",
  },
];
export default function CurrentInvestedValue(props) {
  const [flag, setFlag] = React.useState(
    AMC_DETAILS.FUND_CATEGORY[0] == "CAT-III" ? true : false
  );
  //console.log("1st drill", props.catBox);
  const styleSummary = {
    color: "#ED8554",
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "26px",
    lineHeight: "39px",
    letterSpacing: "0.3px",
    paddingRight: "20px",
  };
  const handelCheck = () => {
    setFlag(flag ? false : true);
  };
  return (
    <Grid container className={styles.mainGrid}>
      <Grid item xs={12} className={styles.switchButtonGrid}>
        {AMC_DETAILS.FUND_CATEGORY?.length > 1 && (
          <FormGroup>
            <FormControlLabel
              control={
                <Android12Switch checked={flag} onChange={handelCheck} />
              }
            />
          </FormGroup>
        )}
      </Grid>
      <Grid item xs={12}>
        <ValuePreviewTable flag={flag} catBox={props.catBox} />
      </Grid>
      <Grid item xs={12} style={{ marginRight: "1rem" }}>
        <Cat23Table
          name="Cat Table"
          headCells={props.Cat23HeadCells}
          detailHeader="Investments"
          flag={flag}
        />
      </Grid>
    </Grid>
  );
}
