// Modal Popup of the Dashboard Bubble Boxes

import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { handleAPI } from "../API/handleAPI";
import TableModal from "./TableModal";
import "../assets/css/DashboardTable.css";
import { connect } from "react-redux";
import { ReactComponent as OpenTable } from "../assets/svg/OpenTable copy.svg";
import { ReactComponent as CloseSVG } from "../assets/svg/Close.svg";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactComponent as LoadingBlack } from "../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../views/modalLoader";
import Swal from "sweetalert2";
import { popupError } from "../utils/errorHandler";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "480px",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid black",
  boxShadow: 24,
  borderRadius: 3,
  p: 1,
  padding: "2px 5px",
};

function DashboardTable(props) {
  const [searchString, setSearchString] = useState("");
  const [rows, setRows] = useState(props.data);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState({});
  const navigate = useNavigate();

  // const [aifLoading, setAifLoading] = useState(true);
  //console.log("ADM CLIENT NAME", props.admClientName.PAN);
  let handleOpen = async function () {
    return;
  };
  if (props.userDetails?.accountType?.includes("ADM")) {
    if (props.admUser === "INV") {
      handleOpen = async () => {
        setOpen(true);
        // setAifLoading(false);

        // console.log("props inside dashboard table", props);
        await handleAPI(
          "/investor/getDashboardBubbleBoxDetails",
          {
            pan: props.admClientName.PAN,
            fileName: props.name,
            navDate: props.navDate,
          },
          props.token
        )
          .then((response) => {
            setTableData(response.data.data);
            setRows(response.data.data);
            setDownloadLink({
              xlsxLink: response.data.s3XlsxSignedUrl,
              pdfLink: response.data.s3PdfSignedUrl,
            });
            setLoader(true);
            // setAifLoading(true);
            // setBubbleBox(response.data);
          })
          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");

            setOpen(false);
            setLoader(true);
          });
      };
    } else if (props.admUser === "DIS") {
      handleOpen = async () => {
        setOpen(true);
        // setAifLoading(false);

        await handleAPI(
          "/distributor/getDashboardBubbleBoxDetails",
          {
            pan: props.admClientName.PAN,
            fileName: props.name,
            navDate: props.navDate,
          },
          props.token
        )
          .then((response) => {
            setTableData(response.data.data);
            setRows(response.data.data);
            setDownloadLink({
              xlsxLink: response.data.s3XlsxSignedUrl,
              pdfLink: response.data.s3PdfSignedUrl,
            });
            setLoader(true);
            // setAifLoading(true);
          })

          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");

            setLoader(true);
            setOpen(false);
          });
      };
    }
  } else if (
    props.userDetails?.accountType?.includes("INV") ||
    props.userDetails?.accountType?.includes("DIS")
  ) {
    if (props.userDetails?.accountType.includes("INV")) {
      handleOpen = async () => {
        setOpen(true);
        // setAifLoading(false);

        if (props.name !== "PerformanceTable") {
          // console.log("props inside dashboard table", props);
          await handleAPI(
            "/investor/getDashboardBubbleBoxDetails",
            {
              pan: props.userDetails.pan,
              fileName: props.name,
              navDate: props.navDate,
            },
            props.token
          )
            .then((response) => {
              setTableData(response.data.data);
              setRows(response.data.data);
              setDownloadLink({
                xlsxLink: response.data.s3XlsxSignedUrl,
                pdfLink: response.data.s3PdfSignedUrl,
              });
              setLoader(true);
              // setAifLoading(true);
              // setBubbleBox(response.data);
            })
            .catch((error) => {
              console.log(`Error Message:  ${error}`);
              console.log("Redirecting to error");
              setLoader(true);
              setOpen(false);
            });
        } else if (props.name === "PerformanceTable") {
          // let tempData = [...props.performanceData.performanceData];
          // tempData.shift();
          // // console.log("TEMPPPPP", tempData);s
          // // console.log("YYZZXXXX", props.performanceData.performanceData);
          // setTableData(tempData);
          // setRows(tempData);
          // setDownloadLink({
          //   xlsxLink: props.performanceData.s3XlsxSignedUrl,
          //   pdfLink: props.performanceData.s3PdfSignedUrl,
          // });
          // setLoader(true);
          // // setAifLoading(true);

          await handleAPI(
            "/investor/getDashboardPerformanceDataDetails",
            {
              pan: props.userDetails.pan,
              navDate: props.navDate,
            },
            props.token
          )
            .then((response) => {
              setRows(response.data.performanceData);
              setTableData(response.data.performanceData);
              setDownloadLink({
                xlsxLink: response.data.s3XlsxSignedUrl,
                pdfLink: response.data.s3PdfSignedUrl,
              });
              setLoader(true);
            })
            .catch((error) => {
              console.log(`Error Message:  ${error.errorMessage}`);
              console.log("Redirecting to error");
              setLoader(true);
              setOpen(false);
            });
        }
      };
    } else if (props.userDetails?.accountType?.includes("DIS")) {
      handleOpen = async () => {
        setOpen(true);
        // setAifLoading(false);

        await handleAPI(
          "/distributor/getDashboardBubbleBoxDetails",
          {
            pan: props.userDetails.pan,
            fileName: props.name,
            navDate: props.navDate,
          },
          props.token
        )
          .then((response) => {
            setTableData(response.data.data);
            setRows(response.data.data);
            setDownloadLink({
              xlsxLink: response.data.s3XlsxSignedUrl,
              pdfLink: response.data.s3PdfSignedUrl,
            });
            setLoader(true);
            // setAifLoading(true);
          })

          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");
            setLoader(true);

            setOpen(false);
          });
      };
    }
  }

  const handleClose = () => {
    setSearchString("");
    setOpen(false);
    setLoader(false);
    // setAifLoading(true);
  };

  const handleChange = (event) => {
    setSearchString(event.target.value);

    let filteredRows = tableData.filter((row) => {
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setRows(filteredRows);
  };

  function downloadXlsxData() {
    //console.log("Inside downloadXLSX Link --->", downloadLink.xlsxLink);
    window.open(downloadLink.xlsxLink, "_self");
  }

  function downloadPdfData() {
    //console.log("Inside downloadPDF Link --->", downloadLink.pdfLink);
    window.open(downloadLink.pdfLink, "_self");
  }
  //console.log("ASD", props.admUser);
  return (
    <div>
      <Grid container>
        <div onClick={handleOpen}>
          <OpenTable />
        </div>
        {
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ backdropFilter: "blur(10px)" }}
          >
            <Box sx={style}>
              {loader ? (
                <>
                  <Grid item className="header">
                    <Grid item xs={6} className="title">
                      {props.detailHeader}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      onClick={handleClose}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <CloseSVG />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginRight: "2rem",
                      height: "35px",
                    }}
                  >
                    <div
                      style={{
                        border: "solid 1px black",
                        borderRadius: "30px",
                        width: "10rem",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Search"
                        type="search"
                        onChange={handleChange}
                        value={searchString}
                        sx={{
                          ".MuiDataGrid-menuIcon": {
                            backgroundColor: "#000084",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          ".MuiInputBase-input": {
                            borderBottom: "none !important",
                            padding: "5px 0 0 10px !important",
                            /*margin: -12px ;  */
                          },
                          paddingBottom: "5rem",
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: "0.3rem" }}>
                    <TableModal
                      name={props.name}
                      headCells={props.headCells}
                      data={rows}
                    />
                    {/* {//console.log("rows current nav", rows)} */}
                  </Grid>

                  <Grid item xs={12}>
                    <div className="downloadBtn">
                      <button
                        style={{ backgroundColor: "#333333" }}
                        onClick={downloadXlsxData}
                        className={
                          tableData.length > 0 ? "btncss" : "btncss-disable"
                        }
                      >
                        Download as Excel
                      </button>
                      <button
                        style={{ backgroundColor: "#333333" }}
                        onClick={downloadPdfData}
                        className={
                          tableData.length > 0 ? "btncss" : "btncss-disable"
                        }
                      >
                        Download as PDF
                      </button>
                    </div>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <LoadingBlack /> */}
                  <ModalLoader />
                </Grid>
              )}
            </Box>
          </Modal>
        }
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    navDate: state.mainReducer.navDate,
    admUser: state.mainReducer.admUser,
    admClientName: state.mainReducer.admClientName,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setAdmUser: (val) => {
      dispatch({ type: "CHANGE-ADM-USER", unread: val });
    },
    setAdmClientName: (val) => {
      dispatch({ type: "CHANGE-CLIENT-NAME", admClientName: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(DashboardTable);
