import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { handleAPI } from "../../API/handleAPI";
// import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import { ReactComponent as BlueSearchIcon } from "../../assets/svg/BlueSearchIcon.svg";
import { ReactComponent as SearchIconYellowBox } from "../../assets/svg/SearchIconYellowBox.svg";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import Loader from "../../views/Loader";
import styles from "../../assets/css/ClientReports.module.css";
import ReportsDownload from "../ClientReports/ReportsDownload";
import AdminDistributor from "./AdminDistributor";
import AdminInvestor from "./AdminInvestor";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";
import { AMC_DETAILS } from "../../variable";

function Admin(props) {
  useEffect(() => {
    props.setShowTLNav(true);
    props.setPage("ADM");
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function () {
    //   console.log("run on back button click");
    //   window.history.go(1);
    // };
  }, []);
  // props.setPage("CLID");
  //console.log("props.token in admin page", props.token);
  const [valueSelected, setValueSelected] = useState("Investor");
  const [searchData, setSearchData] = useState([]);
  const [investorSearchData, setInvestorSearchData] = useState([]);
  const [distributorSearchData, setDistributorSearchData] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [showDashboardPage, setShowDashboardPage] = useState(false);
  const [clientName, setClientName] = useState();
  let USER_TYPE = AMC_DETAILS.USER_PORTAL;
  const [investor, setInvestor] = useState(USER_TYPE === "DIS" ? false : true);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("props.user status ", props.userDetails.status);
    if (props.userDetails.permissions == "") {
      navigate("/admin/profile");
    } else if (props.token) {
      handleAPI(
        "/admin/getDashboardSearchResults",
        {
          // pan: props.userDetails.pan,
          // pan: "AAACU2414K",
        },
        props.token
      )
        .then((response) => {
          //console.log("SEARCH BAR", response.data);
          setDistributorSearchData(response.data.distributorData);
          setInvestorSearchData(response.data.investorData);
          if (USER_TYPE === "DIS") {
            setSearchData(response.data.distributorData);
          } else {
            setSearchData(response.data.investorData);
          }
          setSearchLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          setSearchLoader(true);
        });
    }
  }, [props.token]);
  //console.log("search data", searchData);
  let options = [];
  if (searchLoader) {
    searchData.map((item, index) => {
      let temp = {
        value: `${item.label} ${item.PAN}`,
        label: item.label,
        PAN: item.PAN,
      };
      options.push(temp);
    });
  }

  const formatOptionLabel = ({ value, label, PAN }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 30px 0px 30px",
      }}
    >
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#808080" }}>{PAN}</div>
    </div>
  );

  const handleChangeRadio = (event) => {
    setValueSelected(event.target.value);

    if (event.target.value === "Investor") {
      setSearchData(investorSearchData);
      setInvestor(true);
    } else if (event.target.value === "Distributor") {
      setSearchData(distributorSearchData);
      setInvestor(false);
    }
  };
  //console.log("Investorr", investor);
  const handleChange = (event) => {
    //console.log("iska value hai ye ---->", event.value);
    if (event.value) {
      setClientName(event);
      props.setAdmClientName(event);
      setShowDashboardPage(true);
    }
  };
  //console.log("EVENT", clientName);
  function AdminInv() {
    // props.setAdmUser("INV");
    return (
      <AdminInvestor
        clientName={clientName}
        setShowDashboardPage={setShowDashboardPage}
      />
    );
  }
  function AdminDis() {
    // props.setAdmUser("DIS");
    return (
      <AdminDistributor
        clientName={clientName}
        setShowDashboardPage={setShowDashboardPage}
      />
    );
  }
  return (
    <>
      {showDashboardPage ? (
        investor ? (
          AdminInv()
        ) : (
          AdminDis()
        )
      ) : searchLoader ? (
        <Grid
          container
          style={{
            // border: "solid 2px red",
            marginTop: "1.3rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Text */}
          <Grid item style={{ fontWeight: "bolder" }}>
            Search the PAN, and we'll fetch the Investment details.
          </Grid>
          <Grid
            item
            xs={8}
            className={styles.radioButtonCss}
            sx={{ fontFamily: "Lato" }}
          >
            {USER_TYPE === "BOTH" && (
              <FormControl sx={{ fontFamily: "Lato" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueSelected}
                  onChange={handleChangeRadio}
                  sx={{ fontFamily: "Lato" }}
                  row
                >
                  <FormControlLabel
                    value="Investor"
                    control={<Radio />}
                    sx={{ fontFamily: "Lato" }}
                    label="Investor"
                  />
                  <FormControlLabel
                    value="Distributor"
                    control={<Radio />}
                    label="Distributor"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>

          {/* Search DropDown */}

          <Grid
            item
            style={{
              marginTop: "3rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              //   backgroundColor: "#FED561",
              //   border: "solid 2px green",
              //   height: "100%",
            }}
          >
            <Grid
              item
              style={{
                // border: "solid 2px red",
                backgroundColor: "#FED561",
                height: "2.5rem",
                width: "2.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BlueSearchIcon />
            </Grid>
            <Grid
              item
              style={{
                width: "35rem",
              }}
            >
              <Select
                // defaultValue={{ label: "Select Dept", value: 0 }}
                defaultValue={null}
                formatOptionLabel={formatOptionLabel}
                options={options}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    admUser: state.mainReducer.admUser,
    admClientName: state.mainReducer.admClientName,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    setAdmUser: (val) => {
      dispatch({ type: "CHANGE-ADM-USER", admUser: val });
    },
    setAdmClientName: (val) => {
      dispatch({ type: "CHANGE-CLIENT-NAME", admClientName: val });
    },
    setShowTLNav: (val) => {
      dispatch({ type: "CHANGE-SHOW-TLNAV", showTLNav: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Admin);
