import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../Components/Login/Login";
import Signup from "../Components/Login/Signup";
import { connect } from "react-redux";
import Error from "../Components/Error";
import Otp from "../Components/Login/Otp";
import TLNav from "../Components/Nav/TLNav";
import InvestorProfile from "../Components/Profile/InvestorProfile";
import RecentTransactionInvestor from "../Components/RecentTransactionInvestor";
import ClientReportsInvestor from "../Components/ClientReports/ClientReportsInvestor";
import UpdatesAndAnnouncementsInvestor from "../Components/UpdatesAndAnnouncements/UpdatesAndAnnouncementsInvestor";
import FormsAndDownloadInvestor from "../Components/FormsAndDownload/FormsAndDownloadInvestor";
import Investor from "../Components/Investor";
import TickerAdmin from "../Components/Admin/TickerAdmin";
import Approvals from "../Components/Admin/Approvals";
import AdminUA from "../Components/Admin/AdminUA";
import FormsAndDownloadsAdmin from "../Components/Admin/FormsAndDownloadsAdmin";
import ClientReportsAdmin from "../Components/Admin/ClientReportsAdmin";
import AdminProfile from "../Components/Profile/AdminProfile";
import Admin from "../Components/Admin/Admin";
import FormsAndDownloadDistributor from "../Components/FormsAndDownload/FormsAndDownloadDistributor";
import UpdatesAndAnnouncementsDistributor from "../Components/UpdatesAndAnnouncements/UpdatesAndAnnouncementsDistributor";
import ClientReportsDistributor from "../Components/ClientReports/ClientReportsDistributor";
import RecentTransactionDistributor from "../Components/RecentTransactionDistributor";
import DistributorProfile from "../Components/Profile/DistributorProfile";
import Distributor from "../Components/Distributor/Distributor";
import { handleAPI } from "../API/handleAPI";
import { authStore } from "../utils/authStore";
import { popupError } from "../utils/errorHandler";
import capitalizeCase from "../utils/capitalizeCase";
import Brokerage from "../Components/Distributor/Brokerage";
import { AMC_DETAILS } from "../variable";

function RouterDefaultPage(props) {
  let navigate = useNavigate();

  useEffect(() => {
    // Get necessary details from localstorage
    let idmCredential = {};
    idmCredential.congnitoToken = authStore.getItem("Auth");
    idmCredential.email = authStore.getItem("Auth_email");
    idmCredential.idmUser = authStore.getItem("IDM_response");
    idmCredential.amcFundCode = authStore.getItem("fundCode");

    //  Check if all required crendential is avaiable in localstorage or not
    if (
      idmCredential.congnitoToken &&
      idmCredential.email &&
      idmCredential.idmUser &&
      authStore.getItem("fundCode") &&
      authStore.getItem("tenantCode") &&
      authStore.getItem("logSessionId") &&
      authStore.getItem("kuid")
    ) {
      console.log(
        "Authorization props Authorization Not Null:",
        props.authorization
      );
      // Set auth token of congito in redux
      props.setToken(idmCredential.congnitoToken);

      // For now set the user type as AMCUser
      idmCredential.idmUser.userType = "AMCUser";

      let IdmuserData = idmCredential.idmUser;
      // Store the user details in redux
      IdmuserData.fullName = IdmuserData?.lastName
        ? capitalizeCase(IdmuserData?.firstName) +
          " " +
          capitalizeCase(IdmuserData?.lastName)
        : capitalizeCase(IdmuserData?.firstName);
      console.log("full name ", IdmuserData.fullName);
      props.setUserDetails(IdmuserData);

      // Get all unseen number for all types of user(INV ,DIS)
      async function getUnseenNumberDetails() {
        try {
          // Get unseen number for investor
          if (idmCredential?.idmUser?.accountType.includes("INV")) {
            await handleAPI(
              "/investor/getUpdateAnnouncementUnseenNumber",
              {
                pan: idmCredential.idmUser.pan,
              },
              idmCredential.congnitoToken
            ).then((response) => {
              props.setUnread(response.data.unseenFiles);
            });

            // Get unseen number for distributor
          } else if (idmCredential.idmUser?.accountType.includes("DIS")) {
            await handleAPI(
              "/distributor/getUpdateAnnouncementUnseenNumber",
              {
                pan: idmCredential.idmUser.pan,
              },
              idmCredential.congnitoToken
            ).then((response) => {
              props.setUnread(response.data.unseenFiles);
            });

            // Store admin permission details in redux
          } else if (idmCredential.idmUser?.accountType.includes("ADM")) {
            if (
              idmCredential.idmUser.userType === "Admin" ||
              idmCredential.idmUser.userType === "SuperAdmin"
            )
              props.setUserPermission("all");
            else {
              if (idmCredential.idmUser?.permissions) {
                props.setUserPermission(
                  idmCredential.idmUser?.permissions?.toLowerCase()
                );
              } else {
                props.setUserPermission("none");
              }
            }
          }
        } catch (error) {
          console.log("error in app.js", error);
        }
      }

      getUnseenNumberDetails();
    } else {
      // Navigate to login page if any credential is mission in local storage
      console.log("navigate to login In app js");
      navigate("/");
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/verifyotp" element={<Otp />} />
        <Route path="*" element={<Error />} />
        {authStore.getItem("IDM_response")?.["accountType"]?.includes("INV") ? (
          <Route path="/investor" element={<TLNav />}>
            <Route path="dashboard" element={<Investor />} />
            <Route
              path="investorProfile"
              element={<InvestorProfile details={props.userDetails} />}
            />
            <Route
              path="transactionHistory"
              element={<RecentTransactionInvestor />}
            />
            <Route path="reports" element={<ClientReportsInvestor />} />
            <Route
              path="updates"
              element={<UpdatesAndAnnouncementsInvestor />}
            />
            <Route
              path="standardForms"
              element={<FormsAndDownloadInvestor />}
            />
          </Route>
        ) : authStore
            .getItem("IDM_response")
            ?.["accountType"]?.includes("DIS") ? (
          <Route path="/distributor" element={<TLNav />}>
            <Route path="dashboard" element={<Distributor />} />
            <Route path="distributorProfile" element={<DistributorProfile />} />
            <Route
              path="investmentMaster"
              element={<RecentTransactionDistributor />}
            />
            <Route
              path="investorReports"
              element={<ClientReportsDistributor />}
            />
            <Route
              path="updates"
              element={<UpdatesAndAnnouncementsDistributor />}
            />
            <Route
              path="standardForms"
              element={<FormsAndDownloadDistributor />}
            />
            {AMC_DETAILS.ENABLE_BROKERAGE && (
              <Route path="brokerage" element={<Brokerage />} />
            )}
          </Route>
        ) : authStore
            .getItem("IDM_response")
            ?.["accountType"]?.includes("ADM") ? (
          <Route path="/admin" element={<TLNav />}>
            <Route path="dashboard" element={<Admin />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="clientReports" element={<ClientReportsAdmin />} />
            <Route path="updates" element={<AdminUA />} />
            <Route path="standardForms" element={<FormsAndDownloadsAdmin />} />
            <Route path="tickerAdmin" element={<TickerAdmin />} />
            <Route path="approvals" element={<Approvals />} />
          </Route>
        ) : (
          <></>
        )}
      </Routes>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    authorization: state.mainReducer.authorization,
    token: state.mainReducer.token,
    unread: state.mainReducer.unread,
    showLoader: state.mainReducer.showLoader,
    showTLNav: state.mainReducer.showTLNav,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setAuthorization: (val) => {
      dispatch({ type: "CHANGE-AUTHORIZATION", authorization: val });
    },
    setToken: (val) => {
      dispatch({ type: "CHANGE-TOKEN", token: val });
    },
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
    setUserPermission: (val) => {
      dispatch({ type: "CHANGE-USER-PERMISSION", userPermission: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(RouterDefaultPage);
