import { Card, CardContent, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import Box from "@mui/material/Box";
import camelCase from "../../utils/camelCase";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { handleAPI } from "../../API/handleAPI";
import { Button } from "@mui/material";
import DashboardTable from "../DashboardTable";
import DashboardDistributorTable from "./DashboardDistributorTable";
import styles from "../../assets/css/ActiveClients.module.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


function ActiveClients(props) {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getDashboardActiveClients",
        {
          pan: props.userDetails.pan,
          fileName: "ActiveClients",
        },
        props.token
      )
        .then((response) => {
          setData(response.data.data);
          setLoader(true);
          setDownloadLink({
            xlsxLink: response.data.s3XlsxSignedUrl,
            pdfLink: response.data.s3PdfSignedUrl,
          });
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoader(true);
         
        });
    }
  }, [props.userDetails.pan]);

  //console.log("Distributor Table", props);
  let rows = [];
  let cols = props.activeClientsHeadCells;

  data.map((data) => {
    let e = {};
    Object.keys(data).map((key) => {
      e[camelCase(key)] = data[key];
    });
    rows.push(e);
  });
  rows.map((row, index) => (row.id = `${index + 1}`));
  cols.map((col, index) => (col["key"] = `${index + 1}`));

  rows = rows.slice(0, 5);

  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      className={styles.card}
    >
      <CardContent>
        <Grid
          item
          xs={12}
          style={{
            padding: " 1rem 0 0.25rem 0.5rem",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Active Clients
        </Grid>
        <Box
          sx={{
            height: 335,
            width: "875px",

            "& .tableModal--Header": {
              backgroundColor: "#333333",
              color: "white",
              fontFamily: "Lato",
            },
          }}
        >
          <DataGrid
            style={{
              display: "flex",
              fontFamily: "Lato",
            }}
            rows={rows}
            columns={cols}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            hideFooter={true}
          />
        </Box>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "flex-end",
            height: "auto",
            justifyContent: "right",
          }}
        >
          <Button>
            <DashboardDistributorTable
              headCells={props.activeClientsHeadCells}
              detailHeader="Active Clients"
              data={data}
              downloadLink={downloadLink}
            />
          </Button>
        </Grid>
      </CardContent>
    </Card>
    // </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ActiveClients);
