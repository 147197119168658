import { useEffect, useState } from "react";
import axios from "axios";

import { ReactComponent as LoginImage } from "../../assets/svg/Kfintech Login 1.svg";
import styles from "../../assets/css/Login.module.css";
// import CheckIcon, { withStyles } from "@mui/material/Icon/Check";
import { Grid } from "@mui/material";
import swal from "sweetalert2";

import Password from "./Password";
import PasswordToOtp from "./passwordToOtp";

import Loader from "../../views/Loader";
import { connect } from "react-redux";
import { handleURLAPI } from "../../API/handleAPI";
import Swal from "sweetalert2";
import { AMC_DETAILS, SAAS_AUTH, URL, USE_DEFAULT } from "../../variable";
import { authStore } from "../../utils/authStore";
import { useNavigate } from "react-router-dom";
// import Loader from "../../views/Loader";
function Login(props) {
  const [PasswordStatus, setPasswordStatus] = useState(false);
  const [congnitoUser, setCogitoUser] = useState("");
  const [clicked, setClicked] = useState(false);
  const [switchUser, setSwitchUser] = useState(
    AMC_DETAILS.USER_PORTAL == "DIS" ? "Distributor" : "Investor"
  );
  let navigate = useNavigate();
  useEffect(() => {
    // If user details are present in LS , redirect to dashboard according to usertype.
    if (
      authStore.getItem("Auth_email") &&
      authStore.getItem("fundCode") &&
      authStore.getItem("Auth") &&
      authStore.getItem("tenantCode") &&
      authStore.getItem("logSessionId") &&
      authStore.getItem("kuid") &&
      authStore.getItem("IDM_response")
    ) {
      let idmData = authStore.getItem("IDM_response");
      if (idmData?.accountType?.includes("INV")) {
        navigate("/investor/dashboard");
      } else if (idmData?.accountType?.includes("DIS")) {
        navigate("/distributor/dashboard");
      } else if (idmData?.accountType?.includes("ADM")) {
        navigate("/admin/dashboard");
      } else {
        navigate("/");
        authStore.clear();
      }
    } else {
      let websiteUrl = window.location.origin;
      // console.log("Website URL :", websiteUrl);
      if (USE_DEFAULT) {
        authStore.setItem("fundCode", URL.FUND);
        authStore.setItem("tenantCode", SAAS_AUTH.TENET_CODE);
      } else {
        handleURLAPI("/manage/getCodeByUrl", { url: websiteUrl })
          .then((response) => {
            //console.log("Handle URL API response :", response.data);
            let fundCode = response.fundCode;
            let tenantCode = response.code;
            authStore.setItem("fundCode", fundCode);
            authStore.setItem("tenantCode", tenantCode);
          })
          .catch((error) => {
            //console.log("Unable to fetch tenetcode ");
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Unable to fetch AMC details`,
              showConfirmButton: false,
              timer: 3500,
            });
          });
      }
    }
  }, []);

  return (
    <div>
      <Grid item container direction="row" className={styles.background}>
        {
          // <>
          // swal({
          //   title: `Welcome back  !`,
          //   text: "Logged In Successfully",
          //   icon: "success",
          //   buttons: false,
          //   timer: 1500,
          // });
          // </>
        }
        <Grid item container xs direction="column">
          <Grid item className={styles.container}>
            <div style={{ paddingBottom: "80px" }}>
              <LoginImage />
            </div>
          </Grid>
        </Grid>

        <Grid item xs className={styles.container}>
          <div
            style={{
              height: "70vh",
              width: "40vw",
              backgroundColor: "rgb(255 255 255 / 59%)",
              borderRadius: "40px",
              border: "1px solid black",
              display: "flex",
              //   alignItems: "center",
              minWidth: "400px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bolder",
                fontSize: "1.7rem",
                fontFamily: "Lato",
                paddingTop: "2rem",
              }}
            >
              {!PasswordStatus ? (clicked ? "LOGIN" : "LOGIN") : "OTP"}
            </div>

            <Grid
              item
              xs={12}
              className={styles.radioButtonCss}
              sx={{ fontFamily: "Lato" }}
            >
              {/* <FormControl sx={{ fontFamily: "Lato" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueSelected}
                  onChange={handleChange}
                  sx={{ fontFamily: "Lato" }}
                  row
                >
                  {/* <FormControlLabel
                    value="otp"
                    control={
                      <Radio
                        checked={valueSelected === "otp"}
                        icon={
                          <RadioButtonUncheckedIcon sx={{ fontSize: 30 }} />
                        }
                        checkedIcon={<CheckCircleIcon sx={{ fontSize: 30 }} />}
                      />
                    }
                    sx={{ fontFamily: "Lato" }}
                    label="via OTP"
                  /> */}
              {/* <FormControlLabel
                    value="password"
                    control={
                      <Radio
                        checked={valueSelected === "password"}
                        icon={
                          <RadioButtonUncheckedIcon sx={{ fontSize: 30 }} />
                        }
                        checkedIcon={<CheckCircleIcon sx={{ fontSize: 30 }} />}
                      />
                    }
                    label="via Password"
                  /> 
            </RadioGroup>
          </FormControl>*/}
            </Grid>
            <Grid item xs={12} style={{ margin: "30px 0px 30px 0px" }}>
              {!PasswordStatus ? (
                // clicked? <OtpLoader/>:

                <Password
                  switchUser={switchUser}
                  setSwitchUser={setSwitchUser}
                  setPasswordStatus={setPasswordStatus}
                  setCogitoUser={setCogitoUser}
                  setClicked={setClicked}
                />
              ) : (
                <PasswordToOtp
                  switchUser={switchUser}
                  setSwitchUser={setSwitchUser}
                  congnitoUser={congnitoUser}
                  setPasswordStatus={setPasswordStatus}
                />
              )}
            </Grid>

            {/* <div style={{ marginBottom: "10vh" }}></div> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    query: state.mainReducer.query,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setQuery: (val) => {
      dispatch({ type: "CHANGE-QUERY", query: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);
