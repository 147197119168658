import * as React from "react";

import styles from "../../../assets/css/GraphsDistributor.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";

function TopClientsTable(props) {
  let child_index = 12;
  const convertToCurrency = function standardData(initialData) {
    try {
      let finalData = parseFloat(initialData);
      finalData = finalData.toString();
      finalData = finalData.split(".")[0];
      finalData = parseFloat(finalData).toLocaleString("en-IN");

      return finalData;
    } catch (err) {
      console.log("Error in convert to currency:", err);

      throw err;
    }
  };
  //console.log("props.data:", props.data);
  let colors = ["#BBBBBB", "#3081B4", "#717171", "#333333", "#89C4F4"];

  let subtractionValue = 1;
  if (props.data.length > 4) subtractionValue = 2;
  else subtractionValue = 1;
  return (
    <div>
      <TableContainer>
        <Table size="small" aria-label="a dense table" width="450px">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}></TableCell>
              <TableCell
                style={{ fontWeight: "bold" }}
                sx={{
                  ".MuiTableCell-root": {
                    fontSize: "12px !important",
                  },
                }}
              >
                Investor Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>AUM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map(
              (row, index) =>
                index <= props.data.length - subtractionValue && (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      ".MuiTableCell-root": {
                        borderBottomColor: "white",
                        fontSize: "12px !important",
                        lineHeight: "1.5",
                      },
                    }}
                  >
                    <TableCell
                      key={child_index++}
                      style={{
                        backgroundColor: `${colors[index]}`,
                        width: "10px",
                        // padding: "5px",
                        // border: "solid 2px red",
                        // borderRadius: "100%",
                      }}
                      className={styles.color}
                    ></TableCell>
                    <TableCell className={styles.SecondColumn}>
                      {row["investorName"]}
                    </TableCell>
                    <TableCell key={index}>
                      {convertToCurrency(row["aum"])}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TopClientsTable;
