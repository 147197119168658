import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";
import TableModal from "../TableModal";

import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { GridDensityTypes } from "@mui/x-data-grid";
import { handleAPI } from "../../API/handleAPI";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import styles from "../../assets/css/Brokerage.module.css";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../../views/modalLoader";
import Swal from "sweetalert2";

import { useTheme } from "@emotion/react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function BrokerageModal(props) {
  const [selectItem, setSelectItem] = useState(props?.dropdownArray?.[0]);
  const [loaderDownload, setLoaderDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let [allSchemes, setAllSchemes] = useState(
    props?.dropdownArray ? props?.dropdownArray : []
  );

  useEffect(() => {
    console.log("on card click ", props.detailHeader);
    if (props.detailHeader === "Rate Structure") {
      setLoaderDownload(true);
      function getAllScheme() {
        handleAPI("/distributor/getBrokerageRateSchemes", {
          pan: props.userDetails.pan,
        })
          .then((response) => {
            setAllSchemes(response.data?.schemes);
            setSelectItem(response.data?.schemes?.[0]);
            setLoaderDownload(false);
          })
          .catch((error) => {
            setLoaderDownload(false);
           
          });
      }
      getAllScheme();
    }
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectItem(value);
  };
  const downloadBrokerageData = () => {
    setLoaderDownload(true);
    let payload = {
      pan: props.userDetails.pan,
    };
    let url = "";
    if (props.detailHeader === "Tax Invoice") {
      url = "/distributor/getBrokerageTaxInvoice";
      payload.month = selectItem;
    } else if (props.detailHeader === "Brokerage Statement") {
      url = "/distributor/getBrokerageStatement";
      payload.month = selectItem;
    } else {
      url = "/distributor/getBrokerageRateStructure";
      payload.schemeName = selectItem;
    }
    console.log("payload for brokerage ", payload);
    handleAPI(url, payload)
      .then((response) => {
        setLoaderDownload(false);
        console.log("Is record avaiable -", response);
        if (response?.data?.statusCode == 200) {
          setErrorMessage("");
          let downloadLink = response.data?.result?.[0]?.FilePath;
          window.open(downloadLink, "_self");
          Swal.fire({
            position: "center",
            icon: "success",
            title: `Downloaded successfully`,
            showConfirmButton: false,
            timer: 2500,
          });
          props.handleClose();
        } else if (response?.data?.statusCode == 44) {
          setErrorMessage("No records present");
        } else if (
          response?.data?.statusCode == 99 ||
          response?.data?.statusCode == 404
        ) {
          setErrorMessage(response?.message);
        } else {
          setErrorMessage("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Error in brokerage ", error);
        setLoaderDownload(false);
        setErrorMessage("Something went wrong");
       
      });
  };

  return (
    <>
      <Grid item>
        {!loaderDownload ? (
          //  && loaderUaData && loaderUnread
          <Grid container style={{ padding: "5px" }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "700",
              }}
            >
              <Grid item xs={5}>
                {props.detailHeader}
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "2rem",
                  height: "35px",
                }}
              >
                <div
                  style={{
                    borderRadius: "30px",
                  }}
                ></div>
              </Grid>

              <Grid
                item
                xs={1}
                onClick={props.handleClose}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <CloseSVG />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
              <div
                style={{
                  height: "320px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <div className={styles.noInvestmentSecondLineCss}>
                  <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      {props.dropdownTitle}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectItem}
                      onChange={handleChange}
                      sx={{ fontFamily: "lato", fontSize: "18px" }}
                      MenuProps={MenuProps}
                    >
                      {allSchemes.length > 0 ? (
                        allSchemes.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            //   style={getStyles(name, personName, theme)}
                            style={{ backgroundColor: "#FFFFFF" }}
                          >
                            {name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>No Schemes Avaialble</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    marginTop: "1.4rem",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {errorMessage?.length > 0 && (
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  )}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{
                        backgroundColor: "#333333",
                        // marginLeft: "6rem",
                        marginTop: "1rem",
                      }}
                      onClick={downloadBrokerageData}
                      className={"btncss"}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            style={{
              height: "371px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <LoadingBlack /> */}
            <ModalLoader />
          </Grid>
        )}
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    unread: state.mainReducer.unread,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(BrokerageModal);
