import React, { useState, useEffect } from "react";

import "../assets/css/modalLoader.css";

import PropagateLoader from "react-spinners/PropagateLoader";

export default function ModalLoader(props) {
  //   const [state, dispatch] = useStateValue();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    updatedDimensions();
    window.addEventListener("resize", updatedDimensions);
    // //console.log(isMobile);
    return () => window.removeEventListener("resize", updatedDimensions);
  }, []);

  const updatedDimensions = () =>
    window.innerWidth > 768 ? setIsMobile(false) : setIsMobile(true);

  //   if (!props.pageLoading) return null;
  return (
    <div className="modalloader__div">
      {isMobile ? (
        <div style={props.style} className="wrapper__mobile">
          <div className="otploading-spinner__mobile">
            {/* <div className="loading-spinner__ring__mobile"> */}
            {/* <div className="loading-spinner__inner"></div> */}
            <PropagateLoader cssOverride={override} color="#000000" />
            {/* </div> */}

            {/* <p className="loading">Loading</p> */}
          </div>
        </div>
      ) : (
        <div className="modalwrapper">
          <div className="modalloading-spinner">
            <span style={{ fontWeight: "bold", fontSize: "19px" }}>
              Loading{" "}
            </span>

            <PropagateLoader cssOverride={override} color="#484848" />
            {/* </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
const override = {
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",

  // radius:"80",
  // position: "absolute",
  // left: "47%",
  // top:"15%"
};
