import React from "react";
import Grid from "@mui/material/Grid";
import DoughnutChartDistributor from "./DoughnutChartDistributor";
import InvestmentsTable from "./InvestmentsTable";
import styles from "../../../assets/css/GraphsDistributor.module.css";

function Cat2Investments(props) {
  return (
    <Grid
      container
      style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
    >
      <Grid
        xs={12}
        item
        style={{
          height: "11rem",
          width: "auto",
          display: "flex",
          justifyContent: "center",
        }}
        className={styles.pieGrid__col1}
      >
        <DoughnutChartDistributor
          cat2Data={props.cat2Data}
          total={props.total}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingTop: "1.5rem",
          marginLeft: "0.5rem",
          height: "126px",
        }}

        // style={{
        //   flexDirection: "column",
        //   justifyContent: "space-between",
        //   display: "flex",
        // }}
      >
        {/* <Grid item xs={12}> */}
        <InvestmentsTable data={props.cat2Data} />
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
}

export default Cat2Investments;
