import SecureLS from "secure-ls";
import { SAAS_AUTH, SECURE_LS_ENCRYPT } from "../variable";

let encryption = SECURE_LS_ENCRYPT;

let storage = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: SAAS_AUTH.SECURELS_ENCRYPTION_KEY,
});

const getItem = (key) => {
  try {
    return encryption
      ? JSON.parse(storage.get(key))
      : JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.log("Unable to decrypt localstorage value");
    return null;
  }
};

const setItem = (key, data) => {
  encryption
    ? storage.set(key, JSON.stringify(data))
    : localStorage.setItem(key, JSON.stringify(data));
};

const clear = () => {
  encryption ? storage.clear() : localStorage.clear();
};

export let authStore = {
  getItem,
  setItem,
  clear,
};
