// Modal Popup of the Dashboard Bubble Boxes

import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { handleAPI } from "../../API/handleAPI";
// import TableModal from "../TableModal";
import "../../assets/css/DashboardTable.css";
import { connect } from "react-redux";
import { ReactComponent as OpenTable } from "../../assets/svg/OpenTable copy.svg";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";
import { TextField } from "@mui/material";
import Cat23TableModal from "./Cat23TableModal";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../../views/modalLoader";
import Swal from "sweetalert2";
import { AMC_DETAILS } from "../../variable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "510px",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid black",
  boxShadow: 24,
  borderRadius: 3,
  p: 1,
  padding: "2px 5px",
};

function Cat23Table(props) {
  const [searchString, setSearchString] = useState("");
  const [rows, setRows] = useState([]); // rows data sent to Cat23TableModal for the table i.e data that will be shown in the table
  const [tableDataCat2, setTableDataCat2] = useState([]); // rows data for cat 2
  const [tableDataCat3, setTableDataCat3] = useState([]); // rows data for cat 3
  const [open, setOpen] = useState(false); // to open the modal
  const [loader, setLoader] = useState(false); // loader to wait for api call response
  const [downloadLink, setDownloadLink] = useState({}); // set download link for xlsx and pdf
  const [schemeNumber, setSchemeNumber] = useState(0); // Scheme selected in the dropdown
  const [category2Schemes, setCategory2Schemes] = useState([]); // schemes present in cat 2
  const [category3Schemes, setCategory3Schemes] = useState([]); // schemes present in cat 3
  const [categoryNumber, setCategoryNumber] = useState(0); // category selected in the dropdown
  // const [catFlag, setCatFlag] = useState(); // value of flag , i.e from the switch -> coming from props.flag ... false for cat2 ... true for cat 3
  const [tableDataSchemeWise, setTableDataSchemeWise] = useState([]);
  const [tableDataCategoryWise, setTableDataCategoryWise] = useState([]);
  const [currentSchemes, setCurrentSchemes] = useState([]);
  const [category2DownloadLink, setCategory2DownloadLink] = useState({});
  const [category3DownloadLink, setCategory3DownloadLink] = useState({});
  const navigate = useNavigate();

  //API Call
  const handleOpen = async () => {
    setOpen(true);
    //console.log("Props.name ----->", props.name);
    await handleAPI(
      "/investor/getDashboardCategoryBoxDetails",
      {
        pan: props.userDetails.pan,
        fileName: "InvestmentCategory",
        navDate: props.navDate,
      },
      props.token
    )
      .then((response) => {
        //console.log("Data--", response.data);
        setTableDataCat2(response.data.investmentCategory2Data);
        setTableDataCat3(response.data.investmentCategory3Data);
        setCategory2Schemes(response.data.categoryWiseScheme.category2);
        setCategory3Schemes(response.data.categoryWiseScheme.category3);
        setCategory2DownloadLink({
          xlsxLink: response.data.s3XlsxSignedUrlCategory2,
          pdfLink: response.data.s3PdfSignedUrlCategory2,
        });
        setCategory3DownloadLink({
          xlsxLink: response.data.s3XlsxSignedUrlCategory3,
          pdfLink: response.data.s3PdfSignedUrlCategory3,
        });

        // false for cat 2 ... true for cat 3
        if (props.flag === false) {
          setTableDataCategoryWise(response.data.investmentCategory2Data);
          setRows(response.data.investmentCategory2Data);
          setDownloadLink({
            xlsxLink: response.data.s3XlsxSignedUrlCategory2,
            pdfLink: response.data.s3PdfSignedUrlCategory2,
          });
          setCategoryNumber(2);
          setSchemeNumber(0);
          setCurrentSchemes(response.data.categoryWiseScheme.category2);
          setTableDataSchemeWise(response.data.investmentCategory2Data);
        } else {
          setTableDataCategoryWise(response.data.investmentCategory3Data);
          setRows(response.data.investmentCategory3Data);
          setDownloadLink({
            xlsxLink: response.data.s3XlsxSignedUrlCategory3,
            pdfLink: response.data.s3PdfSignedUrlCategory3,
          });
          setCategoryNumber(3);
          setSchemeNumber(0);
          setCurrentSchemes(response.data.categoryWiseScheme.category3);
          setTableDataSchemeWise(response.data.investmentCategory3Data);
        }
        setLoader(true);
      })
      .catch((error) => {
        console.log(`Error Message:  ${error.errorMessage}`);
        console.log("Redirecting to error");

        setLoader(true);
      });
  };

  const handleClose = () => {
    setSearchString("");
    setOpen(false);
    setLoader(false);
  };

  // Search Functionality
  const handleChange = (event) => {
    setSearchString(event.target.value);
    //console.log("value is:", event.target.value);
    let filteredRows = tableDataSchemeWise.filter((row) => {
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setRows(filteredRows);
  };

  function downloadXlsxData() {
    //console.log("Inside downloadXLSX Link --->", downloadLink.xlsxLink);
    window.open(downloadLink.xlsxLink, "_self");
  }

  function downloadPdfData() {
    //console.log("Inside downloadPDF Link --->", downloadLink.pdfLink);
    window.open(downloadLink.pdfLink, "_self");
  }

  // what scheme dropdown will show
  function showSchemes() {
    return currentSchemes.map((key, index) => {
      return (
        <MenuItem key={index} value={index + 1}>
          {key}
        </MenuItem>
      );
    });
  }

  // when the scheme drop down changes -> scheme number changes and use effect (scheme number dependency) is called
  const handleSchemeNumberChange = (event) => {
    setSchemeNumber(event.target.value);
    //console.log("SCheme number", schemeNumber);
    setSearchString("");
  };

  // when the category drop down changes
  const handleCategoryNumberChangeAndRow = (event) => {
    // the category number is getting changed ., i.e , in the dropdown
    setCategoryNumber(event.target.value);
    setSearchString("");

    // if chnged to cat2
    if (event.target.value === 2) {
      setTableDataCategoryWise(tableDataCat2);
      setCurrentSchemes(category2Schemes);
      setDownloadLink(category2DownloadLink);
      // setCatFlag(false);
    }
    // if changed to cat3
    else if (event.target.value === 3) {
      setTableDataCategoryWise(tableDataCat3);
      setCurrentSchemes(category3Schemes);
      setDownloadLink(category3DownloadLink);

      // setCatFlag(true);
    }
    setSchemeNumber(0);
  };

  // when scheme number changes , tableDataSchemeWise is set as per that scheme
  useEffect(() => {
    if (schemeNumber === 0) setTableDataSchemeWise(tableDataCategoryWise);
    else {
      let schemeNameString = currentSchemes[schemeNumber - 1]; // 0 ADP,1 ADQ ,2 ADR

      setTableDataSchemeWise(
        tableDataCategoryWise.filter((row) => {
          let temp = JSON.stringify(row["Scheme Name"]);
          temp = temp.toLowerCase();
          // //console.log("row =", JSON.stringify(Object.values(row)).includes("Class A"));
          return temp.includes(schemeNameString.toLowerCase());
        })
      );
    }
  }, [schemeNumber]);

  // what rows to be displayed ( i.e rows data that is sent to DataGrid in Cat23tableModal) is dependent on tableDataSchemeWise
  // rows changes and as it is a useState , the component is re-rendered and the entire table to be shown is also re-rendered with the new value for the rows
  useEffect(() => {
    setRows(tableDataSchemeWise);
  }, [tableDataSchemeWise]);

  // when tableDataCategoryWise changes , the rows data is the entire Category Data ... schemes also full Cat Data as all schemes by default
  useEffect(() => {
    setTableDataSchemeWise(tableDataCategoryWise);
    setRows(tableDataCategoryWise);
  }, [tableDataCategoryWise]);

  //console.log("Before Modal Open \nProps.flag= ", props.flag);

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <>
        <Grid item>
          <Button onClick={handleOpen}>
            <OpenTable />
          </Button>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Box className="tableBox" sx={style}>
            {loader ? (
              <>
                <Grid item className="header">
                  <Grid item xs={6} className="title">
                    {props.detailHeader}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    onClick={handleClose}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <CloseSVG />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    // border: "solid 2px blue",
                    display: "flex",
                    flexDirection: "row",
                    height: "5rem",
                  }}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3} style={{ fontFamily: "Lato" }}>
                    {AMC_DETAILS.FUND_CATEGORY?.length > 1 && (
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "90%",
                          marginRight: "3rem",
                          paddingBottom: "2rem",
                        }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={categoryNumber}
                          onChange={handleCategoryNumberChangeAndRow}
                          label="Age"
                        >
                          <MenuItem value={2}>Category II</MenuItem>
                          <MenuItem value={3}>Category III</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      variant="standard"
                      sx={{
                        m: 1,
                        width: "90%",
                        marginRight: "3rem",
                        paddingBottom: "2rem",
                      }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Scheme Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={schemeNumber}
                        onChange={handleSchemeNumberChange}
                        label="Age"
                      >
                        <MenuItem value={0}>All Schemes</MenuItem>
                        {showSchemes()}
                        {/* ABC props.category.map(key) */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginRight: "2rem",
                      marginTop: "1rem",
                      marginBottom: "-0.5rem",
                      height: "35px",
                    }}
                  >
                    <div
                      style={{
                        border: "solid 1px black",
                        borderRadius: "30px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Search"
                        type="search"
                        onChange={handleChange}
                        value={searchString}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          ".MuiInputBase-input": {
                            borderBottom: "none !important",
                            padding: "5px 0 0 10px !important",
                            /*margin: -12px ;  */
                          },
                        }}
                      />
                    </div>
                    {/* </div> */}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Cat23TableModal
                    name={props.name}
                    headCells={props.headCells}
                    data={rows}
                  />
                </Grid>

                <div className="downloadBtn">
                  <button
                    style={{ backgroundColor: "#333333" }}
                    onClick={downloadXlsxData}
                    className={
                      downloadLink?.xlsxLink != null
                        ? "btncss"
                        : "btncss-disable"
                    }
                    disabled={downloadLink.xlsxLink === null ? true : false}
                  >
                    Download as Excel
                  </button>
                  <button
                    style={{ backgroundColor: "#333333" }}
                    onClick={downloadPdfData}
                    className={
                      downloadLink?.xlsxLink != null
                        ? "btncss"
                        : "btncss-disable"
                    }
                    disabled={downloadLink.pdfLink === null ? true : false}
                  >
                    Download as PDF
                  </button>
                </div>
              </>
            ) : (
              <Grid
                item
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <LoadingBlack /> */}
                <ModalLoader />
              </Grid>
            )}
          </Box>
        </Modal>
      </>
    </Grid>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    navDate: state.mainReducer.navDate,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Cat23Table);
