import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import ClientReportsDistributor from "./ClientReportsDistributor";
import { NavLink, useNavigate } from "react-router-dom";
import { handleAPI } from "../../API/handleAPI";
import { connect } from "react-redux";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import ReportsDownloadTable from "./ReportsDownloadTable";
import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";
import { COLOR_CODE } from "../../variable";

function ClientReportsInvestor(props) {
  const [fullClientReportsData, setFullClientReportsData] = useState({});
  const [loader, setLoader] = useState(false);
  const [financialYearList, setFinancialYearList] = useState({});
  const [financialYearValue, setFinancialYearValue] = useState();
  const [schemeFolioList, setSchemeFolioList] = useState({});
  const [schemeFolioValue, setSchemeFolioValue] = useState({});
  const [tableData, setTableData] = useState({});
  const [rows, setRows] = useState();
  const [clientReportsAvailable, setClientReportsAvailable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Inside client reports of investor", props.userDetails);
    props.setPage("CLINV");
    if (props.userDetails.pan) {
      handleAPI(
        "/investor/getClientReportListDetails",
        {
          pan: props.userDetails.pan,
          // pan: "AAACK2331D",
        },
        props.token
      )
        .then((response) => {
          if (Object.keys(response.data).length) {
            let tempFullData = response.data;
            let tempYear = Object.keys(tempFullData)[0];
            let tempScheme = Object.keys(tempFullData[tempYear])[0];
            setFullClientReportsData(response.data);
            setFinancialYearList(Object.keys(response.data));
            setFinancialYearValue(tempYear);
            setSchemeFolioList(Object.keys(tempFullData[tempYear]));
            setSchemeFolioValue(tempScheme);
            setTableData(tempFullData[tempYear][tempScheme]);
            setRows(tempFullData[tempYear][tempScheme]);
            setClientReportsAvailable(true);
          }
          setLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoader(true);
        });
    }
  }, [props.userDetails]);

  useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  // When the FY dropdown changes
  const handleFinancialYearChange = (event) => {
    setFinancialYearValue(event.target.value);
  };

  // When the FY dropdown changes ... FY Value selected
  useEffect(() => {
    if (clientReportsAvailable === true) {
      setSchemeFolioList(
        Object.keys(fullClientReportsData[financialYearValue])
      );
      setSchemeFolioValue(
        Object.keys(fullClientReportsData[financialYearValue])[0]
      );
      let temp1 = fullClientReportsData[financialYearValue];
      let tempObj = Object.keys(fullClientReportsData[financialYearValue]);
      // //console.log("first", temp1[tempObj[0]]);

      setTableData(temp1[tempObj[0]]);
    }
  }, [financialYearValue]);

  //When scheme folio dropdown changes
  const handleSchemeFolioChange = (event) => {
    setSchemeFolioValue(event.target.value);

    setTableData(fullClientReportsData[financialYearValue][event.target.value]);
  };

  const ReportsDownloadHeadCells = [
    {
      field: "reportName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Report",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 528,
    },
    {
      field: "frequency",
      numeric: true,
      disablePadding: false,
      headerName: "Frequency",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "lastUpdated",
      numeric: true,
      disablePadding: false,
      headerName: "Last Updated",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 140,
    },
    {
      field: "downloadLink",
      numeric: true,
      disablePadding: false,
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          window.open(params.formattedValue, "_self");
        };

        return (
          <Button onClick={onClick}>
            <DownloadReport />
          </Button>
        );
      },
    },
  ];
  return (
    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        {loader ? (
          clientReportsAvailable ? (
            <Grid item xs={12}>
              <Card
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "930px",
                }}
              >
                {/* Dropdowns */}
                <CardContent>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {" "}
                    <Grid item xs={3} style={{ fontFamily: "Lato" }}>
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "90%",
                          marginRight: "3rem",
                          fontFamily: "Lato",
                        }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Financial Year
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={financialYearValue}
                          onChange={handleFinancialYearChange}
                          label="Age"
                          style={{
                            fontFamily: "Lato",
                            fontSize: "14px",
                          }}
                        >
                          {financialYearList
                            .sort()
                            .reverse()
                            .map((yearName, id) => {
                              return (
                                <MenuItem key={id} value={yearName}>
                                  {yearName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "90%",
                          marginRight: "3rem",
                        }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Scheme Name - Folio Number
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={schemeFolioValue}
                          onChange={handleSchemeFolioChange}
                          label="Age"
                          style={{
                            fontFamily: "Lato",
                            fontSize: "14px",
                          }}
                          MenuProps={{
                            MenuListProps: {
                              style: {
                                maxHeight: "45vh",
                                overflowY: "auto",
                              },
                            },
                          }}
                        >
                          {schemeFolioList.map((schemeName, id) => {
                            return (
                              <MenuItem key={id} value={schemeName}>
                                {schemeName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>

                {/* Table */}
                <CardContent>
                  <Box
                    sx={{
                      height: 337,
                      width: "100%",
                      "& .tableModal--Header": {
                        backgroundColor: COLOR_CODE.TABLE_HEADER,
                        color: "white",
                        fontFamily: "Lato",
                      },
                    }}
                  >
                    {/* <DataGrid
                      style={{ fontFamily: "Lato" }}
                      rows={[]}
                      columns={ReportsDownloadHeadCells}
                      pageSize={4}
                      rowsPerPageOptions={[4]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    /> */}
                    <ReportsDownloadTable
                      headCells={ReportsDownloadHeadCells}
                      data={rows}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              {" "}
              Sorry no reports are available for you yet!{" "}
            </Grid>
          )
        ) : (
          <>
            {/* <Grid
                item
                xs={12}
                style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                }}
              >
                Fetching Reports !
              </Grid> */}
            <Loader />
          </>
        )}
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(ClientReportsInvestor);
