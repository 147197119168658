import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import { handleAPI } from "../../API/handleAPI";
import styles from "../../assets/css/Profile.module.css";
import { connect } from "react-redux";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import capitalizeCase from "../../utils/capitalizeCase";
import Swal from "sweetalert2";


function DistributorProfile(props) {
  let [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    props.setPage("DistributorProfile");
  }, []);

  let [userProfile, setUserProfile] = useState({
    ["A/C Holder Name"]: "",
    CONTACT: "",
    EMAIL: "",
    Address: "",
    PAN_NO: "",
    nomination: "",
    ARN: "",
    VALID_TILL: "",
    ACNO: "",
  });
  //console.log("Pan Details in distributor----", props.userDetails.pan);

  useEffect(() => {
    //console.log("Pan Details in distributor----", props.userDetails.pan);
    if (props.userDetails.pan !== undefined) {
      handleAPI(
        "/distributor/getProfile",
        {
          pan: props.userDetails.pan,
          userType: "distributor",
        },
        props.token
      )
        .then((response) => {
          //console.log("Details received", response.data);
          let userDetails = {
            "A/C Holder Name": response.data.userDetails["A/C Holder Name"]
              ? response.data.userDetails["A/C Holder Name"]
              : props.userDetails.fullName,

            CONTACT: response.data.userDetails.CONTACT
              ? response.data.userDetails.CONTACT
              : props.userDetails?.phNumber,

            EMAIL: response.data.userDetails.EMAIL
              ? response.data.userDetails.EMAIL
              : props.userDetails?.email,

            Address: response.data.userDetails.Address,
            PAN_NO: response.data.userDetails.PAN_NO
              ? response.data.userDetails.PAN_NO
              : props.userDetails?.pan,

            nomination: response.data.userDetails.NOMINEE,
            ARN: response.data.userDetails.ARN,
            VALID_TILL: response.data.userDetails.VALID_TILL,
            FOLIO: response.data.userDetails.FOLIO,
            ACNO: response.data.userDetails.ACNO,
          };
          setUserProfile(userDetails);
          setLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
         
          setLoader(true);
        });
    }
  }, [props.userDetails.pan]);

  return (
    <div>
      {loader ? (
        <>
          <Grid item xs={12} className={styles.profileFont}>
            Profile
          </Grid>
          <Grid container className={styles.profileBox}>
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label="A/C Holder Name"
                  value={
                    userProfile["A/C Holder Name"] === null
                      ? "NA"
                      : userProfile["A/C Holder Name"]
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label="ARN / Agent Code"
                  value={userProfile.ARN === null ? "NA" : userProfile.ARN}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label="Valid Till"
                  value={
                    userProfile.VALID_TILL === null
                      ? "NA"
                      : userProfile.VALID_TILL
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label="Phone Number"
                  value={
                    userProfile.CONTACT === null ? "NA" : userProfile.CONTACT
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label="Email ID"
                  value={userProfile.EMAIL === null ? "NA" : userProfile.EMAIL}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                borderBottom: "solid 1px lightgray",
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={5}
                  label="Address"
                  value={
                    userProfile.Address === null ? "NA" : userProfile.Address
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                width: "90%",
                padding: "5px 5px 5px 5px",
              }}
            >
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label="PAN"
                  value={
                    userProfile.PAN_NO === null ? "NA" : userProfile.PAN_NO
                  }
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} className={styles.profileTextField}>
                <TextField
                  style={{ width: "75%" }}
                  id="standard-read-only-input"
                  multiline
                  maxRows={1}
                  label=" Bank A/C Number"
                  value={userProfile.ACNO === null ? "NA" : userProfile.ACNO}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      font: "unset",
                      fontFamily: "Lato",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    style: { fontWeight: "800", color: "#333333" },
                  }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};
const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    page: state.mainReducer.page,
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(DistributorProfile);
