import Swal from "sweetalert2";
import { authStore } from "./authStore";
// import maintenanceLogo from "../assets/svg/maintenanceLogo.svg";
import maintenanceLogo from "../assets/svg/maintenanceLogo.svg";

export function showMaintenance(maintenanceInfo) {
  let confirmBtnText = "Go Back";
  let maintenanceAlertMessage = maintenanceInfo?.maintenanceHour
    ? `Data Refresh until ${maintenanceInfo?.maintenanceTo}`
    : "Maintenance in Progress";
  global.showMaintenance = false;
  let logoStyle = {
    Title: `color:#092056;
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      margin-top: 1rem;
      `,
    Desc: `font-family: Lato;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: center;
      margin-top: 0.5rem;
      `,
  };
  Swal.fire({
    position: "center",
    icon: "",
    html: `<div>
              <img src = ${maintenanceLogo} alt="iconLogo" />
              <div style="${logoStyle.Title}">Services Briefly Unavailable</div>
              <div>${maintenanceAlertMessage}</div>
            </div>`,
    // iconHtml: `<img style={{width:"100px"; height:"400px"}} src = ${maintenanceLogo} alt="iconLogo" />`,
    showConfirmButton: true,
    // heightAuto: false,
    confirmButtonText: confirmBtnText,
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonColor: "#337fc9",
  }).then((result) => {
    // If user is unauthorized , redirect to login
    console.log("Showing Maintainance page");
    global.showMaintenance = true;
    // navigate("/");
    window.location.href='/';
    authStore.clear();
  });
}