import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend } from "chart.js/auto";

ChartJS.register(Tooltip, Legend);

function DoughnutInvestor(props) {
  let colors = ["#89C4F4", "#333333", "#808080", "#CCCCCC", "#BBBBBB"];
  let labels = [];
  let data = [];
  console.log(props);
  props.type === "current"
    ? Object.keys(props.doughnut).map((key) => {
        labels.push(props.doughnut[key].schemeName);
        data.push(props.doughnut[key].currentValue);
      })
    : Object.keys(props.doughnut).map((key) => {
        labels.push(props.doughnut[key].schemeName);
        data.push(props.doughnut[key].investedValue);
      });

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
  const totalSum = parseInt(props.total.replace(/,/g, ""));
  const options = {
    plugins: {
      title: {
        display: true,
        text: props.type === "current" ? "Current Value" : "Invested Value",
        font: {
          size: 17,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = props.doughnut[context.dataIndex].schemeDesc || "";
            const value = parseInt(context.formattedValue.replace(/,/g, ""));
            return `${label}: ${((value / totalSum) * 100).toFixed(2)}%`;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          generateLabels: (chart) => {
            const data = chart.data;
            const totalValue = data.datasets[0].data.reduce(
              (acc, curr) => acc + Number(curr),
              0
            );
            return data.labels.map((label, i) => {
              const percentage =
                totalValue > 0
                  ? ((data.datasets[0].data[i] / totalValue) * 100).toFixed(2)
                  : "0.00";
              return {
                text: `${label} - ${percentage}%`,
                fillStyle: chart.getDatasetMeta(0).controller.getStyle(i)
                  .backgroundColor,
                strokeStyle: chart.getDatasetMeta(0).controller.getStyle(i)
                  .borderColor,
                lineWidth: chart.getDatasetMeta(0).controller.getStyle(i)
                  .borderWidth,
                hidden:
                  isNaN(data.datasets[0].data[i]) ||
                  chart.getDatasetMeta(0).data[i].hidden,
                index: i,
              };
            });
          },
        },
      },
    },
    cutout: "65%",
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };

  const doughnutCenterTextPlugin = {
    id: "doughnutCenterText",
    beforeDraw: function (chart) {
      // console.log("chart ---- ", chart);
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const text = `₹${props.total}`;
      // const subtext = "";

      ctx.restore();
      const fontSize = "80%";
      ctx.font = `normal ${fontSize} Arial`;
      ctx.fillStyle = "black";
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 + 5;

      ctx.fillText(text, textX, textY);

      ctx.save();
    },
  };

  return (
    <Doughnut
      data={chartData}
      options={options}
      plugins={[doughnutCenterTextPlugin]}
    />
  );
}

export default DoughnutInvestor;
