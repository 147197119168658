import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
import { style } from "@mui/system";

ChartJS.register(ArcElement, Tooltip, Legend);

// Custom plugin to render text in the center
function DoughnutChartDistributor(props) {
  let colors = [
    "#89C4F4",
    "#BBBBBB",
    "#337FC9",
    "#9AECF3",
    "#717171",
    "#36DAE9",
    "#333333",
    "#4492A8",
    "#66BCFF",
    "#7094C6",
    "#8ED8B7",
  ];
  let labels = [];
  let data = [];

  if (props.hasOwnProperty("cat2Data")) {
    props.cat2Data &&
      Object.keys(props.cat2Data).map((key) => {
        labels.push(props.cat2Data[key].schemeName);
        data.push(props.cat2Data[key].aum / 10000000);
      });
  } else if (props.hasOwnProperty("cat3Data")) {
    props.cat3Data &&
      Object.keys(props.cat3Data).map((key) => {
        labels.push(props.cat3Data[key].schemeName);
        data.push(props.cat3Data[key].aum / 10000000);
      });
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed !== null) {
              label += `${new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                maximumFractionDigits: 1,
              }).format(context.parsed)}M`;
            }
            return label;
          },
        },
      },
    },
    cutout: "65%",
    radius: "100%",
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };

  const doughnutCenterTextPlugin = {
    id: "doughnutCenterText",
    beforeDraw: function (chart) {
      // console.log("chart ---- ", chart);
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const text = `₹${props.total}`;
      // const subtext = "";

      ctx.restore();
      const fontSize = "75%";
      ctx.font = `normal ${fontSize} Arial`;
      ctx.fillStyle = "black";
      ctx.paddingTop = "10";
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 + 6;

      ctx.fillText(text, textX, textY);

      // ctx.font = `${fontSize * 0.3}em Arial`;
      // const subtextX = Math.round((width - ctx.measureText(subtext).width) / 2);
      // ctx.fillText(subtext, subtextX, textY + 10);
      ctx.save();
    },
  };

  return (
    <Doughnut
      data={chartData}
      options={options}
      plugins={[doughnutCenterTextPlugin]}
    />
  );
}

export default DoughnutChartDistributor;
