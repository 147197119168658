import React, { useState, useEffect } from "react";

import "../assets/css/otpLoader.css";

import PropagateLoader from "react-spinners/PropagateLoader";
import styled from "@emotion/styled";
import { Modal } from "@mui/material";
const CustomModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgb(
      0 0 0 / 20%
    ); /* Customize the background color and opacity here */
    box-shadow: none; /* Remove the default box-shadow */
    border: none; /* Remove the border */
    outline: none;
  }
`;

export default function OtpLoader(props) {
  //   const [state, dispatch] = useStateValue();
  const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   updatedDimensions();
  //   window.addEventListener("resize", updatedDimensions);
  //   // //console.log(isMobile);
  //   return () => window.removeEventListener("resize", updatedDimensions);
  // }, []);

  const updatedDimensions = () =>
    window.innerWidth > 768 ? setIsMobile(false) : setIsMobile(true);

  //   if (!props.pageLoading) return null;
  return (
    <CustomModal
      open
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        border: "none",
        outline: "none",
        boxShadow: "none",
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="otploader__div">
        {isMobile ? (
          <div style={props.style} className="wrapper__mobile">
            <div className="otploading-spinner__mobile">
              <PropagateLoader cssOverride={override} color="#000000" />
            </div>
          </div>
        ) : (
          <div className="otpwrapper">
            <div className="otploading-spinner">
              <span style={{ fontWeight: "bold", fontSize: "19px" }}>
                Loading{" "}
              </span>

              <PropagateLoader cssOverride={override} color="#484848" />
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
}
const override = {
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",

  // radius:"80",
  // position: "absolute",
  // left: "47%",
  // top:"15%"
};
