function camelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const motheDifference = (previousDate, currentDate) => {
  //
  var months;
  months = (currentDate.getFullYear() - previousDate.getFullYear()) * 12;
  months -= previousDate.getMonth();
  months += currentDate.getMonth();
  return months <= 0 ? 0 : months;
};

export default camelCase;
