// Modal Popup of the Dashboard Bubble Boxes

import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { handleAPI } from "../../../API/handleAPI";
// import TableModal from "./TableModal";
import "../../../assets/css/DashboardTable.css";
import { connect } from "react-redux";
import { ReactComponent as OpenTable } from "../../../assets/svg/OpenTable copy.svg";
import { ReactComponent as CloseSVG } from "../../../assets/svg/Close.svg";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import TopClientsExtensionTable from "./TopClientsExtensionTable";
import { ReactComponent as LoadingBlack } from "../../../assets/svg/LoadingBlack.svg";
import ModalLoader from "../../../views/modalLoader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { popupError } from "../../../utils/errorHandler";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "480px",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid black",
  boxShadow: 24,
  borderRadius: 3,
  p: 1,
  padding: "2px 5px",
};

function TopClientsExtension(props) {
  const [searchString, setSearchString] = useState("");
  const [rows, setRows] = useState(props.data);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState({});
  const [aifLoading, setAifLoading] = useState(false);
  const navigate = useNavigate();

  //API Call
  const handleOpen = async () => {
    setOpen(true);
    //console.log("Props.name ----->", props.name);
    //console.log("Props.pan ----->", props.userDetails.pan);

    await handleAPI(
      "/distributor/getDashboardTopClientDetails",
      {
        pan: props.userDetails.pan,
        navDate: props.navDate,
      },
      props.token
    )
      .then((response) => {
        //console.log("Data123456--", response.data);
        setTableData(response.data.data);
        setRows(response.data.data);
        setDownloadLink({
          xlsxLink: response.data.s3XlsxSignedUrlTopClientDetails,
          pdfLink: response.data.s3PdfSignedUrlTopClientDetails,
        });

        setLoader(true);
      })
      .catch((error) => {
        console.log(`Error Message:  ${error.errorMessage}`);
        console.log("Redirecting to error");

        setLoader(true);
       
      });
  };

  const handleClose = () => {
    setSearchString("");
    setOpen(false);
    setLoader(false);
  };

  const handleChange = (event) => {
    setSearchString(event.target.value);

    let filteredRows = tableData.filter((row) => {
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setRows(filteredRows);
  };

  function downloadXlsxData() {
    //console.log("Inside downloadXLSX Link --->", downloadLink.xlsxLink);
    window.open(downloadLink.xlsxLink, "_self");
  }

  function downloadPdfData() {
    //console.log("Inside downloadPDF Link --->", downloadLink.pdfLink);
    window.open(downloadLink.pdfLink, "_self");
  }

  // let returnValue = <Grid></Grid>;
  useEffect(() => {
    if (open) {
      if (loader) setAifLoading(false);
      else setAifLoading(true);
    }
  }, [open, loader]);

  return (
    <div>
      <Grid container>
        <Button onClick={handleOpen}>
          <OpenTable />
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Box sx={style}>
            {loader ? (
              <>
                <Grid item className="header">
                  <Grid item xs={6} className="title">
                    {props.detailHeader}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    onClick={handleClose}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <CloseSVG />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "2rem",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px black",
                      borderRadius: "30px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Search"
                      type="search"
                      onChange={handleChange}
                      value={searchString}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        ".MuiInputBase-input": {
                          borderBottom: "none !important",
                          padding: "5px 0 0 10px !important",
                          /*margin: -12px ;  */
                        },
                        paddingBottom: "5rem",
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "0.3rem" }}>
                  <TopClientsExtensionTable
                    name={props.name}
                    headCells={props.headCells}
                    data={rows}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className="downloadBtn">
                    <button
                      style={{ backgroundColor: "#333333" }}
                      onClick={downloadXlsxData}
                      className={
                        downloadLink?.xlsxLink != null
                          ? "btncss"
                          : "btncss-disable"
                      }
                    >
                      Download as Excel
                    </button>
                    <button
                      style={{ backgroundColor: "#333333" }}
                      onClick={downloadPdfData}
                      className={
                        downloadLink?.xlsxLink != null
                          ? "btncss"
                          : "btncss-disable"
                      }
                    >
                      Download as PDF
                    </button>
                  </div>
                </Grid>
              </>
            ) : (
              <Grid
                item
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <LoadingBlack /> */}
                <ModalLoader />
              </Grid>
            )}
          </Box>
        </Modal>
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    navDate: state.mainReducer.navDate,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(TopClientsExtension);
