import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import UACard from "./UACard";
import styles from "../../assets/css/UpdatesAndAnnouncements.module.css";
import { useEffect } from "react";
import { handleAPI } from "../../API/handleAPI";
import { useState } from "react";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";

function UpdatesAndAnnouncementsInvestor(props) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.userDetails.pan) {
      handleAPI(
        "/investor/getUpdateAnnouncementReports",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("getUpdateAnnouncementReports data ----", response.data);
          props.setUaData(response.data.reports);
          props.setUnread(response.data.totalUnseenStatus);
          setLoader(true);
          // props.setUnread(response.data.unseenFiles);
          // setLoaderUnread(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoader(true);
        });
    }
  }, [props.userDetails.pan]);

  useEffect(() => {
    //console.log("uaData = ", props.uaData);
  }, [props.uaData]);
  useEffect(() => {
    props.setPage("UpdatesAndAnnouncementsInvestor");
  }, []);

  return (
    <Grid container style={{ padding: "20px 10px 0px 20px" }}>
      {loader ? (
        <>
          <Grid
            item
            xs={12}
            style={{
              fontSize: "18px",
              fontWeight: "bolder",

              paddingLeft: "17px",
            }}
          >
            Updates
          </Grid>
          {/* Cards */}
          <Grid item xs={12}>
            {/* row1 */}
            <Grid item xs={12} className={styles.row1}>
              <Grid item xs={4} className={styles.singleCard}>
                <UACard
                  cardTitle="Product Presentations"
                  nameForApi="Product Presentations"
                />
              </Grid>
              <Grid item xs={4} className={styles.singleCard}>
                <UACard cardTitle="PPM" nameForApi="PPM" />
              </Grid>
              <Grid item xs={4} className={styles.singleCard}>
                <UACard
                  cardTitle="Addendums to PPM"
                  nameForApi="Addendum to PPM"
                />
              </Grid>
            </Grid>

            {/* row2*/}
            <Grid item xs={12} className={styles.row2}>
              <Grid item xs={3.65} className={styles.singleCard}>
                <UACard cardTitle="Fund Updates" nameForApi="Fund Updates" />
              </Grid>
              {/* <Grid item xs={4} className={styles.singleCard}>
                <UACard cardTitle="Announcements" nameForApi="Announcements" />
              </Grid> */}
              {/* <Grid item xs={4} className={styles.singleCard}>
                <UACard cardTitle="Tax Reckoners" nameForApi="Tax Reckoners" />
              </Grid> */}
            </Grid>

            {/* row3 */}
            {/* <Grid item xs={12} className={styles.row3}>
              <Grid item xs={4} className={styles.singleCard}></Grid>
              <Grid item xs={4} className={styles.singleCard}>
                <UACard cardTitle="Regulatory" nameForApi="Regulatory" />
              </Grid>
              <Grid item xs={4} className={styles.singleCard}></Grid>
            </Grid> */}
          </Grid>
        </>
      ) : (
        <Loader />
      )}
    </Grid>
  );
}

const mapStatetoProps = (state) => {
  return {
    unread: state.mainReducer.unread,
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    uaData: state.mainReducer.uaData,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    setUaData: (val) => {
      dispatch({ type: "CHANGE-UADATA", uaData: val });
    },
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(UpdatesAndAnnouncementsInvestor);
