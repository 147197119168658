import {
  Card,
  CardContent,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Modal,
  Checkbox,
  ListItemText,
  RadioGroup,
  Radio,
} from "@mui/material";
import { TextField } from "@mui/material";
import DashboardTable from "../DashboardTable";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { handleAPI } from "../../API/handleAPI";
import { connect } from "react-redux";
import RecentTransactionTable from "../RecentTransactionTable";
import styles from "../../assets/css/RecentTransaction.module.css";
import styles2 from "../../assets/css/Profile.module.css";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { ReactComponent as Approved } from "../../assets/svg/Approved.svg";
import { ReactComponent as Reject } from "../../assets/svg/Reject.svg";
import { ReactComponent as Editicon } from "../../assets/svg/Editicon.svg";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../../views/modalLoader";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";


function Approvals(props) {
  props.setPage("Approvals");
  //console.log("Inside Admin Approvals");

  const [searchString, setSearchString] = useState("");
  const [dataAll, setDataAll] = useState({});
  const [tableData, setTableData] = useState({});
  const [status, setStatus] = useState("approved");
  const [listOfStatus, setListOfStatus] = useState();
  const [loader, setLoader] = useState(false);
  const [pendingData, setPendingData] = useState({});
  const [approvedData, setApprovedData] = useState({});
  const [rejectedData, setRejectedData] = useState({});
  const [rows, setRows] = useState([]);
  const [rowsAll, setRowsAll] = useState([]);
  const [colsAll, setColsAll] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [fullName, setFullName] = useState("");
  const [userType, setUserType] = useState("");
  const [permission, setPermission] = useState("");
  const [open, setOpen] = useState(false);
  const [valueSelected, setValueSelected] = useState("");
  const [valueSelectedRadio, setValueSelectedRadio] = useState("");
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "280px",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "white",
    border: "2px solid black",
    boxShadow: 24,
    borderRadius: 3,
    p: 1,
    padding: "2px 5px",
  };

  useEffect(() => {
    if (props.userDetails.permissions == "") {
      navigate("/admin/profile");
    } else if (props.token) {
      //console.log("UserDetails", props.userDetails);
      //console.log("token-------", props.token);

      getUserDetails();
    }
  }, [props.token]);

  async function getUserDetails() {
    if (props.token) {
      await handleAPI(
        "/admin/getUserDetails",
        {
          authorizationCode: props.token,
          email: [],
          paginated: 0,
          pageNo: 1,
          size: 3,
          accessStatus: [],
          userType: [],
          accountType: [],
          fundCode: ["161"],
          accountStatus: [],
        },
        props.token
      )
        .then((response) => {
          //console.log("response.data -->>", response.data.approved);
          setPendingData(response.data.pending);
          setApprovedData(response.data.approved);
          setRejectedData(response.data.rejected);
          setRows(response.data.approved);
          setRowsAll(response.data.approved);
          setColsAll(approvalHeadCell);
          setLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);

         
          setLoader(true);
        });
    }
  }

  // When dropdown changes of status
  const handleChange2 = (event) => {
    setStatus(event.target.value);

    if (event.target.value === "approved") {
      setRows(approvedData);
      setRowsAll(approvedData);
      setColsAll(approvalHeadCell);
    } else if (event.target.value === "pending") {
      setRows(pendingData);
      setRowsAll(pendingData);
      setColsAll(pendingHeadCell);
    } else if (event.target.value === "rejected") {
      setRows(rejectedData);
      setRowsAll(rejectedData);
      setColsAll(rejectedHeadCell);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Search button in Approval
  const handleChange = (event) => {
    setSearchString(event.target.value);
    //console.log("searching for event", event.target.value);

    let filteredRows = rowsAll.filter((row) => {
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setRows(filteredRows);
  };

  // font styling for Status dropdown
  const MenuProps = {
    PaperProps: {
      sx: {
        "& .MuiMenuItem-root": {
          fontSize: 12,
          fontFamily: "Lato",
        },
      },
      style: {
        fontSize: 12,
        fontFamily: "Lato",
      },
    },
  };
  function handlePermission(event) {
    setPermission(event.target.value);
  }
  // Pending table headcells
  let pendingHeadCell = [
    {
      field: "id",
      numeric: true,
      disablePadding: false,
      headerName: "No.",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 50,
    },
    {
      field: "fullName",
      numeric: true,
      disablePadding: false,
      headerName: "Full Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 180,
    },
    {
      field: "email",
      numeric: true,
      disablePadding: false,
      headerName: "E-Mail",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 230,
    },
    {
      field: "userType",
      numeric: true,
      disablePadding: false,
      headerName: "UserType",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 100,
    },
    {
      field: "permission",
      numeric: true,
      disablePadding: false,
      headerName: "Permission",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 100,
    },

    ,
    {
      field: "approve",
      numeric: true,
      disablePadding: false,
      headerName: "Approve",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 140,
      renderCell: (params) => {
        const onClickFn = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          approveApiCall(params.row.email);
          // window.open(params.formattedValue, "_self");
        };
        return (
          <div>
            <Grid
              style={{
                // background: "#E6EDFE",
                margin: "50px",
                borderRadius: "10px",
              }}
            >
              <Button onClick={onClickFn}>
                <Approved />
              </Button>
            </Grid>
          </div>
        );
      },
    },
    ,
    {
      field: "reject",
      numeric: true,
      disablePadding: false,
      headerName: "Reject",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 140,
      renderCell: (params) => {
        const onClickFn = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          rejectApiCall(params.row.email);
          // window.open(params.formattedValue, "_self");
        };
        return (
          <div>
            <Grid
              style={{
                // background: "#FFE6E6",
                margin: "50px",
                borderRadius: "10px",
              }}
            >
              <Button onClick={onClickFn}>
                <Reject />
              </Button>
            </Grid>
          </div>
        );
      },
    },
  ];
  let rejectedHeadCell = [
    {
      field: "id",
      numeric: true,
      disablePadding: false,
      headerName: "No.",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 100,
    },
    {
      field: "fullName",
      numeric: true,
      disablePadding: false,
      headerName: "Full Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 280,
    },
    {
      field: "email",
      numeric: true,
      disablePadding: false,
      headerName: "E-Mail",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 280,
    },
    {
      field: "userType",
      numeric: true,
      disablePadding: false,
      headerName: "UserType",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 280,
    },
  ];

  // Approval headCells
  let approvalHeadCell = [
    // {
    //   field: "id",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "No.",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 50,
    // },
    {
      field: "fullName",
      numeric: true,
      disablePadding: false,
      headerName: "Full Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 180,
    },
    {
      field: "email",
      numeric: true,
      disablePadding: false,
      headerName: "E-Mail",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "userType",
      numeric: true,
      disablePadding: false,
      headerName: "UserType",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "permission",
      numeric: true,
      disablePadding: false,
      headerName: "Permission",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 130,
    },
    {
      field: "accountCreated",
      numeric: true,
      disablePadding: false,
      headerName: "Created On",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    ,
    {
      field: "approve",
      numeric: true,
      disablePadding: false,
      headerName: "Edit",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 130,
      renderCell: (params) => {
        const onClickFn = (e) => {
          e.stopPropagation();
          setOpen(true);
          //console.log("first params", params);
          editFunction(
            params.row.email,
            params.row.fullName,
            params.row.userType,
            params.row.permission
          );
          // window.open(params.formattedValue, "_self");
        };

        return (
          <div>
            <Grid>
              <Button onClick={onClickFn}>
                <Editicon />
              </Button>
            </Grid>
          </div>
        );
      },
    },
    ,
  ];
  async function approveApiCall(email) {
    await handleAPI(
      "/admin/updateUserDetails",
      {
        authorizationCode: props.token,
        email: email,
        action: "Approve",
        permission: "",
        fundCode: "161",
      },
      props.token
    )
      .then((response) => {
        //console.log(" approve response->", response);
        getUserDetails();
        setStatus("approved");
      })
      .catch((error) => {
       
      });
  }
  async function rejectApiCall(email) {
    await handleAPI(
      "/admin/updateUserDetails",
      {
        authorizationCode: props.token,
        email: email,
        action: "Reject",
        permission: "",
        fundCode: "161",
      },
      props.token
    )
      .then((response) => {
        console.log(" Reject response->", response);
        getUserDetails();
        setStatus("approved");
      })
      .catch((error) => {
        console.log("Error");
       
      });
  }

  function editFunction(email, fullName, userType, permission) {
    setEmailAddress(email);
    setFullName(fullName);
    setUserType(userType);
    setPermission(permission);
  }
  async function updateFunction() {
    await handleAPI(
      "/admin/updateUserDetails",
      {
        authorizationCode: props.token,
        email: emailAddress,
        action: "Update",
        permission: permission,
        fundCode: "161",
      },
      props.token
    )
      .then((response) => {
        //console.log("Update Function response->", response);

        setOpen(false);
        getUserDetails();
      })
      .catch((error) => {
        console.log("Error");

        setOpen(false);
       
      });
  }
  async function decommissionFunction() {
    await handleAPI(
      "/admin/updateUserDetails",
      {
        authorizationCode: props.token,
        email: emailAddress,
        action: "Decommission",
        permission: "",
        fundCode: "161",
      },
      props.token
    )
      .then((response) => {
        console.log("Decommission Function response->", response);

        setOpen(false);
        getUserDetails();
      })
      .catch((error) => {
        console.log("Error");
        setOpen(false);
       
      });
  }
  return (
    <div style={{ marginTop: "20px", marginLeft: "30px" }}>
      {loader ? (
        <Grid container>
          <Grid item xs={12}>
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "940px",
              }}
              className={styles.card}
            >
              <CardContent
                style={{
                  width: "100%",
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid item xs={6}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={status}
                        onChange={handleChange2}
                        sx={{ fontFamily: "lato", fontSize: "12px" }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={"pending"}>Pending</MenuItem>;
                        <MenuItem value={"approved"}>Approved</MenuItem>;
                        <MenuItem value={"rejected"}>Rejected</MenuItem>;
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      margin: "10px",
                      height: "35px",
                    }}
                  >
                    <div
                      style={{
                        border: "solid 1px black",
                        borderRadius: "30px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Search"
                        type="search"
                        onChange={handleChange}
                        value={searchString}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          ".MuiInputBase-input": {
                            borderBottom: "none !important",
                            padding: "5px 0 0 10px !important",
                            /*margin: -12px ;  */
                          },
                          paddingBottom: "5rem",
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  itesm
                  xs={12}
                  style={{ margin: "10px", fontWeight: "bold" }}
                >
                  Total : {rows.length}
                </Grid>

                <RecentTransactionTable
                  name={`${status} data`}
                  data={rows}
                  headCells={colsAll}
                />
              </CardContent>
            </Card>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              // style={{ backdropFilter: "blur(10px)" }}
            >
              <Box sx={style}>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Grid
                    item
                    xs={12}
                    onClick={handleClose}
                    style={{
                      cursor: "pointer",
                      display: "flex",

                      justifyContent: "right",
                    }}
                  >
                    <CloseSVG />
                  </Grid>
                  <Grid
                    xs
                    item={12}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Grid item xs={6} className={styles2.profileTextField}>
                      <TextField
                        style={{
                          width: "75%",
                        }}
                        id="approvalTextField"
                        label="Full Name"
                        value={fullName}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          style: { fontWeight: "800", color: "#333333" },
                        }}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={6} className={styles2.profileTextField}>
                      <TextField
                        style={{
                          width: "75%",
                        }}
                        id="approvalTextField"
                        label="Email"
                        value={emailAddress}
                        InputProps={{
                          readOnly: true,
                          // disableUnderline: true,
                        }}
                        InputLabelProps={{
                          style: { fontWeight: "800", color: "#333333" },
                        }}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs
                    item={12}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item xs={6} className={styles2.profileTextField}>
                      <TextField
                        style={{
                          width: "75%",
                        }}
                        id="approvalTextField"
                        label="User Type"
                        value={userType}
                        InputProps={{
                          readOnly: true,
                          // disableUnderline: true,
                        }}
                        InputLabelProps={{
                          style: { fontWeight: "800", color: "#333333" },
                        }}
                        variant="standard"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.radioButtonCss}
                      sx={{
                        fontFamily: "Lato",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      {" "}
                      {/* <FormControl sx={{ fontFamily: "Lato" }}>
                      <FormLabel id="demo-radio-buttons-group-label">
                        {" "}
                        Permission
                      </FormLabel>

                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={valueSelectedRadio}
                        // onChange={handleChange3}
                        sx={{ fontFamily: "Lato" }}
                        row
                      >
                        <FormControlLabel
                          value="All"
                          control={<Radio />}
                          sx={{ fontFamily: "Lato" }}
                          label="All"
                        />
                        <FormControlLabel
                          value="Contribution"
                          control={<Radio />}
                          label="Contribution"
                        />
                      </RadioGroup>
                    </FormControl> */}
                      {userType === "AMCUser" ? (
                        <FormControl
                          style={{
                            width: "100%",
                          }}
                        >
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              //   border: "solid 2px green",
                              width: "90%",
                            }}
                            value={permission}
                            onChange={handlePermission}
                          >
                            <FormControlLabel
                              value="Read"
                              control={<Radio />}
                              label="Read"
                            />
                            <FormControlLabel
                              value="Write"
                              control={<Radio />}
                              label="Write"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : userType === "KFintechUser" ? (
                        <FormControl style={{ width: "100%" }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              //   border: "solid 2px green",
                              width: "90%",
                            }}
                            value={permission}
                            onChange={handlePermission}
                          >
                            <FormControlLabel
                              value="Maker"
                              control={<Radio />}
                              label="Maker"
                            />
                            <FormControlLabel
                              value="Checker"
                              control={<Radio />}
                              label="Checker"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: "5vh",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#333333",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontWeight: "800",
                      fontFamily: "lato",
                    }}
                    className="btncss"
                    onClick={updateFunction}
                  >
                    Update
                  </Button>
                  <Button
                    style={{
                      backgroundColor:
                        "linear - gradient(93.3deg, #D73D3D 22.4 %, #CA0505 100 %)",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontWeight: "800",
                      fontFamily: "lato",
                    }}
                    className="btncssDecommission"
                    onClick={decommissionFunction}
                  >
                    Decommission
                  </Button>
                </Grid>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      ) : (
        <>
          {" "}
          <Grid
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <LoadingBlack /> */}
            <ModalLoader />
          </Grid>
        </>
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Approvals);
