import { CardContent } from "@mui/material";
import React from "react";
import { Card } from "@mui/material";
import DashboardTable from "./DashboardTable";

import Grid from "@mui/material/Grid";
import styles from "../assets/css/CardComponent.module.css";
function CardComponent(props) {
  return (
    <Grid item>
      <Card
        className={`${styles.bubbleBox} ${styles.card}`}
        // style={{ borderRadius: "10px" }}
      >
        <CardContent className={styles.cardTitleCss}>
          {props.cardTitle1} <br />
          {props.cardTitle2}
        </CardContent>{" "}
        <CardContent className={styles.cardContentCss}>
          {" "}
          {props.cardContent}
        </CardContent>
        <CardContent className={styles.dashboardTableCss}>
          <DashboardTable
            name={props.name}
            headCells={props.headCells}
            // data={props.data}
            detailHeader={props.detailHeader}
          />
          {/* </Button> */}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default CardComponent;
