import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";

import { ReactComponent as DeleteReport } from "../../assets/svg/DeleteReport.svg";
import { ReactComponent as DeleteReportDisabled } from "../../assets/svg/DeleteReportDisabled.svg";
import FormsAndDownloadTable from "../FormsAndDownload/FormsAndDownloadTable";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { useState } from "react";
import { handleAPI, handleFormDataAPI } from "../../API/handleAPI";
import styles from "../../assets/css/RecentTransaction.module.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import ModalLoader from "../../views/modalLoader";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";
import { Box } from "@mui/system";
import { AMC_DETAILS } from "../../variable";
import "../../assets/css/errorHandler.css";

function FormsAndDownloadsAdmin(props) {
  const [searchString, setSearchString] = useState("");
  const [searchString2, setSearchString2] = useState("");
  const [valueSelected, setValueSelected] = useState("investor");
  const [dataAll, setDataAll] = useState([]);
  const [dataAll2, setDataAll2] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderHistory, setLoaderHistory] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [picture, setPicture] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [preSignedURL, setPreSignedURL] = useState();
  const [loaderUpload, setLoaderUpload] = useState(true);
  const [disable, setDisable] = useState(false);
  const [uploadStatus, setUploadStatus] = useState();
  const [userType, setUserType] = useState(
    AMC_DETAILS.USER_PORTAL == "DIS" ? "distributor" : "investor"
  );
  const [deleteUniqueId, setDeleteUniqueId] = useState("");
  const navigate = useNavigate();

  let USER_TYPE = AMC_DETAILS.USER_PORTAL;
  console.log("user type ", userType);
  let FormsCols = [
    {
      field: "fileName",
      numeric: true,
      disablePadding: false,
      headerName: "Service Request Forms",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 400,
    },
    {
      field: "updatedOn",
      numeric: true,
      disablePadding: false,
      headerName: "Updated On",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
    {
      field: "viewFor",
      numeric: true,
      disablePadding: false,
      headerName: "View For",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 120,
    },

    {
      field: "signedUrl",
      numeric: true,
      disablePadding: false,
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 119,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          window.open(params.formattedValue, "_self");
        };

        return (
          <Button onClick={onClick}>
            <DownloadReport />
          </Button>
        );
      },
    },
    {
      field: "delete",
      numeric: true,
      disablePadding: false,
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClickFn = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          setDeleteUniqueId(params.row.uniqueId);
          setOpen2(true);
          // window.open(params.formattedValue, "_self");
        };

        return (
          <Button
            onClick={onClickFn}
            disabled={
              ["all", "write"].includes(props.userPermission) ? false : true
            }
          >
            {["all", "write"].includes(props.userPermission) ? (
              <DeleteReport />
            ) : (
              <DeleteReportDisabled />
            )}
          </Button>
        );
      },
    },
  ];
  let HistoryCols = [
    {
      field: "serviceRequestForms",
      numeric: true,
      disablePadding: false,
      headerName: "Service Request Forms",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 400,
    },
    {
      field: "activity",
      numeric: true,
      disablePadding: false,
      headerName: "Activity",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "user",
      numeric: true,
      disablePadding: false,
      headerName: "User",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 190,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
  ];

  async function deleteApiCall() {
    //console.log("User delete inside ", props.userDetails);
    setDisable(true);
    console.log("Inside delete api call");
    console.log(disable);
    handleAPI(
      "/admin/deleteFormsAndDownloadFiles",
      {
        uniqueId: deleteUniqueId,
        userName: props.userDetails.fullName,
      },
      props.token
    )
      .then((response) => {
        //console.log("response of delete Api", response);
        if (response.status) {
          historyApi();
          getFormsAndDownloadFiles();
          setOpen2(false);
        } else if (!response.status) {
          alert("Error! Unable to Revert");
        }
      })
      .catch((error) => {
        setOpen2(false);
      })
      .finally(() => {
        setDisable(false);
      });
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: AMC_DETAILS.USER_PORTAL === "BOTH" ? 700 : 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: AMC_DETAILS.USER_PORTAL === "BOTH" ? 400 : 200,
    p: 4,
  };
  const SingleUserModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: AMC_DETAILS.USER_PORTAL === "BOTH" ? 700 : 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: AMC_DETAILS.USER_PORTAL === "BOTH" ? 400 : 200,
    p: 4,
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: 3,
    boxShadow: 24,
    height: 200,
    p: 4,
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setPicture(null);
    setFileName("");
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  //console.log("User details", props.userDetails);
  const onChangePicture = (event) => {
    //console.log("Inside onchange picture", event.target.files[0]);
    setPicture(event.target.files[0]);
    setFileName(event.target.files[0].name);
    event.target.value = null;
  };

  useEffect(() => {
    props.setPage("FDA");
    if (props.userDetails.permissions == "") {
      navigate("/admin/profile");
    } else {
      historyApi();
      getFormsAndDownloadFiles();
    }
  }, [props.token]);
  async function getFormsAndDownloadFiles() {
    if (props.token) {
      handleAPI("/admin/getFormsAndDownloadFiles", {}, props.token)
        .then((response) => {
          //console.log("admin forms download", response.data);
          setTableData(response.data);
          setDataAll(response.data);

          setLoader(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);

          setLoader(true);
        });
    }
  }
  async function historyApi() {
    if (props.token) {
      handleAPI(
        "/admin/getAnnouncementAndFormsHistory",
        { tag: "forms" },
        props.token
      )
        .then((response) => {
          //console.log("admin forms download history", response.data);
          setTableData2(response.data);
          setDataAll2(response.data);

          setLoaderHistory(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);

          setLoaderHistory(true);
        });
    }
  }

  async function FileUpload(event) {
    setDisable(true);
    console.log(disable);
    await handleFormDataAPI("/admin/uploadFormsAndDownloads", picture, {
      uploadedBy: props.userDetails.fullName,
      userType: userType,
    })
      .then((response) => {
        setUniqueId(response.data.uniqueId);
        historyApi();
        getFormsAndDownloadFiles();
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Uploaded Successfully",
          confirmButtonColor: "#1976d2",
          customClass: {
            popup: "swalPopup",
            title: "title1",
          },
        });
      })
      .catch((error) => {
        console.log("Error");
        setLoaderUpload(true);
        setUploadStatus(false);
        handleClose();
      });

    setDisable(false);
  }

  const handleChange = (event) => {
    setSearchString(event.target.value);

    let filteredRows = tableData.filter((row) => {
      // let obj2 = { fileName: row.fileName };
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setDataAll(filteredRows);
  };
  const handleChange2 = (event) => {
    setSearchString2(event.target.value);

    let filteredRows2 = dataAll2?.filter((row) => {
      // let obj3 = { fileName: row.fileName };
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setTableData2(filteredRows2);
  };
  const handleChange3 = (event) => {
    setValueSelected(event.target.value);

    if (event.target.value === "investor") {
      setUserType("investor");
    } else if (event.target.value === "distributor") {
      setUserType("distributor");
    } else if (event.target.value === "both") {
      setUserType("both");
    }
  };

  //console.log(
  //   "Forms and Download Admin User Permission Tab :",
  //   props.userPermission
  // );
  async function downloadForms() {
    await handleAPI(
      "/admin/downloadAnnouncementAndFormHistory",
      {
        tag: "forms",
      },
      props.token
    )
      .then((response) => {
        //console.log("Download Status", response);
        if (response.data.historyAvailable) {
          // setDownloadLink(response.data.s3XlsxSignedUrl);
          window.open(response.data.s3XlsxSignedUrl, "_self");
        }
      })
      .catch((error) => {
        console.log("Error");
      });
  }

  return (
    <div style={{ marginTop: "20px", marginLeft: "30px" }}>
      {loader && loaderHistory ? (
        <Grid container style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Grid item xs={12}>
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "solid 2px red",
                // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
                width: "940px",
              }}
              // className={styles.card}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",

                  width: "940px",
                  marginBottom: "15px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    // padding: " 1rem 0 0.25rem 0.5rem",
                    fontSize: "18px",
                    fontWeight: "bolder",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "auto",
                    marginLeft: "40px",
                  }}
                >
                  Standard Forms
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "2rem",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px black",
                      borderRadius: "30px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Search"
                      type="search"
                      onChange={handleChange}
                      value={searchString}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        ".MuiInputBase-input": {
                          borderBottom: "none !important",
                          padding: "5px 0 0 10px !important",
                          /*margin: -12px ;  */
                        },
                        paddingBottom: "5rem",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormsAndDownloadTable
                  name="Standard Forms"
                  data={dataAll}
                  headCells={FormsCols}
                />
              </Grid>
              {AMC_DETAILS.USER_PORTAL === "BOTH" ? (
                <Grid
                  item
                  xs={12}
                  className={styles.buttonGrid}
                  style={{ marginBottom: "2vh" }}
                >
                  <Grid item xs={12} className={styles.DownloadAsExcelButton}>
                    <button
                      onClick={handleOpen}
                      className={
                        ["all", "write"].includes(props.userPermission)
                          ? styles.htmlButton
                          : styles.htmlButtonDisabled
                      }
                      disabled={
                        ["all", "write"].includes(props.userPermission)
                          ? false
                          : true
                      }
                    >
                      Upload
                    </button>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  className={styles.buttonGrid}
                  style={{ marginBottom: "2vh" }}
                >
                  <Grid item xs={12} className={styles.DownloadAsExcelButton}>
                    <label htmlFor="upload-data">
                      <input
                        style={{ display: "none" }}
                        id="upload-data"
                        name="upload-data"
                        type={
                          ["all", "write"].includes(props.userPermission)
                            ? "file"
                            : "text"
                        }
                        onChange={(e) => {
                          onChangePicture(e);
                          handleOpen();
                        }}
                      />
                      {/* <button */}
                      <Button
                        component="span"
                        style={{
                          backgroundColor: "#333333",
                          borderRadius: "20px",
                          textTransform: "none",
                          fontWeight: "800",
                          fontFamily: "lato",
                        }}
                        className={
                          ["all", "write"].includes(props.userPermission)
                            ? styles.htmlButton
                            : styles.htmlButtonDisabled
                        }
                        disabled={
                          ["all", "write"].includes(props.userPermission)
                            ? false
                            : true
                        }
                      >
                        <span style={{ color: "#ffffff" }}>Browse</span>
                      </Button>
                      {/* </button> */}
                    </label>
                  </Grid>
                </Grid>
              )}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backdropFilter: "blur(10px)" }}
              >
                <Grid item sx={style}>
                  {loaderUpload ? (
                    USER_TYPE == "BOTH" ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Select the user group relevant for the form to be
                          uploaded
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          className={styles.radioButtonCss}
                          sx={{ fontFamily: "Lato" }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <FormControl sx={{ fontFamily: "Lato" }}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={valueSelected}
                              onChange={handleChange3}
                              sx={{ fontFamily: "Lato" }}
                              row
                            >
                              <FormControlLabel
                                value="investor"
                                control={<Radio />}
                                sx={{ fontFamily: "Lato" }}
                                label="Investor only"
                              />
                              <FormControlLabel
                                value="distributor"
                                control={<Radio />}
                                label="Distributor only"
                              />
                              <FormControlLabel
                                value="both"
                                control={<Radio />}
                                label="Both"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {/* File Upload */}
                          <Grid
                            item
                            xs={12}
                            style={{
                              padding: "10px",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="upload-data">
                                <input
                                  style={{ display: "none" }}
                                  id="upload-data"
                                  name="upload-data"
                                  type="file"
                                  onChange={onChangePicture}
                                />
                                {/* <button */}
                                <Button
                                  component="span"
                                  style={{
                                    backgroundColor: "#333333",
                                    borderRadius: "20px",
                                    textTransform: "none",
                                    fontWeight: "800",
                                    fontFamily: "lato",
                                  }}
                                  className="btncss"
                                >
                                  <span style={{ color: "#ffffff" }}>
                                    Browse
                                  </span>
                                </Button>
                                {/* </button> */}
                              </label>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "15px" }}>
                              {fileName ? (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    fontFamily: "Lato",
                                    paddingTop: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* {//console.log("picture", picture)} */}
                                  Are you sure you want to upload&nbsp;
                                  <br />
                                  <Box
                                    sx={{
                                      fontWeight: 600,
                                      mt: 2,
                                    }}
                                  >
                                    {fileName?.replace(
                                      /^(.{60}).*?(\.[^.]+)$/,
                                      "$1...$2"
                                    )}{" "}
                                    ?
                                  </Box>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {fileName ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <Button
                                onClick={FileUpload}
                                component="span"
                                style={{
                                  backgroundColor: "#333333",
                                  borderRadius: "20px",
                                  textTransform: "none",
                                  fontWeight: "800",
                                  fontFamily: "lato",
                                }}
                                className={
                                  disable ? "btncss-disable" : "btncss"
                                }
                              >
                                <span style={{ color: "#ffffff" }}>
                                  Confirm
                                </span>
                              </Button>
                            </Typography>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {/* File Upload */}
                          <Grid
                            item
                            xs={12}
                            style={{
                              padding: "10px",
                            }}
                          >
                            <Grid item xs={12}>
                              {fileName ? (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    fontFamily: "Lato",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* {//console.log("picture", picture)} */}
                                  Are you sure you want to upload&nbsp;
                                  <br />
                                  <Box
                                    sx={{
                                      fontWeight: 600,
                                      mt: 2,
                                    }}
                                  >
                                    {fileName?.replace(
                                      /^(.{30}).*?(\.[^.]+)$/,
                                      "$1...$2"
                                    )}{" "}
                                    ?
                                  </Box>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {fileName ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <Button
                                onClick={FileUpload}
                                // disabled={picture ? false : true}
                                component="span"
                                style={{
                                  backgroundColor: "#333333",
                                  borderRadius: "20px",
                                  textTransform: "none",
                                  fontWeight: "800",
                                  fontFamily: "lato",
                                }}
                                className={
                                  disable ? "btncss-disable" : "btncss"
                                }
                              >
                                <span style={{ color: "#ffffff" }}>
                                  Confirm
                                </span>
                              </Button>
                            </Typography>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  ) : (
                    <Grid
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ModalLoader />
                    </Grid>
                  )}
                </Grid>
              </Modal>
            </Card>

            {/* history table */}
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "solid 2px red",
                // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
                width: "940px",
                marginTop: "20px",
              }}
              // className={styles.card}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",

                  width: "940px",
                  marginBottom: "15px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    // padding: " 1rem 0 0.25rem 0.5rem",
                    fontSize: "18px",
                    fontWeight: "bolder",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "auto",
                    marginLeft: "40px",
                  }}
                >
                  History
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "2rem",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px black",
                      borderRadius: "30px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="search"
                      placeholder="Search"
                      onChange={handleChange2}
                      value={searchString2}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        ".MuiInputBase-input": {
                          borderBottom: "none !important",
                          padding: "5px 0 0 10px !important",
                          /*margin: -12px ;  */
                        },
                        paddingBottom: "5rem",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              {/* <Grid
              item
              xs={8}
              className={styles.radioButtonCss}
              sx={{ fontFamily: "Lato" }}
            >
              <FormControl sx={{ fontFamily: "Lato" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueSelected}
                  onChange={handleChange}
                  sx={{ fontFamily: "Lato" }}
                  row
                >
                  <FormControlLabel
                    value="All"
                    control={<Radio />}
                    sx={{ fontFamily: "Lato" }}
                    label="Distributor Related"
                  />
                  <FormControlLabel
                    value="Contribution"
                    control={<Radio />}
                    label="Service Request Form"
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}
              <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backdropFilter: "blur(10px)" }}
              >
                <Grid container sx={style2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "Lato",
                    }}
                  >
                    Are you sure you want to delete this form?
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      component="span"
                      onClick={deleteApiCall}
                      style={{
                        backgroundColor: "#333333",
                        borderRadius: "20px",
                        textTransform: "none",
                        fontWeight: "800",
                        fontFamily: "lato",
                      }}
                      className={disable ? "btncss-disable" : "btncss"}
                    >
                      <span style={{ color: "#ffffff" }}>Confirm</span>
                    </Button>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                <LoadingBlack />
              </Grid> */}
              </Modal>
              <Grid item xs={12}>
                <FormsAndDownloadTable
                  name="History"
                  data={tableData2}
                  headCells={HistoryCols}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={downloadForms}
                  className={
                    dataAll2?.length > 0
                      ? styles.htmlButton
                      : styles.htmlButtonDisabled
                  }
                >
                  Download as Excel
                </button>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    userPermission: state.mainReducer.userPermission,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(FormsAndDownloadsAdmin);
