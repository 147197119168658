// Modal Popup of the Dashboard Bubble Boxes

import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";

import TableModal from "../TableModal";
import "../../assets/css/DashboardTable.css";
import { connect } from "react-redux";
import { ReactComponent as OpenTable } from "../../assets/svg/OpenTable copy.svg";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid black",
  boxShadow: 24,
  borderRadius: 3,
  p: 1,
  padding: "2px 5px",
};

function DashboardDistributorTable(props) {
  const [searchString, setSearchString] = useState("");
  const [rows, setRows] = useState(props.data);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState({});

  const handleOpen = () => {
    setOpen(true);
    setTableData(props.data);
    setRows(props.data);

    setLoader(true);
  };
  const handleClose = () => {
    setOpen(false);
    setLoader(false);
  };

  const handleChange = (event) => {
    setSearchString(event.target.value);

    let filteredRows = tableData.filter((row) => {
      let temp = JSON.stringify(Object.values(row));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setRows(filteredRows);
  };

  function downloadXlsxData() {
    //console.log("Inside downloadXLSX Link --->", downloadLink.xlsxLink);
    window.open(props.downloadLink.xlsxLink, "_self");
  }

  function downloadPdfData() {
    //console.log("Inside downloadPDF Link --->", downloadLink.pdfLink);
    window.open(props.downloadLink.pdfLink, "_self");
  }

  return (
    <div>
      <Grid container>
        <Button onClick={handleOpen}>
          <OpenTable />
        </Button>
        {loader && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid item className="header">
                <Grid item xs={6} className="title">
                  {props.detailHeader}
                </Grid>
                <Grid
                  item
                  xs={6}
                  onClick={handleClose}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <CloseSVG />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "2rem",
                  height: "35px",
                }}
              >
                <div
                  style={{
                    border: "solid 1px black",
                    borderRadius: "30px",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Search"
                    type="search"
                    onChange={handleChange}
                    value={searchString}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      ".MuiInputBase-input": {
                        borderBottom: "none !important",
                        padding: "5px 0 0 10px !important",
                        /*margin: -12px ;  */
                      },
                      paddingBottom: "5rem",
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "1rem" }}>
                <TableModal headCells={props.headCells} data={rows} />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <div className="downloadBtn">
                  <button
                    style={{ backgroundColor: "#333333" }}
                    onClick={downloadXlsxData}
                    className="btncss"
                  >
                    Download as Excel
                  </button>
                  <button
                    style={{ backgroundColor: "#333333" }}
                    onClick={downloadPdfData}
                    className="btncss"
                  >
                    Download as PDF
                  </button>
                </div>
              </Grid>
            </Box>
          </Modal>
        )}
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(DashboardDistributorTable);
