import * as React from "react";
import Grid from "@mui/material/Grid";
import styles from "../../assets/css/LeftNav.module.css";
// import { ReactComponent as KfinLogo } from "../../assets/svg/axisleftnav.svg";
import { ReactComponent as KfinLogo } from "../../assets/svg/KfintechNavBarLogo.svg";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { AMC_DETAILS } from "../../variable";

let ReportSVG,
  DashboardSVG,
  UpdatesSVG,
  FormsDownloadSVG,
  ClientInvestmentMasterSVG,
  BokerageSVG;

if (AMC_DETAILS.MODE === "dark") {
  ReportSVG = require("../../assets/svg/ClientReport.svg").ReactComponent;
  DashboardSVG = require("../../assets/svg/Dashboard.svg").ReactComponent;
  UpdatesSVG = require("../../assets/svg/Updates.svg").ReactComponent;
  FormsDownloadSVG =
    require("../../assets/svg/FormsDownload.svg").ReactComponent;
  ClientInvestmentMasterSVG =
    require("../../assets/svg/ClientInvestmentMaster.svg").ReactComponent;
  BokerageSVG =
    require("../../assets/svg/Brokerage Tab logo.svg").ReactComponent;
} else {
  ReportSVG = require("../../assets/svg/ClientReportLight.svg").ReactComponent;
  DashboardSVG = require("../../assets/svg/DashboardLight.svg").ReactComponent;
  UpdatesSVG = require("../../assets/svg/UpdatesLight.svg").ReactComponent;
  FormsDownloadSVG =
    require("../../assets/svg/FormsDownloadLight.svg").ReactComponent;
  ClientInvestmentMasterSVG =
    require("../../assets/svg/ClientInvestmentMasterLight.svg").ReactComponent;
  BokerageSVG =
    require("../../assets/svg/Brokerage Tab logoLight.svg").ReactComponent;
}

function LeftNavDistributor(props) {
  return (
    <div style={{ paddingTop: "65px", zIndex: "2" }}>
      <Grid
        className={styles.leftNavBar}
        container
        maxWidth={"18rem"}
        minWidth={"18rem"}
        marginTop={"-63px"}
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="fixed"
      >
        {" "}
        <Grid
          item
          className={styles.leftTile}
          style={{
            paddingTop: " 70px",
            paddingLeft: "35px",
            paddingBottom: "50px",
            zIndex: "4",
          }}
        >
          <KfinLogo />
        </Grid>
        <Grid
          item
          className={
            props.page === "DIS" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/distributor/dashboard"
            style={{
              textDecoration: "none",
              fontWeight: AMC_DETAILS.MODE === "dark" ? "500" : "600",
            }}
            className={styles.leftTile}
          >
            <DashboardSVG
              className={
                AMC_DETAILS.MODE === "dark" ? styles.tileSvg : styles.tile2Svg2
              }
            />
            Dashboard
          </NavLink>
        </Grid>
        <Grid
          item
          className={
            props.page === "RecentTransactionDistributor"
              ? styles.leftTileGolden
              : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/distributor/investmentMaster"
            style={{
              textDecoration: "none",
              fontWeight: AMC_DETAILS.MODE === "dark" ? "500" : "600",
            }}
            className={styles.leftTile}
          >
            <ClientInvestmentMasterSVG
              className={
                AMC_DETAILS.MODE === "dark" ? styles.tileSvg : styles.tile2Svg2
              }
            />
            Investment Master
          </NavLink>
        </Grid>
        <Grid
          item
          className={
            props.page === "CLID" ? styles.leftTileGolden : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/distributor/investorReports"
            style={{
              textDecoration: "none",
              fontWeight: AMC_DETAILS.MODE === "dark" ? "500" : "600",
            }}
            className={styles.leftTile}
          >
            <ReportSVG
              className={
                AMC_DETAILS.MODE === "dark" ? styles.tileSvg : styles.tile2Svg2
              }
            />
            Investor Reports
          </NavLink>
        </Grid>
        <Grid
          item
          className={
            props.page === "UpdatesAndAnnouncementsDistributor"
              ? styles.leftTileGolden
              : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <Grid
            item
            xs={12}
            // style={{ display: "flex", justifyContent: "space-around" }}
          >
            <NavLink
              to="/distributor/updates"
              style={{
                textDecoration: "none",
                fontWeight: AMC_DETAILS.MODE === "dark" ? "500" : "600",
              }}
              className={styles.leftTile}
            >
              <UpdatesSVG
                className={
                  AMC_DETAILS.MODE === "dark"
                    ? styles.tileSvg
                    : styles.tile2Svg2
                }
              />
              Updates &nbsp;&nbsp;&nbsp;&nbsp;
              {props.unread ? (
                <span className={styles.dot}>{props.unread}</span>
              ) : (
                <></>
              )}
            </NavLink>
          </Grid>
        </Grid>
        <Grid
          item
          className={
            props.page === "FormsAndDownloadDistributor"
              ? styles.leftTileGolden
              : styles.leftTile
          }
          // style={{ borderBottom: "solid 2px #AD9042" }}
        >
          <NavLink
            to="/distributor/standardForms"
            style={{
              textDecoration: "none",
              fontWeight: AMC_DETAILS.MODE === "dark" ? "500" : "600",
            }}
            className={styles.leftTile}
          >
            <FormsDownloadSVG
              className={
                AMC_DETAILS.MODE === "dark" ? styles.tileSvg : styles.tile2Svg2
              }
            />
            Standard Forms
          </NavLink>
        </Grid>
        {AMC_DETAILS.ENABLE_BROKERAGE && (
          <Grid
            item
            className={
              props.page === "Brokerage"
                ? styles.leftTileGolden
                : styles.leftTile
            }
            // style={{ borderBottom: "solid 2px #AD9042" }}
          >
            <NavLink
              to="/distributor/brokerage"
              style={{
                textDecoration: "none",
                fontWeight: AMC_DETAILS.MODE === "dark" ? "500" : "600",
              }}
              className={styles.leftTile}
            >
              <BokerageSVG
                className={
                  AMC_DETAILS.MODE === "dark"
                    ? styles.tileSvg
                    : styles.tile2Svg2
                }
              />
              Brokerage
            </NavLink>
          </Grid>
        )}
        <Grid item className={styles.lastTile}></Grid>
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    unread: state.mainReducer.unread,
  };
};
export default connect(mapStatetoProps)(LeftNavDistributor);
