import {
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { ReactComponent as LoginImage } from "../../assets/svg/AxisLogo1.svg";
import { ReactComponent as AlternatesLoginLogo } from "../../../src/assets/svg/AlternatesLoginLogo.svg";
import styles from "../../../src/assets/css/Signup.module.css";
import { useState } from "react";
import AxisBg from "../../assets/svg/AxisBg.svg";
import {
  handleAPI,
  handleOnboardSaasAPI,
  handleSaasAPI,
} from "../../API/handleAPI";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import { ReactComponent as signupbg } from "../../assets/svg/MainBackground.svg";
// import styles from "../../assets/css/Login.module.css";
import { SAAS_AUTH } from "../../variable";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";
import validator from "validator";
import { authStore } from "../../utils/authStore";
import { isEmail, isStrongPassword } from "validator";

function Signup(props) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [pan, setPan] = useState("");
  const [hasSubmit, setHasSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmPasswordHelperText, setconfirmPasswordHelperText] =
    useState("");
  const [resStatus, setresStatus] = useState(false);
  const [clicked, setCliked] = useState(false);

  const [btnDisable, setBtnDisable] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const validator = require("validator");

  useEffect(() => {
    props.setShowTLNav(false);
  }, []);
  function checkConfirmPassword(value) {
    setConfirmNewPassword(value);
    if (value !== password) {
      setconfirmPasswordHelperText("Passwords must be same");
    } else {
      setconfirmPasswordHelperText("Password match");
    }
  }
  function passwordChecker(value) {
    return validator.isStrongPassword(value, {
      minLength: 8,
      minSymbols: 1,
      minUppercase: 1,
      minNumbers: 1,
    });
  }
  function handleFirstname(event) {
    setFirstname(event.target.value);
  }
  function handleLastname(event) {
    setLastname(event.target.value);
  }
  function handleEmailId(event) {
    setEmailId(event.target.value);
  }
  function handlePhoneNo(event) {
    setPhoneNo(event.target.value);
  }
  function handlePAN(event) {
    setPan(event.target.value);
  }

  function handleSubmit() {
    //   //console.log("firstname = ", firstname);
    //   //console.log("last Name = ", lastname);
    //   //console.log("emailId = ", emailId);
    //   //console.log("phoneNo = ", phoneNo);
    //   //console.log("permission = ", permission);

    setHasSubmit(true);

    if (
      firstname.trim() &&
      emailId.trim() &&
      password.trim() &&
      phoneNo.trim() &&
      confirmNewPassword.trim() &&
      password.trim() == confirmNewPassword.trim() &&
      validator.isEmail(emailId) &&
      validator.isStrongPassword(password, {
        minLength: 8,
        minSymbols: 1,
        minUppercase: 1,
        minNumbers: 1,
      })
    ) {
      setBtnDisable(true);
      setCliked(true);
      props.setShowLoader(true);

      let payload = {
        email: emailId,
      };

      handleSaasAPI("/validate/user", payload)
        .then((res) => {
          //console.log(res);
          setresStatus(true);
          if (res.message == "User already exists!") {
            setresStatus(true);
            props.setShowLoader(false);
            Swal.fire({
              position: "center",
              icon: "error",
              title: `User already exist`,
              confirmButtonColor: "#337fc9",
              confirmButtonText: "Go To Login",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              }
            });
          } else {
            setresStatus(true);
            alert("validate user responce changed");
          }
          setBtnDisable(false);
        })
        .catch((error) => {
          // //console.log(error.)
          if (error.errorData?.message == "User not found!") {
            let payload = {
              payload: {
                firstName: firstname,
                lastName: lastname,
                userName: `${firstname}${authStore.getItem("tenantCode")}`,
                email: emailId,
                phNumber: phoneNo,
                code: authStore.getItem("tenantCode"),
                userType: "AMCUser",
                accountType: "ADM",
                pan: pan,
                password: password,
                appName: SAAS_AUTH.COGNITO_APPNAME,
              },
            };
            handleOnboardSaasAPI("/onboard/selfOnboardUser", payload)
              .then((res) => {
                //console.log(res);

                if (res.message == "User created successfully") {
                  setresStatus(true);
                  navigate("/verifyotp", { state: { email: emailId } });
                } else {
                  setresStatus(true);
                  alert("adm on board api responce changed");
                }
                setBtnDisable(false);
                props.setShowLoader(false);
              })
              .catch((error) => {
                console.log(error);
                setresStatus(true);
                setBtnDisable(false);
                props.setShowLoader(false);
              });
          } else {
            setresStatus(true);
            props.setShowLoader(false);
            alert("error in validate user responce api");
          }
        });
    }
  }
  return (
    <Grid
      className={styles.container}
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Logo */}
      {/* <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "120px",
          margin: "20px 0px 20px 0px",
        }}
        >
        <AlternatesLoginLogo />
      </Grid> */}
      {/* Card */}

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "14vh",
        }}
      >
        <Card className={styles.card}>
          <CardContent>
            <form
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault(); // Prevent default form submission
                  handleSubmit(); // Call your login function here
                  console.log("hello");
                }
              }}
            >
              <Grid item xs={12}>
                {/* Signup */}
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "30px 0px 30px 0px",
                    fontWeight: "bolder",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                  }}
                >
                  SIGN UP
                </Grid>

                {/* Firstname and Lastname */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px 0px 20px 0px",
                  }}
                >
                  {/* Firstname */}
                  <Grid
                    item
                    xs={6}
                    style={{
                      margin: "0px 20px 0px 30px",
                    }}
                  >
                    <TextField
                      error={hasSubmit && firstname.length === 0 ? true : false}
                      required={true}
                      id="login-first-name"
                      label="First Name"
                      helperText={
                        hasSubmit && firstname.length === 0
                          ? "First Name cannot be blank"
                          : null
                      }
                      variant="standard"
                      onChange={handleFirstname}
                      autoComplete="off"
                      InputLabelProps={{
                        style: { color: "#333333" },
                      }}
                      style={{ width: "90%" }}
                      FormHelperTextProps={{
                        style: { color: "red" },
                      }}
                    />
                  </Grid>
                  {/* Lastname */}
                  <Grid
                    item
                    xs={6}
                    style={{
                      margin: "0px 0px 0px 80px",
                    }}
                  >
                    <TextField
                      id="login-last-name"
                      label="Last Name"
                      variant="standard"
                      onChange={handleLastname}
                      style={{ width: "90%" }}
                      InputLabelProps={{
                        style: { color: "#333333" },
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>

                {/* EmailId and PhoneNo */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px 0px 20px 0px",
                  }}
                >
                  {/* EmailId */}
                  <Grid
                    item
                    xs={6}
                    style={{
                      margin: "0px 30px 0px 30px",
                    }}
                  >
                    <TextField
                      error={
                        hasSubmit &&
                        (emailId.length === 0 || !validator.isEmail(emailId))
                          ? true
                          : false
                      }
                      required={true}
                      id="login-emailId-name"
                      label="Email Id "
                      helperText={
                        hasSubmit && emailId.length === 0
                          ? "Email Id cannot be blank"
                          : hasSubmit && !validator.isEmail(emailId)
                          ? "Invalid EmailID"
                          : null
                      }
                      variant="standard"
                      onChange={handleEmailId}
                      style={{ width: "90%" }}
                      InputLabelProps={{
                        style: { color: "#333333" },
                      }}
                      FormHelperTextProps={{
                        style: { color: "red" },
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                  {/* PhoneNo */}
                  <Grid
                    item
                    xs={6}
                    style={{
                      margin: "0px 0px 0px 75px",
                    }}
                  >
                    <TextField
                      error={hasSubmit && phoneNo.length === 0 ? true : false}
                      id="login-phoneNo-name"
                      required={true}
                      label="Phone Number"
                      variant="standard"
                      onChange={handlePhoneNo}
                      style={{ width: "90%" }}
                      helperText={
                        hasSubmit && phoneNo.length === 0
                          ? "Phone number can't be blank"
                          : null
                      }
                      InputLabelProps={{
                        style: { color: "#333333" },
                      }}
                      autoComplete="off"
                      FormHelperTextProps={{
                        style: { color: "red" },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // margin: "20px 0px 20px 0px",
                  }}
                >
                  {/* password */}
                  <Grid
                    item
                    xs={6}
                    style={{
                      margin: "0px 7px 0px 29px",
                    }}
                  >
                    <div>
                      {" "}
                      <TextField
                        variant="standard"
                        label="Create Password"
                        error={
                          hasSubmit &&
                          (password.length === 0 ||
                            !validator.isStrongPassword(password, {
                              minLength: 8,
                              minSymbols: 1,
                              minUppercase: 1,
                              minNumbers: 1,
                            }))
                            ? true
                            : false
                        }
                        required={true}
                        style={{
                          border: "none",
                          borderRadius: "0px",
                          width: "77%",
                        }}
                        className={styles.inputFormat}
                        type={showPassword ? "password" : "text"}
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        helperText={
                          hasSubmit && password.length === 0
                            ? "Password field can't be blank"
                            : hasSubmit &&
                              !validator.isStrongPassword(password, {
                                minLength: 8,
                                minSymbols: 1,
                                minUppercase: 1,
                                minNumbers: 1,
                              })
                            ? "Password must contain atleast one symbol , one uppercase , one number and minimum 8 characters"
                            : null
                          // : ! passwordChecker(password)
                          // ? "Password is not strong enough"
                        }
                        autoComplete="off"
                        FormHelperTextProps={{
                          style:
                            password.length === 0
                              ? { color: "red" }
                              : { color: "grey" },
                        }}
                        InputLabelProps={{
                          style: { color: "#333333" },
                        }}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <span
                      style={{
                        marginTop: ".3rem",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: ".7rem",
                        color: "red",
                        fontFamily: "Lato",
                      }}
                    ></span>
                  </Grid>
                  <Grid
                    item
                    xs={5.5}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextField
                      variant="standard"
                      id="outlined-controlled2"
                      label="Confirm New Password"
                      type="password"
                      required={true}
                      style={{
                        // border: "solid 2px red",
                        borderRadius: "0px",
                        width: "80%",
                      }}
                      autoComplete="off"
                      error={
                        hasSubmit && confirmNewPassword.length === 0
                          ? true
                          : false
                      }
                      value={confirmNewPassword}
                      onChange={(event) => {
                        checkConfirmPassword(event.target.value);
                      }}
                      helperText={
                        hasSubmit && confirmNewPassword.length == 0
                          ? "Password field can't be blank"
                          : hasSubmit && confirmPasswordHelperText
                      }
                      FormHelperTextProps={{
                        style:
                          confirmNewPassword.length === 0
                            ? { color: "red" }
                            : { color: "grey" },
                      }}
                      InputLabelProps={{
                        style: { color: "#333333" },
                      }}
                      // error={confirmPasswordHelperText !== "Password match"}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "30px 0px 18px -3px",
                  }}
                >
                  <Button
                    component="span"
                    style={{
                      backgroundColor: "#333333",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontWeight: "800",
                      fontFamily: "lato",
                    }}
                    className={btnDisable ? "btncss-disable" : "btncss"}
                    onClick={handleSubmit}
                  >
                    <span style={{ color: "white" }}>Submit</span>
                  </Button>
                </Grid>
                {/* Already have account */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      margin: "5px",
                      fontFamily: "Lato",
                    }}
                  >
                    Already have an account?
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      margin: "5px",
                      fontFamily: "Lato",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/")}
                  >
                    Log In
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const mapDispatchtoProps = (dispatch) => {
  return {
    setSignupEmail: (val) => {
      dispatch({ type: "CHANGE-SIGNUP-EMAIL", signupEmail: val });
    },
    setShowTLNav: (val) => {
      dispatch({ type: "CHANGE-SHOW-TLNAV", showTLNav: val });
    },
    setShowLoader: (val) => {
      dispatch({ type: "CHANGE-SHOW-LOADER", showLoader: val });
    },
  };
};

const mapStatetoProps = (state) => {
  return {
    signupEmail: state.mainReducer.signupEmail,
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Signup);
