import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { handleAPI } from "../../API/handleAPI";
import { useEffect } from "react";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { handleSaasAPI } from "../../API/handleAPI";
import { SAAS_AUTH } from "../../variable";
import { Email, Visibility, VisibilityOff } from "@mui/icons-material";
import { authStore } from "../../utils/authStore";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";

function CreateForgotPassword(props) {
  const [otp, setOtp] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showResetPwd, setShowResetPwd] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [otpHelperText, setOtpHelperText] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState("");
  const [openPan, setOpenPan] = useState(false);
  const [pan, setPan] = useState("");
  const [confirmPasswordHelperText, setconfirmPasswordHelperText] =
    useState("");
  const panHelperText =
    "Multiple PANs attached to this email id. Enter the PAN you want to login as Investor/Distributor";

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();

  const handleOpenPan = () => {
    setOpenPan(true);
  };
  const handleClosePan = () => {
    setOpenPan(false);
  };
  function handlePan(event) {
    setPan(event.target.value);
  }
  async function sendOtp(multipan = false) {
    if (userEmail) {
      let payload = {
        userName: userEmail,
        code: authStore.getItem("tenantCode"),
        appName: SAAS_AUTH.COGNITO_APPNAME,
      };

      if (multipan) {
        payload = {
          userName: userEmail,
          code: authStore.getItem("tenantCode"),
          appName: SAAS_AUTH.COGNITO_APPNAME,
          pan: pan,
        };
        handleClosePan();
      }
      setDisabled(true);
      await handleSaasAPI("/auth/forgetPassword", payload)
        .then((response) => {
          //console.log("response inside CreateForgotPassword", response);
          setDisabled(false);
          setShowResetPwd(true);
        })
        .catch((error) => {
          setDisabled(false);
          if (error.errorData?.message == "FoundMultipleUsersRequirespan") {
            handleOpenPan();
            setIsValidEmail("Confirm your PAN");
            return;
          }
          setIsValidEmail("Email id doesn't exist");
          console.log("error in forgot password", error);
        });
    } else {
    }
  }

  function handleOtp(event) {
    setOtp(event.target.value);
  }
  function handleEmail(event) {
    setUserEmail(event.target.value);
  }
  //console.log("user email ", userEmail);
  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minSymbols: 1,
        minUppercase: 1,
        minNumbers: 1,
      })
    ) {
      setNewPassword(value);
      setPasswordHelperText("Strong Password");
    } else {
      setNewPassword(value);
      setPasswordHelperText(
        "Password must contain atleast one symbol , one uppercase , one number and minimum 8 characters"
      );
    }
  };

  // Function to validate the confirm new password
  function checkConfirmPassword(value) {
    setConfirmNewPassword(value);
    if (value !== newPassword) {
      setconfirmPasswordHelperText("Passwords must be same");
    } else {
      setconfirmPasswordHelperText("Password match");
    }
  }
  useEffect(() => {
    if (confirmNewPassword !== newPassword) {
      setconfirmPasswordHelperText("Passwords must be same");
    } else {
      setconfirmPasswordHelperText("Password match");
    }
  }, [newPassword]);

  useEffect(() => {
    setOtpHelperText(`OTP has been sent to ${userEmail}`);
  }, [userEmail]);

  // Function to update password
  async function updatePassword() {
    let payload = {
      userName: userEmail,
      password: newPassword,
      otp: otp,
      code: authStore.getItem("tenantCode"),
      appName: SAAS_AUTH.COGNITO_APPNAME,
    };
    if (pan.length > 0) {
      payload.pan = pan;
    }
    setDisabled(true);
    await handleSaasAPI("/auth/resetPassword", payload)
      .then((response) => {
        //console.log("Update password response", response);
        navigate("/");
        setIsValidEmail(true);
        props.setOpen(false);
        setDisabled(false);
      })

      .catch((err) => {
        console.log("Error", err);
        setOtpHelperText("Invalid Otp");
        setDisabled(false);
      });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: 500,
    p: 4,
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: 350,
    p: 4,
  };
  return (
    <Grid item sx={style}>
      <Grid
        item
        xs={12}
        style={{
          //   border: "solid 2px yellow",
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "20px",
          marginBottom: "20px",
          //   height: "30px",
        }}
      >
        Reset Password
      </Grid>
      {!showResetPwd ? (
        <form
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // Prevent default form submission
              sendOtp(); // Call your login function here
              console.log("hello");
            }
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              //   border: "solid 2px pink",
              display: "flex",
              flexDirection: "column",
              height: "350px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6rem",
                }}
              >
                <TextField
                  variant="standard"
                  label="Email"
                  InputLabelProps={{
                    style: { fontWeight: "800" },
                    sx: {
                      "&.Mui-focused": {
                        color: "#000084", // Label color when focused
                      },
                    },
                  }}
                  style={{
                    // border: "solid 2px red",
                    borderRadius: "0px",
                    width: "80%",
                  }}
                  type="text"
                  name="User Email"
                  value={userEmail}
                  onChange={handleEmail}
                  error={isValidEmail.length > 0}
                  helperText={
                    isValidEmail.length > 0
                      ? isValidEmail
                      : "Enter your registered email Id , in which an otp will be sent"
                  }
                  FormHelperTextProps={{ style: { color: "gray" } }}
                  InputProps={{
                    disableUnderline: true, // Disable the default underline
                    sx: {
                      "&:hover": {
                        borderBottom: `1px solid #000084`, // Underline color on hover
                      },
                      "&.Mui-focused": {
                        borderBottom: `1px solid #000084`, // Underline color when focused
                      },
                    },
                  }}
                  // InputLabelProps={{
                  //   sx: {
                  //     "&.Mui-focused": {
                  //       color: "#000084", // Label color when focused
                  //     },
                  //   },
                  // }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10%",
                }}
              ></div>
              <Grid
                item
                xs={12}
                style={{
                  //   border: "solid 2px green",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6rem",
                }}
              >
                <Button
                  component="span"
                  className={disabled ? "login-disabled" : "loginButton"}
                  onClick={() => {
                    sendOtp();
                  }}
                  style={{ textTransform: "capitalize" }}
                  // disabled={confirmPasswordHelperText !== "Password match"}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (
        <form
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // Prevent default form submission
              updatePassword(); // Call your login function here
              console.log("hello");
            }
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              //   border: "solid 2px pink",
              display: "flex",
              flexDirection: "column",
              height: "350px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  variant="standard"
                  label="Enter OTP"
                  InputLabelProps={{
                    style: { fontWeight: "800" },
                    sx: {
                      "&.Mui-focused": {
                        color: "#000084", // Label color when focused
                      },
                    },
                  }}
                  InputProps={{
                    disableUnderline: true, // Disable the default underline
                    sx: {
                      "&:hover": {
                        borderBottom: `1px solid #000084`, // Underline color on hover
                      },
                      "&.Mui-focused": {
                        borderBottom: `1px solid #000084`, // Underline color when focused
                      },
                    },
                  }}
                  style={{
                    // border: "solid 2px red",
                    borderRadius: "0px",
                    width: "80%",
                  }}
                  type="text"
                  name="username"
                  value={otp}
                  onChange={handleOtp}
                  helperText={otpHelperText}
                  FormHelperTextProps={{ style: { color: "gray" } }}
                  error={otpHelperText == "Invalid Otp"}
                  // InputProps={{
                  //   readOnly: authCode.length !== 0,
                  // }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10%",
                }}
              ></div>
              <Grid
                item
                xs={12}
                style={{
                  //   border: "solid 2px green",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></Grid>
            </Grid>
            {
              <>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    id="outlined-controlled"
                    variant="standard"
                    label="Enter New Password"
                    style={{
                      // border: "solid 2px red",
                      borderRadius: "0px",
                      width: "80%",
                    }}
                    type={showPassword ? "password" : "text"}
                    value={newPassword}
                    onChange={(event) => {
                      validate(event.target.value);
                    }}
                    InputLabelProps={{
                      style: { fontWeight: "800" },
                      sx: {
                        "&.Mui-focused": {
                          color: "#000084", // Label color when focused
                        },
                      },
                    }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      disableUnderline: true, // Disable the default underline
                      sx: {
                        "&:hover": {
                          borderBottom: `1px solid #000084`, // Underline color on hover
                        },
                        "&.Mui-focused": {
                          borderBottom: `1px solid #000084`, // Underline color when focused
                        },
                      },
                    }}
                    helperText={newPassword ? passwordHelperText : ""}
                    FormHelperTextProps={{ style: { color: "gray" } }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    variant="standard"
                    id="outlined-controlled2"
                    label="Confirm New Password"
                    type="password"
                    style={{
                      // border: "solid 2px red",
                      borderRadius: "0px",
                      width: "80%",
                    }}
                    InputLabelProps={{
                      style: { fontWeight: "800" },
                      sx: {
                        "&.Mui-focused": {
                          color: "#000084", // Label color when focused
                        },
                      },
                    }}
                    value={confirmNewPassword}
                    onChange={(event) => {
                      checkConfirmPassword(event.target.value);
                    }}
                    helperText={
                      confirmNewPassword.length > 0
                        ? confirmPasswordHelperText
                        : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        color: `${
                          confirmPasswordHelperText == "Password match"
                            ? "grey"
                            : "red"
                        }`,
                      },
                    }}
                    InputProps={{
                      disableUnderline: true, // Disable the default underline
                      sx: {
                        "&:hover": {
                          borderBottom: `1px solid #000084`, // Underline color on hover
                        },
                        "&.Mui-focused": {
                          borderBottom: `1px solid #000084`, // Underline color when focused
                        },
                      },
                    }}
                    // error={confirmPasswordHelperText !== "Password match"}
                  />
                </Grid>
              </>
            }
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              //   border: "solid 2px green",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              // disabled={confirmPasswordHelperText !== "Password match" || passwordHelperText!== "Strong Password"}
              component="span"
              className={
                confirmPasswordHelperText !== "Password match" ||
                passwordHelperText !== "Strong Password" ||
                disabled ||
                !otp
                  ? "login-disabled"
                  : "loginButton"
              }
              style={{ textTransform: "capitalize" }}
              onClick={updatePassword}
            >
              Proceed
            </Button>
          </Grid>
        </form>
      )}
      {/* { multiple pan modal} */}
      <Modal
        open={openPan}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backdropFilter: "blur(10px)",
          zIndex: 100000,
        }}
      >
        <>
          <Grid item sx={style2}>
            <Grid item className="header">
              <Grid
                item
                xs={11}
                className="title"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
              </Grid>
              <Grid
                item
                xs={1}
                onClick={handleClosePan}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <CloseSVG />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                //   border: "solid 2px yellow",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Confirm PAN
            </Grid>

            <>
              <Grid
                item
                xs={12}
                style={{
                  //   border: "solid 2px pink",
                  display: "flex",
                  flexDirection: "column",
                  height: "70%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "2.5rem",
                    }}
                  >
                    <TextField
                      variant="standard"
                      label="Enter PAN"
                      InputLabelProps={{
                        style: { fontWeight: "800" },
                      }}
                      style={{
                        // border: "solid 2px red",
                        borderRadius: "0px",
                        width: "80%",
                      }}
                      type="text"
                      name="Pan"
                      value={pan}
                      onChange={handlePan}
                      helperText={panHelperText}
                      FormHelperTextProps={{ style: { color: "gray" } }}
                    />
                  </div>
                  <Grid
                    item
                    xs={12}
                    style={{
                      //   border: "solid 2px green",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  //   border: "solid 2px green",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  component="span"
                  className={"loginButton"}
                  style={{ textTransform: "capitalize" }}
                  onClick={(e) => {
                    sendOtp(true);
                  }}
                >
                  Proceed
                </Button>
              </Grid>
            </>
          </Grid>
        </>
      </Modal>
    </Grid>
  );
}

export default CreateForgotPassword;
