import { Card, CardContent, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import styles from "../../assets/css/UpdatesAndAnnouncements.module.css";
import UAModal from "./UAModal";

function UACard(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // let loaderDownload = false;

  // useEffect(() => {
  //   //console.log("card ka unseen number ->", props.uaData[props.nameForApi]);
  // }, [props.uaData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={`${styles.bubbleBox} ${styles.card}`}>
          <Grid item onClick={handleOpen}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {props.uaData[props.nameForApi] &&
              props.uaData[props.nameForApi]["unSeenNumber"] !== 0 ? (
                <span className={styles.dot}>
                  {props.uaData[props.nameForApi]["unSeenNumber"]}
                </span>
              ) : (
                <Grid
                  style={{
                    height: "30px",
                    width: "30px",
                    ...props.cardStyle
                  }}
                ></Grid>
              )}
            </Grid>
            <Grid item xs={12} className={styles.cardTitleCss} style={props.cardStyle}>
              {props.cardTitle}
            </Grid>
          </Grid>
          <Modal
            open={open}
            // onClose={loaderDownload ? handleClose : ""}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ backdropFilter: "blur(10px)" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "800px",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 3,
              }}
            >
              <UAModal
                detailHeader={props.cardTitle}
                handleClose={handleClose}
                nameForApi={props.nameForApi}
              />
            </Grid>
          </Modal>
        </Card>
      </Grid>
    </Grid>
  );
}

const mapStatetoProps = (state) => {
  return {
    uaData: state.mainReducer.uaData,
  };
};

export default connect(mapStatetoProps)(UACard);
