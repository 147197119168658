import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import ClientReportsDistributor from "./ClientReportsDistributor";
import { NavLink, useNavigate } from "react-router-dom";
import { handleAPI } from "../../API/handleAPI";
import { connect } from "react-redux";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import ReportsDownloadTable from "./ReportsDownloadTable";
import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";
import { COLOR_CODE } from "../../variable";

function ReportsDownload(props) {
  //console.log("props in reports download.js", props);

  const [fullClientReportsData, setFullClientReportsData] = useState({});
  const [investorDetails, setInvestorDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [clientReportsAvailable, setClientReportsAvailable] = useState(false);
  const [financialYearList, setFinancialYearList] = useState({});
  const [financialYearValue, setFinancialYearValue] = useState();
  const [schemeFolioList, setSchemeFolioList] = useState({});
  const [schemeFolioValue, setSchemeFolioValue] = useState({});
  const [clientReports, setClientReports] = useState([]);
  const [tableData, setTableData] = useState({}); //
  const [rows, setRows] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.userDetails?.accountType.includes("DIS")) {
      if (props.userDetails.pan) {
        handleAPI(
          "/distributor/getClientReportListDetails",
          {
            distributorPan: props.userDetails.pan,
            investorPan: props.clientName.PAN,
          },
          props.token
        )
          .then((response) => {
            //console.log(
            //   "fullClientReportsData",
            //   response.data.data.clientReports.length
            // );
            setInvestorDetails(response.data.data.userDetails);
            if (Object.keys(response.data.data.clientReports).length) {
              // throw {
              //   error: true,
              //   errorMessage: "No client reports data to show",
              // };
              let tempFullData = response.data.data.clientReports;
              let tempYear = Object.keys(tempFullData)[0];
              let tempScheme = Object.keys(tempFullData[tempYear])[0];
              setFullClientReportsData(response.data.data.clientReports);

              setFinancialYearList(
                Object.keys(response.data.data.clientReports)
              );
              setFinancialYearValue(tempYear);
              setSchemeFolioList(Object.keys(tempFullData[tempYear]));
              setSchemeFolioValue(tempScheme);
              setTableData(tempFullData[tempYear][tempScheme]);
              setRows(tempFullData[tempYear][tempScheme]);
              setClientReportsAvailable(true);
            }
            setLoader(true);
          })
          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");
            setLoader(true);
          });
      }
    } else if (props.userDetails?.accountType.includes("ADM")) {
      handleAPI(
        "/admin/getClientReportListDetails",
        { pan: props.clientName.PAN },
        props.token
      )
        .then((response) => {
          //console.log(
          //   "full admin ClientReports Data",
          //   response.data.data.clientReports.length
          // );
          setInvestorDetails(response.data.data.userDetails);
          if (Object.keys(response.data.data.clientReports).length) {
            // throw {
            //   error: true,
            //   errorMessage: "No client reports data to show",
            // };
            let tempFullData = response.data.data.clientReports;
            let tempYear = Object.keys(tempFullData)[0];
            let tempScheme = Object.keys(tempFullData[tempYear])[0];
            setFullClientReportsData(response.data.data.clientReports);

            setFinancialYearList(Object.keys(response.data.data.clientReports));
            setFinancialYearValue(tempYear);
            setSchemeFolioList(Object.keys(tempFullData[tempYear]));
            setSchemeFolioValue(tempScheme);
            setTableData(tempFullData[tempYear][tempScheme]);
            setRows(tempFullData[tempYear][tempScheme]);
            setClientReportsAvailable(true);
          }
          setLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          setLoader(true);
        });
    }
  }, []);

  useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  // When the FY dropdown changes
  const handleFinancialYearChange = (event) => {
    setFinancialYearValue(event.target.value);
  };

  // When the FY dropdown changes ... FY Value selected
  useEffect(() => {
    if (clientReportsAvailable === true) {
      setSchemeFolioList(
        Object.keys(fullClientReportsData[financialYearValue])
      );
      setSchemeFolioValue(
        Object.keys(fullClientReportsData[financialYearValue])[0]
      );
      let temp1 = fullClientReportsData[financialYearValue];
      let tempObj = Object.keys(fullClientReportsData[financialYearValue]);
      // //console.log("first", temp1[tempObj[0]]);

      setTableData(temp1[tempObj[0]]);
    }
  }, [financialYearValue]);

  //When scheme folio dropdown changes
  const handleSchemeFolioChange = (event) => {
    setSchemeFolioValue(event.target.value);

    // //console.log("full data", fullClientReportsData);
    // //console.log("FY val", financialYearValue);
    // //console.log("sch val", schemeFolioValue);
    setTableData(fullClientReportsData[financialYearValue][event.target.value]);
  };

  const ReportsDownloadHeadCells = [
    {
      field: "reportName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Report",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 528,
    },
    {
      field: "frequency",
      numeric: true,
      disablePadding: false,
      headerName: "Frequency",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "lastUpdated",
      numeric: true,
      disablePadding: false,
      headerName: "Last Updated",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 140,
    },
    {
      field: "downloadLink",
      numeric: true,
      disablePadding: false,
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          window.open(params.formattedValue, "_self");
        };

        return (
          <Button onClick={onClick}>
            <DownloadReport />
          </Button>
        );
      },
    },
  ];
  return (
    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
      <Grid
        container
        style={{
          marginTop: "20px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* BackButton and Name */}
          <Grid item xs={5} style={{ display: "flex", flexDirection: "row" }}>
            {/* Back Button */}
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  props.setShowReportsPage(false);
                }}
              >
                <Back />
              </Button>
            </Grid>
            {/* Reports available for Investor Name */}
            <Grid item xs={10}>
              <Grid
                item
                style={{
                  color: "grey",
                  fontSize: "12px",
                  // justifyContent: "center",
                  // alignItems: "center",
                  // marginTop: "10px",
                  // display: "flex",
                }}
              >
                Reports available for
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  fontWeight: "bold",
                  wordWrap: "break-word",
                  fontSize: "14px",
                }}
              >
                {props.clientName.label}
              </Grid>
            </Grid>
          </Grid>

          {loader ? (
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* Pan */}
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{ color: "grey", fontSize: "12px" }}
                  >
                    PAN
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: "rgb(32, 87, 166)",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {props.clientName.PAN}
                  </Grid>
                </Grid>
              </Grid>

              {/* Contact */}
              <Grid
                item
                xs={3}
                style={{
                  borderLeft: "solid 2px lightgrey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    style={{ color: "grey", fontSize: "12px" }}
                  >
                    Phone No.
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: "rgb(32, 87, 166)",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {investorDetails.contact}
                  </Grid>
                </Grid>
              </Grid>

              {/* Email id */}
              <Grid
                item
                xs={6}
                style={{
                  borderLeft: "solid 2px lightgrey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "left",
                  flexDirection: "column",
                  padding: "0rem 0.5rem 0rem 2rem",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ color: "grey", fontSize: "12px" }}
                  >
                    Email ID
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: "rgb(32, 87, 166)",
                      fontWeight: "bold",
                      wordWrap: "break-word",
                      fontSize: "14px",
                    }}
                  >
                    {investorDetails.emailId}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {loader ? (
          clientReportsAvailable ? (
            <Grid item xs={12}>
              <Card
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "930px",
                }}
              >
                {/* Dropdowns */}
                <CardContent>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {" "}
                    <Grid item xs={3} style={{ fontFamily: "Lato" }}>
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "90%",
                          marginRight: "3rem",
                          fontFamily: "Lato",
                        }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Financial Year
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={financialYearValue}
                          onChange={handleFinancialYearChange}
                          label="Age"
                          style={{
                            fontFamily: "Lato",
                            fontSize: "14px",
                          }}
                          MenuProps={{
                            MenuListProps: {
                              style: {
                                maxHeight: "45vh",
                                overflowY: "auto",
                              },
                            },
                          }}
                        >
                          {financialYearList
                            .sort()
                            .reverse()
                            .map((yearName, id) => {
                              return (
                                <MenuItem key={id} value={yearName}>
                                  {yearName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "90%",
                          marginRight: "3rem",
                        }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Scheme Name - Folio Number
                        </InputLabel>
                        {/* {//console.log("scheme folio value", schemeFolioList)} */}
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={schemeFolioValue}
                          onChange={handleSchemeFolioChange}
                          label="Age"
                          style={{
                            fontFamily: "Lato",
                            fontSize: "14px",
                          }}
                          MenuProps={{
                            MenuListProps: {
                              style: {
                                maxHeight: "45vh",
                                overflowY: "auto",
                              },
                            },
                          }}
                        >
                          {schemeFolioList.map((schemeName, id) => {
                            return (
                              <MenuItem key={id} value={schemeName}>
                                {schemeName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>

                {/* Table */}
                <CardContent>
                  <Box
                    sx={{
                      height: 337,
                      width: "100%",
                      "& .tableModal--Header": {
                        backgroundColor: COLOR_CODE.TABLE_HEADER,
                        color: "white",
                        fontFamily: "Lato",
                      },
                    }}
                  >
                    {/* <DataGrid
                    style={{ fontFamily: "Lato" }}
                    rows={[]}
                    columns={ReportsDownloadHeadCells}
                    pageSize={4}
                    rowsPerPageOptions={[4]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  /> */}
                    <ReportsDownloadTable
                      headCells={ReportsDownloadHeadCells}
                      data={rows}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              {" "}
              Sorry no reports are available for selected investor!{" "}
            </Grid>
          )
        ) : (
          <>
            <Loader />
          </>
        )}
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ReportsDownload);
