import "./App.css";
import { connect } from "react-redux";
import RouterDefaultPage from "./Routes/RouterDefaultPage";
import Loader from "./views/newLoader";
import { COLOR_CODE } from "./variable";
import { useEffect } from "react";

function App(props) {
  const insertCssVariables = () => {
    document.documentElement.style.setProperty(
      "--leftnavcolor",
      COLOR_CODE.LEFT_NAV
    );
    document.documentElement.style.setProperty(
      "--leftnavbtncolorbg",
      COLOR_CODE.LEFT_NAV_BUTTON_BG
    );
    document.documentElement.style.setProperty(
      "--leftnavbtncolor",
      COLOR_CODE.LEFT_NAV_BUTTON
    );
    document.documentElement.style.setProperty(
      "--themecolor",
      COLOR_CODE.THEME_ELEMENT_COLOR
    ); // color for table header , and replace ment of blue(default)

    // Add more variables as needed
  };

  // Call the function in your component, such as in componentDidMount or useEffect
  useEffect(() => {
    insertCssVariables();
  }, []);
  console.log(
    "SAAS ENCRYPTION ",
    process.env.REACT_APP_SAAS_ENCRYPTION?.replace(/\s/g, "") == "true"
  );
  console.log(
    "SECURE LS ENCRYPT ",
    process.env.REACT_APP_SECURE_LS_ENCRYPT?.replace(/\s/g, "") == "true"
  );

  return (
    <>
      <div className="mobileScreen">
        We recommend you to access the website on a desktop/laptop browser!
      </div>
      {props.showLoader && <Loader />}
      <div
        style={{
          fontFamily: "Lato",
        }}
        className="appDiv"
      >
        <RouterDefaultPage />
      </div>
    </>
  );
}
const mapStatetoProps = (state) => {
  return {
    showLoader: state.mainReducer.showLoader,
  };
};

export default connect(mapStatetoProps)(App);
