import React from "react";
import Cat2Investments from "./Cat2Investments";
import Cat3Investments from "./Cat3Investments";
import styles from "../../../assets/css/Cat23.module.css";

function InvestmentDoughnut(props) {
  //console.log("DOUGHNUT DATA", props.doughnutData.category2Data);
  //console.log("checking dd", props.doughnutData.categoryWiseTotal.category2);
  return (
    <>
      {props.flag ? (
        props?.doughnutData?.category3Data?.length == 0 ? (
          <div
            style={{
              height: "326px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={styles.noInvestmentFirstLineCss}>OOPS!</div>
            <div className={styles.noInvestmentSecondLineCss}>
              You do not have any investments here
            </div>
          </div>
        ) : (
          <Cat3Investments
            cat3Data={props?.doughnutData?.category3Data}
            total={props?.doughnutData?.categoryWiseTotal?.category3}
          />
        )
      ) : props?.doughnutData?.category2Data?.length == 0 ? (
        <div
          style={{
            height: "326px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.noInvestmentFirstLineCss}>OOPS!</div>
          <div className={styles.noInvestmentSecondLineCss}>
            You do not have any investments here
          </div>
        </div>
      ) : (
        <Cat2Investments
          cat2Data={props?.doughnutData?.category2Data}
          total={props?.doughnutData?.categoryWiseTotal?.category2}
        />
      )}
    </>
  );
}

export default InvestmentDoughnut;
