import React from "react";
import Grid from "@mui/material/Grid";
import PieChartDistributor from "./PieChartDistributor";
import TopClientsTable from "./TopClientsTable";
import styles from "../../../assets/css/GraphsDistributor.module.css";

function AumTopClients(props) {
  return (
    <Grid container style={{ display: "flex", flexDirection: "row" }}>
      <Grid
        xs={12}
        item
        style={{
          marginTop: "1rem",
          height: "10.5rem",
          width: "auto",
          display: "flex",
          justifyContent: "center",
        }}
        className={styles.pieGrid__col1}
      >
        <PieChartDistributor aumWiseData={props.aumWiseData} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ paddingTop: "1.5rem", marginLeft: "0.5rem", height: "126px" }}
        // style={{
        //   flexDirection: "column",
        //   justifyContent: "space-between",
        //   display: "flex",
        // }}
      >
        {/* <Grid item xs={12}> */}
        <TopClientsTable data={props.aumWiseData} />
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
}

export default AumTopClients;
