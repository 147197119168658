import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { handleAPI } from "../../API/handleAPI";
// import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import { ReactComponent as BlueSearchIcon } from "../../assets/svg/BlueSearchIcon.svg";
import { ReactComponent as SearchIconYellowBox } from "../../assets/svg/SearchIconYellowBox.svg";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import styles from "../../assets/css/ClientReports.module.css";
import ReportsDownload from "./ReportsDownload";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";


function ClientReportsDistributor(props) {
  const [searchData, setSearchData] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getClientReportSearchResults",
        {
          pan: props.userDetails.pan,
          // pan: "AAACU2414K",
        },
        props.token
      )
        .then((response) => {
          setSearchData(response.data);
          //console.log("SEARCH BAR", response.data);
          setSearchLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setSearchLoader(true);
         
        });
    }
  }, [props.userDetails.pan]);

  useEffect(() => {
    props.setPage("CLID");
  }, []);

  let options = [];
  if (searchLoader) {
    searchData?.data?.map((item, index) => {
      let temp = {
        value: `${item.label} ${item.PAN}`,
        label: item.label,
        PAN: item.PAN,
      };
      options.push(temp);
    });
  }

  const formatOptionLabel = ({ value, label, PAN }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 30px 0px 30px",
      }}
    >
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#808080" }}>{PAN}</div>
    </div>
  );
  const [showReportsPage, setShowReportsPage] = useState(false);
  const [clientName, setClientName] = useState();

  const handleChange = (event) => {
    //console.log("iska value hai ye ---->", event.value);
    if (event.value) {
      setClientName(event);
      setShowReportsPage(true);
    }
  };
  //console.log("EVENT", clientName);
  return (
    <>
      {showReportsPage ? (
        <ReportsDownload
          clientName={clientName}
          setShowReportsPage={setShowReportsPage}
        />
      ) : searchLoader ? (
        <Grid
          container
          style={{
            // border: "solid 2px red",
            marginTop: "1.3rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Text */}
          <Grid item style={{ fontWeight: "bolder" }}>
            Search the Investor, we'll fetch the available reports.
          </Grid>

          {/* Search DropDown */}

          <Grid
            item
            style={{
              marginTop: "3rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              //   backgroundColor: "#FED561",
              //   border: "solid 2px green",
              //   height: "100%",
            }}
          >
            <Grid
              item
              style={{
                // border: "solid 2px red",
                backgroundColor: "#FED561",
                height: "2.5rem",
                width: "2.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BlueSearchIcon />
            </Grid>
            <Grid
              item
              style={{
                width: "35rem",
              }}
            >
              <Select
                // defaultValue={{ label: "Select Dept", value: 0 }}
                defaultValue={null}
                formatOptionLabel={formatOptionLabel}
                options={options}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: "45vh",
                      overflowY: "auto",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(ClientReportsDistributor);
