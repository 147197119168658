import { Card, CardContent, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { handleAPI } from "../../API/handleAPI";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import BrokerageCard from "../Distributor/BrokerageCard";
import styles from "../../assets/css/Brokerage.module.css";
import Loader from "../../views/Loader";

function Brokerage(props) {
  const navigate = useNavigate();
  let [loaderBrokerage, setloaderBrokerage] = useState(false);
  useEffect(() => {
    props.setPage("Brokerage");
  }, []);

  return (
    <>
      <div>
        {!loaderBrokerage ? (
          <Grid container style={{ padding: "20px 10px 0px 20px" }}>
            <Grid
              item
              xs={12}
              style={{
                fontSize: "18px",
                fontWeight: "bolder",
                paddingLeft: "17px",
              }}
            >
              Brokerage
            </Grid>
            {/* Cards */}
            <Grid item xs={12}>
              {/* row1 */}
              <Grid item xs={12} className={styles.row1}>
                {/* Brokerage Statement card */}
                <Grid item xs={4} className={styles.singleCard}>
                  <BrokerageCard
                    cardStyle={{ color: "#2057a6", backgroundColor: "#ffffff" }}
                    cardTitle="Brokerage Statement"
                    nameForApi="Brokerage Statement"
                  />
                </Grid>

                {/* Tax Invoice card */}
                <Grid item xs={4} className={styles.singleCard}>
                  <BrokerageCard
                    cardStyle={{ color: "#2057a6", backgroundColor: "#ffffff" }}
                    cardTitle="Tax Invoice"
                    nameForApi="Tax Invoice"
                  />
                </Grid>

                {/* Rate Structure */}
                <Grid item xs={4} className={styles.singleCard}>
                  <BrokerageCard
                    cardStyle={{ color: "#2057a6", backgroundColor: "#ffffff" }}
                    cardTitle="Rate Structure"
                    nameForApi="Rate Structure"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Brokerage);
