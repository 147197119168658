import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";
import TableModal from "../TableModal";
import UATableModal from "./UATableModal";
import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { GridDensityTypes } from "@mui/x-data-grid";
import { handleAPI } from "../../API/handleAPI";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import styles from "../../assets/css/UpdatesAndAnnouncements.module.css";
import { useNavigate } from "react-router-dom";
import ModalLoader from "../../views/modalLoader";
import Swal from "sweetalert2";


function UAModal(props) {
  // const [loaderUaData, setLoaderUaData] = useState(true);
  // const [loaderUnread, setLoaderUnread] = useState(true);
  const [loaderDownload, setLoaderDownload] = useState(true);
  const [searchString, setSearchString] = useState("");

  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.uaData[props.nameForApi])
      setRows(props.uaData[props.nameForApi]["filesAvailable"]);
  }, [props.uaData]);

  function downloadApi(params) {
    // function called when download button is clicked
    if (props.userDetails.pan) {
      // setLoaderUaData(false);
      // setLoaderUnread(false);
      setLoaderDownload(false);

      // outermost api called -> set downlaod link
      if (props.userDetails?.accountType.includes("INV")) {
        handleAPI(
          "/investor/downloadUpdateAnnouncementFiles",
          {
            pan: props.userDetails.pan,
            uniqueId: params.row.uniqueId,
          },
          props.token
        )
          .then((response) => {
            //console.log("download link succesful -> ", response.data);

            // alreadySeen -> true ... dont call api ...else -> if false , then call api
            if (!response.data.alreadySeen) {
              console.log("not seen before , so calling api");
              // downlink succesfull -> call api for uaData
              handleAPI(
                "/investor/getUpdateAnnouncementReports",
                {
                  pan: props.userDetails.pan,
                },
                props.token
              )
                .then((response) => {
                  // //console.log(
                  //   "getUpdateAnnouncementReports data  callewd inside UAModal----",
                  //   response.data
                  // );
                  props.setUaData(response.data.reports);
                  props.setUnread(response.data.totalUnseenStatus);

                  // // set uadata loader true
                  // setLoaderUnread(true);
                  // setLoaderUaData(true);
                })
                .catch((error) => {
                  console.log(`Error Message:  ${error.errorMessage}`);
                  console.log("Redirecting to error");
                 
                });
            }

            // download and then set loader download true
            window.open(response.data["signedUrl"], "_self");
            setLoaderDownload(true);
          })
          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");
           
            setLoaderDownload(true);
          });
      } else if (props.userDetails?.accountType.includes("DIS")) {
        handleAPI(
          "/distributor/downloadUpdateAnnouncementFiles",
          {
            pan: props.userDetails.pan,
            uniqueId: params.row.uniqueId,
          },
          props.token
        )
          .then((response) => {
            //console.log("download link succesful -> ", response.data);

            // alreadySeen -> true ... dont call api ...else -> if false , then call api
            if (!response.data.alreadySeen) {
              console.log("not seen before , so calling api");
              // downlink succesfull -> call api for uaData
              handleAPI(
                "/distributor/getUpdateAnnouncementReports",
                {
                  pan: props.userDetails.pan,
                },
                props.token
              )
                .then((response) => {
                  //console.log(
                  //   "getUpdateAnnouncementReports data  callewd inside UAModal----",
                  //   response.data
                  // );
                  // props.setUaData(response.data.reports);
                  props.setUnread(response.data.totalUnseenStatus);

                  // // set uadata loader true
                  // setLoaderUnread(true);
                  // setLoaderUaData(true);
                })
                .catch((error) => {
                  console.log(`Error Message:  ${error.errorMessage}`);
                  console.log("Redirecting to error");
                 
                });
            }

            // download and then set loader download true
            window.open(response.data["signedUrl"], "_self");
            setLoaderDownload(true);
          })
          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");
           
            setLoaderDownload(true);
          });
      }
    }
  }

  const handleChange = (event) => {
    setSearchString(event.target.value);
    let filteredRows = props.uaData[props.nameForApi]["filesAvailable"].filter(
      (row) => {
        let tempObj = {
          reportName: row.reportName,
          uploadedOn: row.uploadedOn,
        };
        let temp = JSON.stringify(Object.values(tempObj));
        temp = temp.toLowerCase();
        //console.log("temp", temp);
        return temp.includes(event.target.value.toLowerCase());
      }
    );

    setRows(filteredRows);
  };

  const headCells = [
    {
      field: "reportName",
      numeric: true,
      disablePadding: false,
      headerName: props.detailHeader,
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 550,
      renderCell: (params) => {
        // return <Grid>1234</Grid>;

        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              // width: "550",
              // height: "auto",
            }}
          >
            <Grid
              item
              style={{
                // margin: "1rem 1rem 1rem 1rem",
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              {params.row.reportName}
            </Grid>
            {params.row.seen === false ? (
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "#FFF4D4",
                    fontSize: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                  }}
                >
                  Unread
                </Grid>
              </Grid>
            ) : (
              <Grid item> </Grid>
            )}
          </Grid>
        );
      },
    },
    {
      field: "uploadedOn",
      numeric: true,
      disablePadding: false,
      headerName: "Updated On",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 140,
    },
    {
      field: "downloadLink",
      numeric: true,
      disablePadding: false,
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 99,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          //console.log(" onclick params", params);
          downloadApi(params);
        };

        return (
          <Button onClick={onClick}>
            <DownloadReport />
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Grid item>
        {loaderDownload ? (
          //  && loaderUaData && loaderUnread
          <Grid container style={{ padding: "5px" }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "700",
              }}
            >
              <Grid item xs={5}>
                {props.detailHeader}
              </Grid>
              {props.uaData[props.nameForApi] ? (
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "2rem",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px black",
                      borderRadius: "30px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      type="search"
                      variant="outlined"
                      placeholder="Search"
                      onChange={handleChange}
                      value={searchString}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        ".MuiInputBase-input": {
                          borderBottom: "none !important",
                          padding: "5px 0 0 10px !important",
                          /*margin: -12px ;  */
                        },
                        paddingBottom: "5rem",
                      }}
                    />
                  </div>
                </Grid>
              ) : (
                <Grid item xs={6}></Grid>
              )}
              <Grid
                item
                xs={1}
                onClick={props.handleClose}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <CloseSVG />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              {props.uaData[props.nameForApi] ? (
                <UATableModal
                  name={props.name}
                  headCells={headCells}
                  data={rows}
                  // data={props.uaData[props.nameForApi]["filesAvailable"]}
                />
              ) : (
                <div
                  style={{
                    height: "337px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.noInvestmentSecondLineCss}>
                    No {props.nameForApi} data to show
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            style={{
              height: "388px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <LoadingBlack /> */}
            <ModalLoader />
          </Grid>
        )}
      </Grid>
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    uaData: state.mainReducer.uaData,
    unread: state.mainReducer.unread,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUaData: (val) => {
      dispatch({ type: "CHANGE-UADATA", uaData: val });
    },
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(UAModal);
