// STEP 1 - Include Dependencies
import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
import { connect } from "react-redux";
import { AMC_DETAILS } from "../../variable";

function PieChart(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  let colors = [
    "#89C4F4",
    "#BBBBBB",
    "#337FC9",
    "#9AECF3",
    "#717171",
    "#36DAE9",
    "#333333",
    "#4492A8",
    "#66BCFF",
    "#7094C6",
    "#8ED8B7",
  ];

  let data = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  if (props.portfolioBox.portfolioView.length > 0) {
    Object.keys(props.portfolioBox.portfolioView).forEach((key, index) => {
      data.labels.push(props.portfolioBox.portfolioView[key].fundType);
      data.datasets[0].data.push(
        props.portfolioBox.portfolioView[key][
          AMC_DETAILS.INVESTMENT_TYPE == "openEnded"
            ? "totalContribution"
            : "totalCommitment"
        ]
      );
    });
  }
  // console.log(props);
  const totalSum = parseInt(
    props.portfolioBox.portfolioViewTotal.replace(/,/g, "")
  );

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = parseInt(context.formattedValue.replace(/,/g, ""));
            return `${label}: ${(value / totalSum) * 100}%`;
          },
        },
      },
    },
    onClick: (event, elements, chart) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const fundType = {
          name: data.labels[index],
          color: elements[0]?.element?.options?.backgroundColor,
        };
        // console.log(fundType);
        props.setFundType(fundType);
      }
    },
    radius: "90%",
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        right: 0,
        bottom: 10,
        left: 0,
      },
    },
  };

  return <Pie data={data} options={options} />;
}

const mapStatetoProps = (state) => {
  return {
    fundType: state.mainReducer.fundType,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setFundType: (val) => {
      dispatch({ type: "CHANGE-FUND-TYPE", fundType: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(PieChart);
