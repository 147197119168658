import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { handleAPI } from "../../API/handleAPI";
import styles from "../../assets/css/RecentTransaction.module.css";
import { connect } from "react-redux";
import RecentTransactionTable from "../RecentTransactionTable";
import { ReactComponent as DownloadReport } from "../../assets/svg/DownloadReport.svg";
import FormsAndDownloadTable from "./FormsAndDownloadTable";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";


function FormsAndDownloadDistributor(props) {
  const [searchString, setSearchString] = useState("");
  const [valueSelected, setValueSelected] = useState("All");
  const [dataAll, setDataAll] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [distributorRelated, setDistributorRelated] = useState([]);
  const [serviceRequest, setServiceRequest] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  let FormsCols = [
    {
      field: "fileName",
      numeric: true,
      disablePadding: false,
      headerName: "Service Request Forms",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 650,
    },

    {
      field: "signedUrl",
      numeric: true,
      disablePadding: false,
      headerName: "Download",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 250,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          //console.log("first params", params);
          window.open(params.formattedValue, "_self");
        };

        return (
          <Button onClick={onClick}>
            <DownloadReport />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    //console.log("Entered Forms and Download Distributor use effect");
    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getFormsAndDownloadFiles",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("dist forms download", response.data);
          setTableData(response.data);
          setDataAll(response.data);
          // setDistributorRelated(
          //   response.data.recentTransactionData.Contribution
          // );
          // setServiceRequest(response.data.recentTransactionData.Redemption);
          // setRows(response.data.recentTransactionData.All);

          setLoader(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoader(true);

         
        });
    }
  }, [props.userDetails.pan]);

  useEffect(() => {
    props.setPage("FormsAndDownloadDistributor");
  }, []);

  const handleChange = (event) => {
    setSearchString(event.target.value);

    let filteredRows = tableData.filter((row) => {
      let obj2 = { fileName: row.fileName };
      let temp = JSON.stringify(Object.values(obj2));
      temp = temp.toLowerCase();
      return temp.includes(event.target.value.toLowerCase());
    });

    setDataAll(filteredRows);
  };

  return (
    <div style={{ marginTop: "20px", marginLeft: "30px" }}>
      {loader ? (
        <Grid container>
          <Grid item xs={12}>
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "solid 2px red",
                // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
                width: "940px",
              }}
              // className={styles.card}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",

                  width: "940px",
                  marginBottom: "15px",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    // padding: " 1rem 0 0.25rem 0.5rem",
                    fontSize: "18px",
                    fontWeight: "bolder",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "auto",
                    marginLeft: "40px",
                  }}
                >
                  Standard Forms
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "2rem",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px black",
                      borderRadius: "30px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Search"
                      type="search"
                      onChange={handleChange}
                      value={searchString}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        ".MuiInputBase-input": {
                          borderBottom: "none !important",
                          padding: "5px 0 0 10px !important",
                          /*margin: -12px ;  */
                        },
                        paddingBottom: "5rem",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {/* <Grid
                  item
                  xs={8}
                  className={styles.radioButtonCss}
                  sx={{ fontFamily: "Lato" }}
                >
                  <FormControl sx={{ fontFamily: "Lato" }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={valueSelected}
                      onChange={handleChange}
                      sx={{ fontFamily: "Lato" }}
                      row
                    >
                      <FormControlLabel
                        value="All"
                        control={<Radio />}
                        sx={{ fontFamily: "Lato" }}
                        label="Distributor Related"
                      />
                      <FormControlLabel
                        value="Contribution"
                        control={<Radio />}
                        label="Service Request Form"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}
              <Grid item xs={12}>
                <FormsAndDownloadTable
                  name="Standard Forms"
                  data={dataAll}
                  headCells={FormsCols}
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(FormsAndDownloadDistributor);
