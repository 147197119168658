import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Card, Tooltip } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import parseUrl from "../../utils/parseURL";
import { useState, useEffect } from "react";
import { handleAPI } from "../../API/handleAPI";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import CardComponent from "../CardComponent";
import GraphsDistributor from "./DistributorGraphs/GraphsDistributor";
import ActiveClients from "./ActiveClients";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import Loader from "../../views/Loader";
import { AMC_DETAILS } from "../../variable";
import style from "../../assets/css/CardComponent.module.css";

function Distributor(props) {
  useEffect(() => {
    props.setShowTLNav(true);
    props.setPage("DIS");
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function () {
    //   console.log("run on back button click");
    //   window.history.go(1);
    // };
  }, []);

  let [catBox, setCatBox] = useState({});
  let [topClientsData, setTopClientsData] = useState([]);
  let [investmentsData, setInvestmentsData] = useState([]);
  let [loaderNavDate, setLoaderNavDate] = useState(false);
  let [loaderTopClients, setLoaderTopClients] = useState(false);
  let [loaderInvesments, setLoaderInvestments] = useState(false);
  let [loaderBubbleBox, setLoaderBubbleBox] = useState(false);
  let [bubbleBox, setBubbleBox] = useState({
    ["Total Number of Investors"]: "",
    ["Total Commitment Raised"]: "",
    ["Total Contribution Raised"]: "",
    ["Income Distribution & Capital Repayment"]: "",
    ["Current Value"]: "",
    ["Uncalled Contribution"]: "",
  });
  // let [loaderUnread, setLoaderUnread] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.userDetails.pan) {
      handleAPI(
        "/distributor/getDashboardBubbleBox",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          setBubbleBox(response.data);
          setLoaderBubbleBox(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoaderBubbleBox(true);
        });
      handleAPI(
        "/distributor/getDashboardTopClients",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          setTopClientsData(response.data);
          setLoaderTopClients(true);
          //console.log("top clients table data", response.data);
          //console.log("top clients table data pan", props.userDetails.pan);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoaderTopClients(true);
        });
      handleAPI(
        "/distributor/getDashboardInvestment",
        {
          pan: props.userDetails.pan,
          // pan: "AAACU2414K",
        },
        props.token
      )
        .then((response) => {
          setInvestmentsData(response.data);
          setLoaderInvestments(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoaderInvestments(true);
        });
      handleAPI(
        "/distributor/getNavDate",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("NAV DATE RECENT----", response.data);

          props.setNavDate(response.data);
          setLoaderNavDate(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoaderNavDate(true);
        });

      handleAPI(
        "/distributor/getUpdateAnnouncementUnseenNumber",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("Unread data----", response.data.unseenFiles);
          props.setUnread(response.data.unseenFiles);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
        });
    }
  }, [props.userDetails.pan]);

  const ActiveClientsHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "center",
    },
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "totalCommitment",
      numeric: true,
      disablePadding: false,
      headerName: "Total Commitment",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
      align: "center",
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },

    // {
    //   field: "iRR",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "IRR",
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 120,
    //   align: "center",
    //   color: "gold",
    // },
  ];
  const InvestorHeadCells = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",

      headerClassName: "tableModal--Header",
      width: 290,
      align: "left",
    },
    {
      field: "mobileNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Mobile Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },

    {
      field: "emailID",
      numeric: true,
      disablePadding: false,
      headerName: "Email ID",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 280,
      align: "center",
    },
    {
      field: "pAN",
      numeric: true,
      disablePadding: false,
      headerName: "PAN",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
      align: "center",
    },
  ];
  const CommitmentHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
      align: "center",
    },
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
      // flex: "1",
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      // flex: "1",
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      align: "center",
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
      align: "center",
    },
  ];
  const ContributionHeadCells = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const ContributionHeadCellsOpenEnded = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const CurrentValueHeadCells = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
      align: "center",
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "center",
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      align: "center",
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
      align: "center",
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "unitsIssued",
      numeric: true,
      disablePadding: false,
      headerName: "Units Issued",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "currentNAV",
      numeric: true,
      disablePadding: false,
      headerName: "Current NAV",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];
  const CurrentValueHeadCellsOpenEnded = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
      align: "center",
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "center",
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      align: "center",
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "unitsIssued",
      numeric: true,
      disablePadding: false,
      headerName: "Units Issued",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "currentNAV",
      numeric: true,
      disablePadding: false,
      headerName: "Current NAV",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];
  const DistributionHeadCells = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
      align: "center",
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "center",
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      align: "center",
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
      align: "center",
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },

    {
      field: "incomeDistribution",
      numeric: true,
      disablePadding: false,
      headerName: "Income Distribution",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "capitalRepayment",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Repayment",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];
  const DistributionHeadCellsOpenEnded = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
      align: "center",
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "center",
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
      align: "center",
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "incomeDistribution",
      numeric: true,
      disablePadding: false,
      headerName: "Income Distribution",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "capitalRepayment",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Repayment",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];
  const UncalledContributionHeadCells = [
    {
      field: "clientName",
      numeric: true,
      disablePadding: false,
      headerName: "Client Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "tableModal--Header",
      width: 258,
    },
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 130,
    },

    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 100,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "uncalledContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Uncalled Contribution",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  return (
    <div>
      {loaderNavDate &&
      loaderInvesments &&
      loaderTopClients &&
      loaderBubbleBox ? (
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  paddingRight: "3%",
                  paddingLeft: "3%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    fontSize: "10px",
                    color: "#6d6e71",
                    // marginTop: "10px",
                  }}
                >
                  Updated as of NAV dated {props.navDate}
                </Grid>
                <Grid
                  item
                  xs={AMC_DETAILS.INVESTMENT_TYPE === "openEnded" ? 3 : 4}
                  className={
                    AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                      ? style.paddingOpenEnded
                      : style.paddingCloseEnded
                  }
                >
                  <CardComponent
                    cardTitle1="Total No."
                    cardTitle2="of Investors"
                    cardContent={bubbleBox["Total Number of Investors"]}
                    headCells={InvestorHeadCells}
                    // data={CommitmentData}
                    name="InvestorData"
                    detailHeader="Investor Details"
                  />
                </Grid>
                {AMC_DETAILS.INVESTMENT_TYPE !== "openEnded" && (
                  <Grid
                    item
                    xs={AMC_DETAILS.INVESTMENT_TYPE === "openEnded" ? 3 : 4}
                    className={
                      AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                        ? style.paddingOpenEnded
                        : style.paddingCloseEnded
                    }
                  >
                    <CardComponent
                      cardTitle1="Total Commitment"
                      cardTitle2=" Raised"
                      cardContent={bubbleBox["Total Commitment Raised"]}
                      headCells={CommitmentHeadCells}
                      // data={CommitmentData}
                      name="TotalCommitment"
                      detailHeader="Commitment Details"
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={AMC_DETAILS.INVESTMENT_TYPE === "openEnded" ? 3 : 4}
                  className={
                    AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                      ? style.paddingOpenEnded
                      : style.paddingCloseEnded
                  }
                >
                  <CardComponent
                    cardTitle1="Total Contribution"
                    cardTitle2=" Raised"
                    cardContent={bubbleBox["Total Contribution Raised"]}
                    headCells={
                      AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                        ? ContributionHeadCellsOpenEnded
                        : ContributionHeadCells
                    }
                    // data={CommitmentData}
                    name="TotalContribution"
                    detailHeader="Contribution Details"
                  />
                </Grid>
                <Grid
                  item
                  xs={AMC_DETAILS.INVESTMENT_TYPE === "openEnded" ? 3 : 4}
                  className={
                    AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                      ? style.paddingOpenEnded
                      : style.paddingCloseEnded
                  }
                >
                  <CardComponent
                    cardTitle1="Income Distribution"
                    cardTitle2=" & Capital Repayment"
                    cardContent={
                      bubbleBox["Income Distribution & Capital Repayment"]
                    }
                    headCells={
                      AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                        ? DistributionHeadCellsOpenEnded
                        : DistributionHeadCells
                    }
                    // data={CommitmentData}
                    name="IncomeDistributionAndCapitalRepayment"
                    detailHeader="Income Distribution & Capital Repayment"
                  />
                </Grid>
                <Grid
                  item
                  xs={AMC_DETAILS.INVESTMENT_TYPE === "openEnded" ? 3 : 4}
                  className={
                    AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                      ? style.paddingOpenEnded
                      : style.paddingCloseEnded
                  }
                >
                  <CardComponent
                    cardTitle1="Current"
                    cardTitle2="Value"
                    cardContent={bubbleBox["Current Value"]}
                    headCells={
                      AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                        ? CurrentValueHeadCellsOpenEnded
                        : CurrentValueHeadCells
                    }
                    // data={CommitmentData}
                    name="CurrentValue"
                    detailHeader="Current Value"
                  />
                </Grid>
                {AMC_DETAILS.INVESTMENT_TYPE !== "openEnded" && (
                  <Grid
                    item
                    xs={AMC_DETAILS.INVESTMENT_TYPE === "openEnded" ? 3 : 4}
                    className={
                      AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                        ? style.paddingOpenEnded
                        : style.paddingCloseEnded
                    }
                  >
                    <CardComponent
                      cardTitle1="Uncalled"
                      cardTitle2="Contributions"
                      cardContent={bubbleBox["Uncalled Contribution"]}
                      headCells={UncalledContributionHeadCells}
                      // data={CommitmentData}
                      name="UncalledContribution"
                      detailHeader="Uncalled Contribution"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <GraphsDistributor
                topClientsData={topClientsData}
                investmentsData={investmentsData}
              />
            </Grid>
          </Grid>
          {/* <Grid container style={{ padding: "1rem 1rem 1rem 2rem" }}>
            <Grid item xs={12}>
              <ActiveClients activeClientsHeadCells={ActiveClientsHeadCells} />
            </Grid>
          </Grid> */}
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    page: state.mainReducer.page,
    token: state.mainReducer.token,
    navDate: state.mainReducer.navDate,
    query: state.mainReducer.query,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    setNavDate: (val) => {
      dispatch({ type: "CHANGE-NAV-DATE", navDate: val });
    },
    setQuery: (val) => {
      dispatch({ type: "CHANGE-QUERY", query: val });
    },
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
    setShowTLNav: (val) => {
      dispatch({ type: "CHANGE-SHOW-TLNAV", showTLNav: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Distributor);
