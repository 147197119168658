import axios from "axios";
import { encrypt, decrypt } from "../utils/cipher";

import { AMC_DETAILS, SAAS_AUTH, URL } from "../variable";
import { jwtError } from "../utils/ErrorConditions";
import { authStore } from "../utils/authStore";
import { showMaintenance } from "../utils/maintenanceAlert";
import { popupError } from "../utils/errorHandler";
// For swal modal multiple rendering issue
global.showMaintenance = true;
global.showErroMessage = true;

export const handleAPI = (url, payload) =>
  new Promise((resolve, reject) => {
    let Auth_token = authStore.getItem("Auth");
    payload.email = authStore.getItem("Auth_email");
    payload.fundCode = authStore.getItem("fundCode");
    payload.investmentType = AMC_DETAILS.INVESTMENT_TYPE;
    let IdmData = authStore.getItem("IDM_response");

    // Check for data present in session storage or not
    if (
      !Auth_token ||
      !payload.email ||
      !payload.fundCode ||
      !IdmData ||
      !authStore.getItem("tenantCode") ||
      !authStore.getItem("logSessionId") ||
      !authStore.getItem("kuid")
    ) {
      // If necessary details are not present in localstorage return the error
      global.showErroMessage && popupError();
      return reject({
        error: true,
        errorMessage: "Something Went Wrong",
        errorNavigate: true,
      });
    }
    // Encrypt the payload
    let encryptedData = encrypt(payload);
    // Make the api call to aif backend
    axios
      .post(
        URL.AIF_BACKEND_URL + url,
        { data: encryptedData },
        {
          headers: {
            Authorization: `Bearer ${Auth_token}`,
            sessionId: authStore.getItem("logSessionId"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,Access-Control-Allow-Methods,Access-Control-Allow-Origin,x-code,x-fund,x-accountType,x-userType,x-permission",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Content-Type": "application/json",
            "x-code": authStore.getItem("tenantCode"),
            "x-fund": authStore.getItem("fundCode"),
            "x-accountType": authStore.getItem("accountType"),
            "x-userType": IdmData.userType,
            "x-permission": IdmData.permissions ? IdmData.permissions : "",
          },
        }
      )
      .then((result) => {
        //console.log("Result in handleAPI :", result.data);
        let decryptedData = decrypt(result.data.data);
        let decryptedMessage = result.data.message;
        let decryptedStatus = result.data.status;
        // let decryptedData = result.data.data;
        // console.log("Decrypted Data", decryptedData);

        resolve({
          data: decryptedData,
          code: 200,
          message: decryptedMessage,
          status: decryptedStatus,
        });
      })
      .catch((error) => {
        console.log(`Error fetching data from ${url}: ${error}`);
        console.log("ERROR--", error.response);

        let errorNavigate = false;
        let MaintainanceInfo = error?.response?.data?.data;
        if (
          MaintainanceInfo?.maintenanceHour != undefined &&
          error?.response?.status == 503
        ) {
          console.log(
            `${
              MaintainanceInfo.maintenanceHour
                ? "Daily Maintenance is running"
                : "System maintenance is in progress"
            }`
          );
          global.showMaintenance && showMaintenance(MaintainanceInfo);
        } else if (error?.response?.status == 401) {
          console.log(`Unauthorized ${error?.response?.status}`);
          errorNavigate = true;
          global.showErroMessage && popupError(errorNavigate);
        } else if (error?.response?.status) {
          console.log(`Backend error ${error} `);
          errorNavigate = false;
          global.showErroMessage && popupError(errorNavigate);
        } else {
          console.log(`Api gateway error ${error} `);
          errorNavigate = true;
          global.showErroMessage && popupError(errorNavigate);
        }
        reject({
          error: true,
          errorData: error?.response?.data?.data || "null",
          errorMessage: error?.response?.data?.message || "null",
          errorStatus: error?.response?.status || "null",
          errorNavigate,
        });
      });
  });

export const handleSaasAPI = (url, payload, token) =>
  new Promise((resolve, reject) => {
    let encryptedData = payload;
    console.log("Payload :", payload);
    if (SAAS_AUTH.SAAS_ENCRYPTION) {
      encryptedData = encrypt(payload, SAAS_AUTH.SAAS_ENCRYPTION);
    }
    //console.log("url", URL.SAAS_URL + url);
    if (!authStore.getItem("tenantCode")) {
      //console.log("session storage don't have enough data to make a api call");
      return reject({
        error: true,
        errorMessage: "Something Went Wrong",
      });
    }
    axios
      .post(
        URL.SAAS_URL + url,
        { data: encryptedData },
        token
          ? {
              headers: {
                Authorization: token,
                "x-code": authStore.getItem("tenantCode"),
                "x-api-key": SAAS_AUTH.API_KEY,
              },
            }
          : {
              headers: {
                "x-api-key": SAAS_AUTH.API_KEY,
              },
            }
      )
      .then((result) => {
        // console.log("Result in handleSaasAPI :", result);
        let decryptedData = result.data;
        if (
          SAAS_AUTH.SAAS_ENCRYPTION &&
          result?.data?.data &&
          result?.data?.data !== "null"
        ) {
          console.log("decrypting ...");
          decryptedData = decrypt(result.data.data, SAAS_AUTH.SAAS_ENCRYPTION);
        }
        // let decryptedStatus = result.data.success;
        // console.log("Decrypted Data", decryptedData);
        resolve(decryptedData);
      })
      .catch((err) => {
        console.log(err);
        console.log(`Error fetching data from ${url}: ${err}`);
        reject({
          error: true,
          errorMessage: err?.message,
          errorData: err?.response?.data,
        });
      });
  });

export const handleOnboardSaasAPI = (url, payload, token) =>
  new Promise((resolve, reject) => {
    let encryptedData = payload;
    if (SAAS_AUTH.SAAS_ENCRYPTION) {
      encryptedData = encrypt(payload, SAAS_AUTH.SAAS_ENCRYPTION);
    }
    //console.log("url", URL.SAAS_URL_ONBOARD + url);
    axios
      .post(
        URL.SAAS_URL_ONBOARD + url,
        { data: encryptedData },
        {
          headers: {
            "x-api-key": SAAS_AUTH.API_KEY,
          },
        }
      )
      .then((result) => {
        // console.log("Result in handleSaasAPI :", result);
        let decryptedData = result.data;
        // if (SAAS_AUTH.SAAS_ENCRYPTION) {
        //   decryptedData = decrypt(result.data.data, SAAS_AUTH.SAAS_ENCRYPTION);
        // }
        // let decryptedStatus = result.data.success;
        //console.log("Decrypted Data", decryptedData);
        resolve(decryptedData);
      })
      .catch((err) => {
        console.log(err);
        console.log(`Error fetching data from ${url}: ${err}`);
        reject({
          error: true,
          errorMessage: err?.message,
          errorData: err?.response?.data,
        });
      });
  });

export const handleSaasIdmAPI = (url, payload) =>
  new Promise((resolve, reject) => {
    console.log("Payload :", payload);
    let Auth_token = authStore.getItem("Auth");
    let encryptedData = encrypt(payload, true);
    if (!Auth_token || !authStore.getItem("tenantCode")) {
      //console.log("session storage don't have enough data to make a api call");
      return reject({
        error: true,
        errorMessage: "Something Went Wrong",
      });
    }
    console.log("url", URL.SAAS_IDM + url);
    axios
      .post(
        URL.SAAS_IDM + url,
        { data: encryptedData },
        {
          headers: {
            Authorization: `Bearer ${Auth_token}`,
            "x-code": authStore.getItem("tenantCode"),
          },
        }
      )
      .then((result) => {
        console.log("result - ", result);
        // let decryptedData = result.data;
        // if (SAAS_AUTH.SAAS_ENCRYPTION) {
        //   decryptedData = decrypt(result.data.data, SAAS_AUTH.SAAS_ENCRYPTION);
        // }
        // console.log("Result in handleSaasIdmAPI :", decryptedData);
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        console.log(`Error fetching data from ${url}: ${error}`);
        reject({
          error: true,
          errorMessage: error?.message,
        });
      });
  });

export const handleURLAPI = (url, payload) =>
  new Promise((resolve, reject) => {
    //console.log("Payload :", payload);
    //console.log("Url", URL.SAAS_CODE_URL + url);
    let encryptedData = payload;
    if (SAAS_AUTH.SAAS_ENCRYPTION) {
      encryptedData = encrypt(payload, true);
    }

    axios
      .post(
        `${URL.SAAS_CODE_URL}${url}`,
        { data: encryptedData },
        {
          headers: {
            "x-api-key": SAAS_AUTH.API_KEY,
          },
        }
      )
      .then((result) => {
        let decryptedData = result.data;
        // console.log("result ", result);
        if (SAAS_AUTH.SAAS_ENCRYPTION) {
          decryptedData = decrypt(result.data.data, SAAS_AUTH.SAAS_ENCRYPTION);
        }
        resolve(decryptedData);
      })
      .catch((error) => {
        console.log(error);
        console.log(`Error fetching data from ${url}: ${error}`);
        reject({
          error: true,
          errorMessage: error?.message,
        });
      });
  });

  export const handleFormDataAPI = (url,file, payload) =>
  new Promise((resolve, reject) => {
    
    let Auth_token = authStore.getItem("Auth");
    payload.email = authStore.getItem("Auth_email");
    payload.fundCode = authStore.getItem("fundCode");
    payload.investmentType = AMC_DETAILS.INVESTMENT_TYPE;
    let IdmData = authStore.getItem("IDM_response");

    // Check for data present in session storage or not
    if (
      !Auth_token ||
      !payload.email ||
      !payload.fundCode ||
      !IdmData ||
      !authStore.getItem("tenantCode") ||
      !authStore.getItem("logSessionId") ||
      !authStore.getItem("kuid")
    ) {
      // If necessary details are not present in localstorage return the error
      global.showErroMessage && popupError();
      return reject({
        error: true,
        errorMessage: "Unable to Upload",
        errorNavigate: true,
      });
    }
    // Encrypt the payload
    let encryptedData = encrypt(payload);
    // Make the api call to aif backend
    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", encryptedData);

    axios
      .post(
        URL.AIF_BACKEND_URL + url,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Auth_token}`,
            sessionId: authStore.getItem("logSessionId"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,Access-Control-Allow-Methods,Access-Control-Allow-Origin,x-code,x-fund,x-accountType,x-userType,x-permission",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Content-Type": "application/json",
            "x-code": authStore.getItem("tenantCode"),
            "x-fund": authStore.getItem("fundCode"),
            "x-accountType": authStore.getItem("accountType"),
            "x-userType": IdmData.userType,
            "x-permission": IdmData.permissions ? IdmData.permissions : "",
          },
        }
      )
      .then((result) => {
        console.log("Result in handleAPI :", result.data);
        let decryptedData = decrypt(result.data.data);
        let decryptedMessage = result.data.message;
        let decryptedStatus = result.data.status;
        // let decryptedData = result.data.data;
        // console.log("Decrypted Data", decryptedData);

        resolve({
          data: decryptedData,
          code: 200,
          message: decryptedMessage,
          status: decryptedStatus,
        });
      })
      .catch((error) => {
        console.log(`Error fetching data from ${url}: ${error}`);
        console.log("ERROR--", error.response);

        let errorNavigate = false;
        let MaintainanceInfo = error?.response?.data?.data;
        if (
          MaintainanceInfo?.maintenanceHour != undefined &&
          error?.response?.status == 503
        ) {
          console.log(
            `${
              MaintainanceInfo.maintenanceHour
                ? "Daily Maintenance is running"
                : "System maintenance is in progress"
            }`
          );
          global.showMaintenance && showMaintenance(MaintainanceInfo);
        } else if (error?.response?.status == 400) {
          // console.log(`Unauthorized ${error?.response?.status}`);
          console.log("hello");
          global.showErroMessage && popupError(errorNavigate,error.response.data.message);
        } else if (error?.response?.status == 401) {
          console.log(`Unauthorized ${error?.response?.status}`);
          errorNavigate = true;
          global.showErroMessage && popupError(errorNavigate,error.response.data.message);
        } else if (error?.response?.status) {
          console.log(`Backend error ${error} `);
          errorNavigate = false;
          global.showErroMessage && popupError(errorNavigate,error.response.data.message);
        } else {
          console.log(`Api gateway error ${error} `);
          errorNavigate = true;
          global.showErroMessage && popupError(errorNavigate,error.response.data.message);
        }
        reject({
          error: true,
          errorData: error?.response?.data?.data || "null",
          errorMessage: error?.response?.data?.message || "null",
          errorStatus: error?.response?.status || "null",
          errorNavigate,
        });
      });
  });
