import * as React from "react";

import styles from "../../assets/css/TopNav.module.css";
import { Grid, TextField, Typography } from "@mui/material";
import { handleAPI } from "../../API/handleAPI";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Marquee from "react-fast-marquee";
// import { ReactComponent as UserIcon2 } from "../../assets/svg/UserIcon2.svg";
// import { ReactComponent as LogoutIcon } from "../../assets/svg/LogoutIcon.svg";
import { ReactComponent as UserIcon2 } from "../../assets/svg/new_profile.svg";
import { ReactComponent as Logout } from "../../assets/svg/LogoutIcon.svg";
import { ReactComponent as Alternates } from "../../assets/svg/Alternates.svg";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import TopJpg from "../../assets/svg/TopJpg.jpg";
import BelowJpg from "../../assets/svg/BelowJpg.jpg";
import { ReactComponent as NewAxisAlternates } from "../../assets/svg/NewAxisAlternates.svg";
import { ReactComponent as PaddedAlternates } from "../../assets/svg/PaddedAlternates.svg";
import { useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { handleSaasAPI } from "../../API/handleAPI";
import capitalizeCase from "../../utils/capitalizeCase";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";
import "../../assets/css/logoutSwal.css";
import { COLOR_CODE } from "../../variable";

function TopNav(props) {
  const [newTickerMsg, setNewTickerMsg] = useState("");
  const [finalTicker, setFinalTicker] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [accountType, setAccountType] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    if (props.token) {
      setFirstName(capitalizeCase(props.userDetails.firstName));
      if (props.userDetails?.accountType?.includes("INV")) {
        handleAPI("/investor/getTickerMessage", {}, props.token)
          .then((response) => {
            //console.log("Ticker Msg data", response.data);
            const tempArr = Object.values(response.data);
            setFinalTicker(tempArr);
          })
          .catch((error) => {
            console.log(`Error Message:  ${error}`);
            console.log("Redirecting to error");
          });
      } else if (props.userDetails?.accountType?.includes("DIS")) {
        handleAPI("/distributor/getTickerMessage", {}, props.token)
          .then((response) => {
            //console.log("Ticker Msg data", response.data);
            const tempArr = Object.values(response.data);
            setFinalTicker(tempArr);
          })
          .catch((error) => {
            console.log(`Error Message:  ${error.errorMessage}`);
            console.log("Redirecting to error");
          });
      }
    }
  }, [props.token]);

  function createTickerMsg() {
    setNewTickerMsg(
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {//console.log("tickerString----", props.tickerString)} */}
        {finalTicker.map((message, index) => {
          let temp = message.trim();
          if (temp.length) {
            return (
              <Typography key={index} style={{ whiteSpace: "nowrap" }}>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;▲
                {message}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
            );
          }
        })}
      </div>
    );
  }

  useEffect(() => {
    createTickerMsg();
  }, [finalTicker]);
  //console.log("all details of user ", props.userDetails);

  useEffect(() => {
    if (props.userDetails?.accountType?.includes("INV")) {
      setAccountType(
        <NavLink to="/investor/investorProfile">
          <UserIcon2 />
        </NavLink>
      );
    } else if (props.userDetails?.accountType?.includes("DIS")) {
      setAccountType(
        <NavLink to="/distributor/distributorProfile">
          <UserIcon2 />
        </NavLink>
      );
    } else {
      setAccountType(
        <NavLink to="/admin/profile">
          <UserIcon2 />
        </NavLink>
      );
    }
  }, [props.userDetails.accountType]);

  function logout() {
    Swal.fire({
      text: "Do You Want to Logout?",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#000084",
      customClass: {
        popup: "logout-popup",
        cancelButton: "cancelbtn",
        confirmButton: "confirmbtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("You have logged out");
        let payload = {
          accessToken: props.token,
        };
        handleSaasAPI("/auth/expireToken", payload)
          .then((responce) => {
            console.log("Logout  ", responce.success);
          })
          .catch((error) => {
            console.log("error in logout ", error);
          });
        authStore.clear();
        props.RESET();
        navigate("/");
      }
    });
  }
  return (
    <div style={{ position: "fixed", width: "100vw", zIndex: "2" }}>
      <Grid container>
        <Grid item xs={7.8} className={styles.row1Left}>
          {/* Top */}
        </Grid>
        <Grid item xs={4.2} className={styles.row1Right}>
          {/* <img src={TopJpg} alt="Axis Mutual Fund" className={styles.topCut} /> */}
          <Grid item className={styles.phoneNumber}>
            {/* <PhoneInTalkOutlinedIcon
              sx={{ marginRight: "10px", paddingTop: "2px" }}
            />
            18604195555 */}
          </Grid>
        </Grid>
        <Grid item xs={7.8} className={styles.row2Left}>
          {/* Below Left */}
          {props.userDetails?.accountType?.includes("INV") ? (
            <NavLink
              to="/investor/dashboard"
              style={{ textDecoration: "none" }}
              className={styles.leftTile}
            ></NavLink>
          ) : props.userDetails?.accountType?.includes("DIS") ? (
            <NavLink
              to="/distributor/dashboard"
              style={{ textDecoration: "none" }}
              className={styles.leftTile}
            >
              {/* <PaddedAlternates
                className={styles.logoSvg}
                // style={{ border: "solid 2px blue", marginLeft: "-40px" }}
              /> */}
            </NavLink>
          ) : (
            <NavLink
              to="/admin/dashboard"
              style={{ textDecoration: "none" }}
              className={styles.leftTile}
            >
              {/* <PaddedAlternates
                className={styles.logoSvg}
                // style={{ border: "solid 2px blue", marginLeft: "-40px" }}
              /> */}
            </NavLink>
          )}

          {/* <img
            src={BelowJpg}
            alt="Axis Mutual Fund"
            className={styles.belowCut}
          /> */}
        </Grid>

        <Grid item xs={4.2} className={styles.row2Right}>
          Hi {firstName}&nbsp;&nbsp;{accountType}
          {/* <LogoutIcon
            style={{ marginLeft: "20px", fontSize: "30px", cursor:"pointer"}}
            onClick={logout}
          /> */}
          <Logout
            style={{
              marginLeft: "20px",
              fontSize: "30px",
              cursor: "pointer",
            }}
            onClick={logout}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={styles.tickerRow}
          style={{ height: "50px" }}
        >
          {props.userDetails?.accountType?.includes("INV") ||
          props.userDetails?.accountType?.includes("DIS") ? (
            <Grid
              item
              xs={12}
              style={{
                background: "#000084",
                //   "linear-gradient(90.68deg, #AD9042 0.59%, #CFAD4F 59.57%, #DEBA55 82.74%, #FED561 99.41%)",
                margin: "10px",
                zIndex: "2",
                color: "#f2f2f2",
              }}
            >
              <Marquee speed={50} pauseOnHover={true}>
                <>
                  <div>
                    {/* <span style={{color:"white"}}> */}
                    {newTickerMsg}
                    {/* </span> */}
                  </div>
                </>
              </Marquee>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    tickerString: state.mainReducer.tickerString,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setQuery: (val) => {
      dispatch({ type: "CHANGE-QUERY", query: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(TopNav);
