import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { ReactComponent as AlternatesLoginLogo } from "../../../src/assets/svg/AlternatesLoginLogo.svg";
import styles from "../../../src/assets/css/AdminProfile.module.css";
import { useState } from "react";
import AxisBg from "../../assets/svg/AxisBg.svg";
import { handleAPI } from "../../API/handleAPI";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import { handleSaasIdmAPI } from "../../API/handleAPI";
import { SAAS_AUTH } from "../../variable";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";


function AdminProfile(props) {
  const [Permission, setPermission] = useState(props.userDetails?.permissions);
  const [UserType, setUserType] = useState("");
  const [radioValue, setRadiovalue] = useState({
    radio1: "",
    radio2: "",
  });
  const [disable, setDisable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    props.setShowTLNav(true);
    props.setPage("AdminProfile");
    // setFullName(props.userDetails.fullName);
    setUserType(props.userDetails?.userType);
    if (props.userDetails?.userType == "AMCUser") {
      setRadiovalue({
        radio1: "Read",
        radio2: "Write",
      });
      setPermission(props.userDetails?.permissions);
    } else if (props.userDetails?.userType == "Admin") {
      setRadiovalue({
        radio1: "Read",
        radio2: "Write",
      });
      setPermission("Write");
    } else {
      setRadiovalue({
        radio1: "Maker",
        radio2: "Checker",
      });
    }
  }, [props.userDetails]);

  useEffect(() => {
    if (UserType == "Admin") {
      setDisable(true);
    } else if (Permission !== props.userDetails.permissions) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    // console.log("current user permission - ", Permission);
  }, [Permission]);

  function handleChangeRadio(event) {
    console.log(event.target.value);
    if (UserType !== "Admin") {
      setPermission(event.target.value);
    } else {
      setPermission("Write");
    }
  }

  async function onRequest() {
    if (Permission !== props.userDetails?.permissions) {
      if (props.userDetails?.status === "Pending") {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: `Request has already been raised `,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        let payload = {
          funds: [],
          permissions: Permission,
          userType: "AMCUser",
          accountType: props.userDetails.accountType,
          kuid: authStore.getItem("kuid"),
          appName: SAAS_AUTH.COGNITO_APPNAME,
          code: authStore.getItem("tenantCode"),
        };
        try {
          let IdmResponse = await handleSaasIdmAPI(
            "/user/submit_access_request",
            payload
          );
          console.log(IdmResponse);
          if (IdmResponse?.data?.message == "Succesfully raised request") {
            let userData = props.userDetails;
            userData.status = "Pending";
            props.setUserDetails(userData);
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Request raised successfully`,
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Error in request`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          //console.log(error);
         
        }
      }
    }
  }
  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2vh",
        marginLeft: "1rem",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card className={styles.card}>
          <CardContent>
            <Grid item xs={12}>
              {/* Signup */}
              <Grid
                item
                xs={12}
                style={{
                  margin: "0px 0px 40px -6px",
                  fontWeight: "bolder",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                  fontSize: "25px",
                  // border: "2px solid #fff4d7",
                  // borderRadius: "10px ",
                  // backgroundColor: "#fff4d7",
                }}
              >
                <span style={{ paddingLeft: "5px" }}>Profile</span>
              </Grid>

              {/* FullName and Emailid */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "20px 0px 20px 0px",
                }}
              >
                {/* Fullname */}
                <Grid item xs={6} className={styles.profileTextField}>
                  <TextField
                    style={{ width: "75%" }}
                    id="login-full-name"
                    // label="A/C Holder Name"
                    // required={true}
                    maxRows={1}
                    label="Full Name"
                    variant="standard"
                    InputLabelProps={{
                      style: { fontWeight: "800", color: "#333333" },
                    }}
                    className={styles.signUpTextField}
                    value={
                      props.userDetails?.fullName
                        ? props.userDetails?.fullName
                        : ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* EmailId */}
                <Grid
                  item
                  xs={6}
                  style={{
                    margin: "0px 30px 0px 30px",
                  }}
                >
                  <TextField
                    id="login-emailId-name"
                    label="Email Id "
                    variant="standard"
                    // onChange={handleEmailId}
                    style={{ width: "80%" }}
                    InputLabelProps={{
                      style: { fontWeight: "800", color: "#333333" },
                    }}
                    value={
                      props.userDetails?.email ? props.userDetails?.email : ""
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>

              {/* UserType and Permissions */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "40px 0px 20px 0px",
                }}
              >
                {/* UserType */}
                <Grid item xs={6}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      // margin: "0px 30px 0px 30px",
                      marginRight: "30px",
                      fontWeight: "bolder",
                    }}
                  >
                    <Grid item xs={12} style={{}}>
                      <TextField
                        // required={true}
                        id="login-emailId-name"
                        label="UserType "
                        variant="standard"
                        style={{ width: "80%" }}
                        InputLabelProps={{
                          style: { fontWeight: "800", color: "#333333" },
                        }}
                        value={
                          props.userDetails?.userType
                            ? props.userDetails?.userType
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* permission */}
                <Grid item xs={5} style={{ marginTop: "0px" }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      // margin: "0px 30px 0px 30px",
                      marginRight: "30px",
                      fontWeight: "bolder",
                    }}
                  >
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Permission
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={Permission ? Permission : ""}
                        onChange={handleChangeRadio}
                        name="radio-buttons-group"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          disabled={props.userDetails?.userType == "Admin"}
                          value={radioValue.radio1 ? radioValue.radio1 : ""}
                          control={<Radio />}
                          label={radioValue.radio1}
                        />
                        <FormControlLabel
                          value={radioValue.radio2 ? radioValue.radio2 : ""}
                          control={<Radio />}
                          label={radioValue.radio2}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "60px 110px 18px 0px",
                }}
              >
                <Button
                  component="span"
                  className={disable ? "btncss-disable" : "btncssProfile"}
                  onClick={onRequest}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const mapDispatchtoProps = (dispatch) => {
  return {
    setSignupEmail: (val) => {
      dispatch({ type: "CHANGE-SIGNUP-EMAIL", signupEmail: val });
    },
    setShowTLNav: (val) => {
      dispatch({ type: "CHANGE-SHOW-TLNAV", showTLNav: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
  };
};

const mapStatetoProps = (state) => {
  return {
    signupEmail: state.mainReducer.signupEmail,
    userDetails: state.mainReducer.userDetails,
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(AdminProfile);
