import { useRef, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import "../../assets/css/passwordToOtp.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { URL, SAAS_AUTH } from "../../variable";
import { handleSaasAPI } from "../../API/handleAPI";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import capitalizeCase from "../../utils/capitalizeCase";
import CryptoJS from "crypto-js";
import swal from "sweetalert2";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";

function PasswordToOtp(props) {
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]); // from store all 6 digit otp
  const [IdmUser, setIdmUser] = useState(props.congnitoUser); // response from cognito
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const otpAttemps = useRef(1);
  const [errorMessage, setErrorMessage] = useState("Invalid OTP");
  const [userEmail, setUserEmail] = useState("demo@kfintech.com");
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById(parseInt(0)).focus();
    if (authStore.getItem("Auth_email")) {
      setUserEmail(authStore.getItem("Auth_email"));
      //console.log("user email where otp is sent ", userEmail);
    } else {
      props.setPasswordStatus(false);
    }
  }, []);
  // handle the focus of input field on backspace
  const keyDownHandler = (e) => {
    setDisabled(false);
    if (e.key == "Backspace" && e.target.id > -1) {
      let newOtp = [...otpValue];
      e.preventDefault();
      newOtp[e.target.id] = "";
      setOtpValue(newOtp);

      e.target.id > 0 &&
        document.getElementById((parseInt(e.target.id) - 1).toString()).focus();
    }
  };

  // handle the focus of input field on value change
  const inputHandler = (e) => {
    // //console.log(e)
    // //console.log(e.target.value)
    setDisabled(false);
    if (!isNaN(e.target.value)) {
      let newOtp = [...otpValue];
      newOtp[e.target.id] = e.target.value;
      setOtpValue(newOtp);
      // //console.log(newOtp)
      if (e.target.id < 5) {
        // //console.log(e.target.id)
        document.getElementById((parseInt(e.target.id) + 1).toString()).focus();
      }
    }
  };

  // verify the otp
  async function verifyOtp(event) {
    event.preventDefault();
    let otp = "";
    if (otpValue) {
      otpValue.map((data) => {
        otp = otp + data;
      });
    }
    //console.log(otp);
    setDisabled(true);
    // validate the user according to the cognito response
    if (IdmUser.challengeName === "CUSTOM_CHALLENGE" && otp.length == 6) {
      const reqTime = Date.now();
      const appName = SAAS_AUTH.COGNITO_APPNAME;
      const logSessionId = reqTime + IdmUser.username + appName;
      var hash = CryptoJS.AES.encrypt(logSessionId, "saasloginkey").toString();
      let clientMetaData1 = {
        appName: SAAS_AUTH.COGNITO_APPNAME,
        applicationId: SAAS_AUTH.COGNITO_APPLICATION_ID,
        sessionId: IdmUser.challengeParam.sessionId,
        token: IdmUser.challengeParam.otpToken,
        attempts: otpAttemps.current.toString(),
        logSessionId: hash,
        code: authStore.getItem("tenantCode"),
        request_timestamp: JSON.stringify(reqTime),
      };
      // to send the answer of the custom challenge
      Auth.sendCustomChallengeAnswer(IdmUser, otp, clientMetaData1)
        .then((user) => {
          // swal({
          //     title: `Oops  !`,
          //     text: "This User is Not an Investor",
          //     icon: "error",
          //     buttons: false,
          //     timer: 5000,
          //   });
          //console.log(user);
          if (user.challengeName !== "CUSTOM_CHALLENGE") {
            setIsOtpValid(true);
            authStore.setItem("logSessionId", hash);
            getIdmUserDetails(user);
          } else {
            setIsOtpValid(false);
            setDisabled(false);
            otpAttemps.current = otpAttemps.current + 1;
          }
          setIdmUser(user);
          //console.log("Props Switch User :", props.switchUser);
          // Setting the User Type
          if (props.switchUser === "Investor")
            authStore.setItem("accountType", "INV");
          else if (props.switchUser === "Distributor")
            authStore.setItem("accountType", "DIS");
          else if (props.switchUser === "Admin")
            authStore.setItem("accountType", "ADM");
        })
        .catch((err) => {
          console.log("Error", err);
          setErrorMessage("Session expired. Please try again.");
          setIsOtpValid(false);
          setDisabled(false);
        });
    } else {
      console.log("error in cognito user", IdmUser);
      setErrorMessage("Server error. Please Try again.");
      setIsOtpValid(false);
      setDisabled(false);
    }
  }

  // get user data from idm using cognito auth token
  const getIdmUserDetails = async (user) => {
    let payload = {
      email: userEmail,
      appName: SAAS_AUTH.COGNITO_APPNAME,
      code: authStore.getItem("tenantCode"),
    };
    try {
      let congnitoToken = user.signInUserSession.accessToken.jwtToken;
      //console.log("payload for idm login", payload);
      await handleSaasAPI("/auth/getAuthData", payload, congnitoToken).then(
        (IdmuserData) => {
          // Fetching the accountType from storage
          let accountType = authStore.getItem("accountType");

          // Check For Accountype selected and accountType recived from IDM are same or not
          if (!accountType || !IdmuserData.accountType?.includes(accountType)) {
            let alertText = "";
            //console.log("see this - ", accountType == "INV");
            if (accountType == "INV") {
              alertText = "Investor";
            } else if (accountType == "ADM") {
              alertText = "Admin";
            } else if (accountType == "DIS") {
              alertText = "Distributor";
            }
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Unauthorized ${alertText} Type`,
              // title: `Your Account is not ${alertText} type `,
              showConfirmButton: true,
              confirmButtonText: "Go back to login",
              confirmButtonColor: "#337fc9",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              // if (result.isConfirmed) {
              props.setPasswordStatus(false);
              // }
            });
          } else {
            // add first name and last name in authStore
            authStore.setItem(
              "firstName",
              capitalizeCase(IdmuserData?.firstName)
            );
            authStore.setItem(
              "lastName",
              capitalizeCase(IdmuserData?.lastName)
            );
            IdmuserData.fullName = IdmuserData?.lastName
              ? capitalizeCase(IdmuserData?.firstName) +
                " " +
                capitalizeCase(IdmuserData?.lastName)
              : capitalizeCase(IdmuserData?.firstName);
            IdmuserData.userType = "AMCUser";
            props.setToken(congnitoToken);
            props.setUserDetails(IdmuserData);
            authStore.setItem("IDM_response", IdmuserData);
            props.setAuthorization(true);
            authStore.setItem("Auth", congnitoToken);

            if (IdmuserData?.accountType.includes("ADM")) {
              if (IdmuserData.permissions?.length > 0) {
                props.setUserPermission(IdmuserData?.permissions.toLowerCase());
                navigate("admin/dashboard");
              } else {
                props.setUserPermission("none");
                navigate("admin/profile");
              }
            } else if (IdmuserData?.accountType.includes("DIS")) {
              navigate("distributor/dashboard");
            } else if (IdmuserData?.accountType.includes("INV")) {
              navigate("investor/dashboard");
            } else {
              alert("user type not listed");
            }
          }
        }
      );
    } catch (error) {
      console.log("error in getting idm data ", error);
    }
    // const idmUser = await handleSaasAPI()
    // //console.log("this is the getidm user details section ",responseCognito);
  };
  return (
    <div>
      <form onSubmit={verifyOtp}>
        <Grid
          container
          style={{
            maxHeight: "30vh",
            minHeight: "150px",
            display: "flex",
            justifyContent: "center",
            scale: "110%",
          }}
        >
          {
            <>
              {" "}
              {/* <TextField
                variant="standard"
                label="Enter OTP"
                style={{ border: "none", borderRadius: "0px" }}
                className={styles.inputFormat}
                type="password"
                name="OTP"
                // value={pwd}
                onChange={(e) => {
                  // setPassword(e.target.value);
                }}
                // helperText={otpMsg}
                FormHelperTextProps={{ style: { color: "gray" } }}
                // error={otpMsg === "Incorrect OTP."}
              /> */}
              <Stack spacing={2}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    fontFamily: "Lato",
                  }}
                >
                  has been sent to
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "0.8rem",
                    color: "black",
                    fontFamily: "Lato",
                    fontWeight: "bolder",
                  }}
                >
                  {userEmail}
                </div>
                <Stack className="otp-stack" direction="row" spacing={0.1}>
                  <input
                    type="text"
                    inputMode="numeric"
                    id="0"
                    className="otp-input"
                    maxLength="1"
                    autoComplete="off"
                    onChange={inputHandler}
                    onKeyDown={keyDownHandler}
                    value={otpValue[0]}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="1"
                    className="otp-input"
                    maxLength="1"
                    autoComplete="off"
                    onChange={inputHandler}
                    onKeyDown={keyDownHandler}
                    value={otpValue[1]}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="2"
                    className="otp-input"
                    maxLength="1"
                    autoComplete="off"
                    onChange={inputHandler}
                    onKeyDown={keyDownHandler}
                    value={otpValue[2]}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="3"
                    className="otp-input"
                    maxLength="1"
                    autoComplete="off"
                    onChange={inputHandler}
                    onKeyDown={keyDownHandler}
                    value={otpValue[3]}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="4"
                    className="otp-input"
                    maxLength="1"
                    autoComplete="off"
                    onChange={inputHandler}
                    onKeyDown={keyDownHandler}
                    value={otpValue[4]}
                  />
                  <input
                    type="text"
                    inputMode="numeric"
                    id="5"
                    className="otp-input"
                    maxLength="1"
                    autoComplete="off"
                    onChange={inputHandler}
                    onKeyDown={keyDownHandler}
                    value={otpValue[5]}
                  />
                </Stack>
                {!isOtpValid ? (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                      fontSize: "0.7rem",
                      color: "red",
                      fontFamily: "Lato",
                    }}
                  >
                    {errorMessage}
                  </span>
                ) : null}
              </Stack>
            </>
          }
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2vh",
          }}
        >
          <button
            type="submit"
            className={disabled ? "login-disabled" : "loginButton"}
            // disabled={username && password ? false : true}
            // onClick={verifyOtp}
          >
            Proceed
          </button>
        </div>
      </form>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    token: state.mainReducer.token,
    authorization: state.mainReducer.authorization,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setToken: (val) => {
      dispatch({ type: "CHANGE-TOKEN", token: val });
    },
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setAuthorization: (val) => {
      dispatch({ type: "CHANGE-AUTHORIZATION", authorization: val });
    },
    setUserPermission: (val) => {
      dispatch({ type: "CHANGE-USER-PERMISSION", userPermission: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(PasswordToOtp);
